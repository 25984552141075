<script setup lang="ts">
import FieldModal from '@/components/Modals/FieldModal.vue';
import { nextTick, ref, watch } from 'vue';
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import VDropdownList from '@/components/Inputs/Dropdown/VDropdownList.vue';
import VDropdownListItem from '@/components/Inputs/Dropdown/VDropdownListItem.vue';
import { allAvailableFieldTypes, fieldClasses } from '@/util/fields';

type Props = {
  classList?: { value: string; title: string }[];
  withClass?: boolean;
  withDescription?: boolean;
  withLineBreak?: boolean;
  fieldTypes?: { title: string; icon: string; component: string }[];
  initField: any;
  withButton?: boolean;
  handleOptionsOutside?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  classList: () => [...fieldClasses],
  withClass: true,
  withDescription: true,
  withLineBreak: true,
  fieldTypes: () => [...allAvailableFieldTypes],
  initField: {
    id: null,
    title: '',
    value: '',
    component: 'text',
    options: [],
    class: 'w-full',
  },
  withButton: false,
  handleOptionsOutside: false,
});

const emit = defineEmits<{
  (event: 'field-added', arg: any): void;
  (event: 'field-updated', arg: any): void;
  (event: 'field-deleted', arg: number): void;
  (event: 'modalOpen', arg: any): void;
  (event: 'closed', arg: any): void;
}>();

const isModalOpen = ref(false);
const field = ref<{
  id: number | null;
  title: string;
  component: string;
  options: string[];
  class: string;
  description: string;
  linebreak_after: boolean;
} | null>(null);

const openModalForField = (newField) => {
  field.value = null;
  isModalOpen.value = false;
  nextTick(() => {
    field.value = {
      id: newField && newField.hasOwnProperty('id') ? newField.id : null,
      component: newField && newField.hasOwnProperty('component') ? newField.component : props.fieldTypes[0].component,
      options: newField && newField.hasOwnProperty('options') ? newField.options : null,
      title: newField && newField.hasOwnProperty('title') ? newField.title : '',
      class: newField && newField.hasOwnProperty('class') ? newField.class : props.classList[0].value,
      description: newField && newField.hasOwnProperty('description') ? newField.description : null,
      linebreak_after: newField && newField.hasOwnProperty('linebreak_after') ? newField.linebreak_after : false,
    };
    isModalOpen.value = true;
    emit('modalOpen', field.value);
  });
};

watch(
  () => props.initField,
  () => {
    if (props.initField && !isModalOpen.value) {
      openModalForField(props.initField);
    }
  },
  { deep: true }
);
</script>

<template>
  <div>
    <div v-if="withButton">
      <VDropdown title="Open dropdown">
        <template #click-area>
          <button
            class="btn-primary"
            title="Click to add a field">
            <i class="fa fa-fw fa-plus mr-2" />Add field
          </button>
        </template>
        <template #dropdown>
          <VDropdownList class="rounded border bg">
            <VDropdownListItem
              v-for="fieldType in fieldTypes"
              :key="fieldType.title"
              :title="fieldType.title"
              :icon="fieldType.icon"
              @click="openModalForField({ component: fieldType.component, id: null })" />
          </VDropdownList>
        </template>
      </VDropdown>
    </div>

    <FieldModal
      v-if="isModalOpen"
      :with-line-break="withLineBreak"
      :init-field="initField"
      :field-types="fieldTypes"
      :with-description="withDescription"
      :with-class="withClass"
      :class-list="classList"
      :handle-options-outside="handleOptionsOutside"
      @created="$emit('field-added', $event)"
      @updated="$emit('field-updated', $event)"
      @deleted="$emit('field-deleted', $event)"
      @closed="[(isModalOpen = false), $emit('closed')]">
      <template #under>
        <slot name="under" />
      </template>
      <template #listOptions>
        <slot name="listOptions" />
      </template>
    </FieldModal>
  </div>
</template>
