<script lang="ts" setup>
import { computed } from 'vue';
import { TaskAuditResource, TaskResource } from '@/types/tasks';
import ModeSelector from '@/components/Inputs/Components/ModeSelector.vue';
import TaskSlideoutComment from '@/components/Tasks/TaskSlideoutComment.vue';
import TaskSlideoutAudit from '@/components/Tasks/TaskSlideoutAudit.vue';
import { getKey } from '@/util/globals';
import { timeStampsAreSame } from '@/util/timeFunctions';

type Props = {
  task: TaskResource;
  currentMode: string;
};

const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits<{
  (e: 'update:currentMode', value: string): void;
}>();

const createAudit = computed(() => {
  return props.task.task_audits.find((audit: TaskAuditResource) => {
    return (
      audit.old_values === null &&
      audit.task_comment_id === null &&
      audit.task_user_pivot_id === null &&
      audit.task_task_field_pivot_id === null
    );
  });
});

const completeAudit = computed(() => {
  if (props.task.completed_at) {
    return props.task.task_audits.find((audit: TaskAuditResource) => {
      return (
        audit.old_values !== null &&
        getKey(audit.old_values, 'completed_at') === null &&
        getKey(audit.new_values, 'completed_at') !== null &&
        audit.task_comment_id === null &&
        audit.task_user_pivot_id === null &&
        audit.task_task_field_pivot_id === null
      );
    });
  }
});

const filteredAudits = computed(() => {
  return props.task.task_audits.filter(
    (t) =>
      t.title !== null &&
      (createAudit.value && createAudit.value.title === 'created task' ? createAudit.value.id !== t.id : true) &&
      (completeAudit.value ? completeAudit.value.id !== t.id : true)
  );
});

const showDateRow = (index) => {
  if (index === 0) return false;
  const thisAudit = filteredAudits.value[index];
  const lastAudit = filteredAudits.value[index - 1];
  return !timeStampsAreSame(thisAudit.created_at, lastAudit.created_at, 'day');
};
</script>

<template>
  <div class="bg-content flex-1 pb-10 mt-8">
    <div class="p-edge">
      <ModeSelector
        class="flex-nowrap whitespace-nowrap"
        :model-value="currentMode"
        grey-default
        :modes="[
          {
            name: 'Comments',
            value: 'comments',
          },
          {
            name: 'All Activity',
            value: 'activity',
          },
        ]"
        @update:model-value="emit('update:currentMode', $event)" />
    </div>
    <div class="flex flex-col gap-3 mb-5">
      <TaskSlideoutAudit
        v-if="completeAudit"
        with-image
        full-text
        :audit="{ ...completeAudit, title: 'completed task.' }" />
      <TaskSlideoutAudit
        v-if="createAudit"
        with-image
        full-text
        :audit="{ ...createAudit, title: 'created task.' }" />
    </div>
    <div class="my-4" />
    <div
      v-if="currentMode === 'comments'"
      class="flex flex-col gap-7">
      <TaskSlideoutComment
        v-for="comment in task.task_comments"
        :comment="comment" />
    </div>
    <div
      v-if="currentMode === 'activity'"
      class="flex flex-col gap-3">
      <div v-for="(audit, index) in filteredAudits">
        <hr
          v-if="showDateRow(index)"
          class="mb-4 mt-2" />
        <TaskSlideoutAudit :audit="audit" />
      </div>
    </div>
  </div>
</template>
