<script setup lang="ts">
import { EventMinimalResource } from '@/types/event';
import { getRoute } from '@/util/route';
import { router } from '@inertiajs/vue3';
import moment from 'moment';
import { nextTick, ref } from 'vue';
import { useToast } from 'vue-toastification';
import CrudModal from '@/components/Modals/CrudModal.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import VButton from '@/components/Inputs/VButton.vue';

type Props = {
  event: EventMinimalResource;
  recurringDates: string[];
  clickable?: boolean;
  shouldEmit?: boolean;
  openOnCreate?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  clickable: false,
  shouldEmit: false,
  openOnCreate: false,
});

const emit = defineEmits<{
  (event: 'changeDate', date: string): void;
}>();

const toast = useToast();

const modalOpen = ref(props.openOnCreate);

const recurringDatesByWeek = ref(null);

const formattedMonth = (date) => {
  return moment(date[0].start_date).format('MMMM');
};
const formattedWeek = (date) => {
  return moment(date[0].start_date).format('WW');
};
const formattedRecurringDate = (date) => {
  return moment(date.start_date).format('dddd Do');
};
const formattedDate = (date) => {
  return moment(date).format('dddd Do [of] MMMM');
};
const showDatesModal = () => {
  modalOpen.value = false;
  nextTick(() => {
    modalOpen.value = true;
  });
};

const hideDatesModal = () => {
  modalOpen.value = false;
};
const openDate = (date) => {
  hideDatesModal();
  if (props.clickable) {
    toast.success(`Relocating to ${moment(date.start_date).format('dddd Do [of] MMMM')}`);
    if (props.shouldEmit) {
      emit('changeDate', date.start_date);
    } else {
      router.visit(getRoute('events.show', date.slug));
    }
  }
};
const isSameMonth = (index) => {
  if (index === 0) {
    return false;
  }
  return moment(recurringDatesByWeek.value[index - 1][0].start_date).isSame(
    recurringDatesByWeek.value[index][0].start_date,
    'month'
  );
};

if (props.recurringDates) {
  recurringDatesByWeek.value = Object.values(
    props.recurringDates
      .sort((a, b) => moment(a.start_date).valueOf() - moment(b.start_date).valueOf())
      .reduce((acc, date) => {
        const yearWeek = `${moment(date.start_date).year()}-${moment(date.start_date).isoWeek()}`;
        if (!acc[yearWeek]) {
          acc[yearWeek] = [];
        }
        acc[yearWeek].push(date);
        return acc;
      }, {})
  ).filter((val) => val);
  if (props.openOnCreate) {
    showDatesModal();
  }
}
</script>

<template>
  <div>
    <div @click="showDatesModal">
      <slot
        name="button"
        :click="showDatesModal" />
    </div>

    <CrudModal
      v-if="modalOpen"
      :title="'Recurring Dates for ' + event.name"
      :only-close-button="true"
      small
      @closed="modalOpen = false">
      <p class="sub-title text-soft">
        A recurring event is multiple events connected over time. Each date is an individual event. As long as you do
        global changes, this will be reflected on all recurrences of the event, but you can also do individual
        differences between the events. Just make sure you change the correct date!
      </p>
      <div class="rounded bg-content p-edge">
        <div v-for="(week, index) in recurringDatesByWeek">
          <h3
            v-if="!isSameMonth(index)"
            class="text-xl uppercase">
            {{ formattedMonth(week) }}
          </h3>
          <h4 class="text-lg uppercase">Week {{ formattedWeek(week) }}</h4>
          <VTable row-size="small">
            <VTableRow
              v-for="day in week"
              clickable
              classes="items-center change-fa-icon"
              @click="openDate(day)">
              <VTableCell>
                {{ formattedRecurringDate(day) }}
              </VTableCell>
              <VTableCell style="width: 40px">
                <VButton
                  icon="fa-external-link"
                  size="inTable"
                  @click="openDate(day)" />
              </VTableCell>
            </VTableRow>
          </VTable>
        </div>
      </div>
    </CrudModal>
  </div>
</template>
