<script lang="ts" setup>
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import VButton from '@/components/Inputs/VButton.vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import { useRecurringModal } from '@/composables/modals/use-recurring-modal';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';

type Props = {
  eventId: number;
  isCancelled?: boolean;
  isRecurring: boolean;
  eventName: string;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'closed', ...args: any[]): void;
  (event: 'eventCancelled', ...args: any[]): void;
}>();

const sendEmail = ref(false);
const reasonForCancel = ref('');

const onCancel = async (close: () => void) => {
  let global = false;
  if (props.isRecurring) {
    const res = await useRecurringModal().recurringModal(
      '',
      'Cancel Recurring Event',
      'Do you want cancel all recurrences of the event, or just this one? All elements will be kept in the event itself either way. '
    );
    if (res === 'cancel') return;
    global = res === 'all';
  }
  try {
    const params = {
      is_global: global,
      cancel_reason: reasonForCancel.value,
      send_email: sendEmail.value,
    };
    const { data } = await window.axios.post(`/api/events/${props.eventId}/cancel`, { ...params });
    useToast().success(`Event ${props.isCancelled ? 'Reinstated' : 'Cancelled'} `);
    emit('eventCancelled', { data, close });
  } catch (e) {
    useToast().warning('Something went wrong');
    throw e;
  }
};
</script>

<template>
  <CrudModal
    :title="`${isCancelled ? 'Reinstate' : 'Cancel'} Event`"
    :title-highlight="['Reinstate', 'Cancel']"
    @closed="$emit('closed')">
    <div
      v-if="!isCancelled"
      class="flex flex-col gap-5">
      <p class="text-soft">
        Are you sure you want to cancel {{ eventName }}? Would you like to send an email to everyone involved in the
        event? If so, would you like to include a reason for the cancel?
      </p>
      <SettingToggle
        v-model="sendEmail"
        label="Send an email to everyone involved in the event" />
      <TextareaInput
        v-model="reasonForCancel"
        :can-edit="sendEmail"
        label="Reason" />
    </div>
    <div v-else>Are you sure you want to reinstate {{ eventName }}?</div>

    <template #footer="{ close }">
      <div class="flex justify-end gap-2">
        <VButton
          :title="isCancelled ? 'Reinstate Event' : 'Cancel Event'"
          :type="isCancelled ? 'success' : 'warning'"
          @click="onCancel(close)" />
      </div>
    </template>
  </CrudModal>
</template>
