<script setup lang="ts">
import { EditorContent, useEditor } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import { computed, nextTick, onBeforeUnmount, ref, watch } from 'vue';
import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import TextInput from '@/components/Inputs/TextInput.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import WordExchanger from '@/components/Fields/WordExchanger/WordExchanger.vue';
import { getExchangeWarning } from '@/util/exchangeFunctions';
import FloatingWindowContainer from '@/components/Inputs/Components/FloatingWindowContainer.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { useToast } from 'vue-toastification';
import ModeSelector from '@/components/Inputs/Components/ModeSelector.vue';
import { startsWith } from 'lodash';
import PhoneInput from '@/components/Inputs/PhoneInput.vue';
import EmailInput from '@/components/Inputs/EmailInput.vue';
import UrlInput from '@/components/Inputs/UrlInput.vue';
import WhisperIndicator from '@/components/Broadcasting/WhisperIndicator.vue';
import { whisperForArea, WhisperObject } from '@/util/whisper-functions';

type Props = {
  modelValue?: string | null;
  labelClasses?: string;
  label?: string | null;
  targetBlank?: boolean;
  canEdit?: boolean;
  unsavedIndicator?: boolean;
  working?: boolean;
  whisper?: WhisperObject | null;
  exchangeWords?: string[];
  setFocus?: boolean | null;
  blurDelay?: number | null;
};

type UserType = {
  [key: string]: unknown;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  labelClasses: '',
  label: null,
  targetBlank: true,
  canEdit: true,
  unsavedIndicator: false,
  working: false,
  setFocus: false,
  whisper: null,
  blurDelay: 200,
  exchangeWords: () => [],
});

const emit = defineEmits<{
  (event: 'update:modelValue', ...args: unknown[]): void;
  (event: 'blur', ...args: unknown[]): void;
}>();

const URL_OPTIONS = {
  REGULAR: 'REGULAR',
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
};

const unsavedChanges = ref(false);
const justSaved = ref(false);
const inFocus = ref(false);
const keyNumber = ref(0);

const editor = useEditor({
  content: props.modelValue,
  editable: props.canEdit,
  autofocus: props.setFocus,
  extensions: [
    StarterKit,
    Link.configure({
      openOnClick: true,
    }),
    Underline.configure({
      HTMLAttributes: {
        class: 'text-underline',
      },
    }),
  ],
  editorProps: {
    attributes: {
      class: 'w-full prose prose-sm',
    },
  },
  onFocus: () => {
    if (!props.canEdit || focusedBySomeone.value) {
      (document.activeElement as HTMLElement)?.blur();
      if (focusedBySomeone.value) {
        toast.info((focusedBySomeone.value?.name ?? 'Someone') + ' is editing.');
      }
      return;
    }
    inFocus.value = true;
  },
  onBlur: () => {
    if (!props.canEdit || focusedBySomeone.value) {
      return;
    }

    inFocus.value = false;
    unsavedChanges.value = false;
    setTimeout(() => {
      emit('blur', editor.value?.getHTML());
    }, props.blurDelay);
  },
  onUpdate: ({ editor: ed }) => {
    const oldValue = props.modelValue;
    let content = ed.getHTML();

    unsavedChanges.value = true;

    if (props.exchangeWords && props.exchangeWords.length > 0) {
      if (oldValue) {
        for (let i = 0; i < props.exchangeWords.length; i++) {
          if (oldValue.includes(props.exchangeWords[i])) {
            if (!content.includes(props.exchangeWords[i])) {
              getExchangeWarning(props.exchangeWords[i]);
              content = oldValue.replace(props.exchangeWords[i], '');
              editor.value?.commands.setContent(content, false);
            }
          }
        }
      }
    }
    emit('update:modelValue', content);
  },
});

watch(
  () => props.modelValue,
  (newValue) => {
    const isSame = editor.value?.getHTML() === newValue;

    if (isSame) {
      return;
    }

    editor.value?.commands.setContent(newValue, false);
  }
);

watch(
  () => props.canEdit,
  async () => {
    if (editor.value) editor.value.options.editable = props.canEdit;
    await nextTick();
    keyNumber.value++;
  }
);

watch(
  () => props.working,
  (newValue, oldValue) => {
    unsavedChanges.value = false;
    if (oldValue && !newValue) {
      justSaved.value = true;
      setTimeout(() => {
        justSaved.value = false;
      }, 2000);
    }
  }
);

const linkMenuIsActive = ref(false);
const phoneMenuIsActive = ref(false);
const emailMenuIsActive = ref(false);
const linkText = ref('');
const linkUrl = ref('');
const linkUrlType = ref(URL_OPTIONS.REGULAR);
const phoneLinkValue = ref('');
const emailLinkValue = ref('');

const processedLinkOptions = computed(() => {
  if (linkUrlType.value === URL_OPTIONS.REGULAR)
    return { label: 'Input the web address you want to link to', icon: 'fa-link' };
  if (linkUrlType.value === URL_OPTIONS.PHONE)
    return { label: 'Insert the phone number this should call', icon: 'fa-phone' };
  if (linkUrlType.value === URL_OPTIONS.EMAIL)
    return { label: 'What email address should this refer to?', icon: 'fa-at' };

  return { label: 'Input the web address you want to link to', icon: 'fa-link' };
});

type UrlOptionKeys = keyof typeof URL_OPTIONS;
type UrlOptionValues = (typeof URL_OPTIONS)[UrlOptionKeys];

const sanitizeLinkString = (linkString: string, type: UrlOptionValues) => {
  switch (type) {
    case URL_OPTIONS.PHONE: {
      if (startsWith(linkString, 'tel:')) return linkString;
      return 'tel:' + phoneLinkValue.value;
    }
    case URL_OPTIONS.EMAIL: {
      if (startsWith(linkString, 'mailto:')) return linkString;
      return 'mailto:' + emailLinkValue.value;
    }
    default: {
      if (startsWith(linkString, 'http://')) return linkString;
      if (startsWith(linkString, 'https://')) return linkString;
      return 'http://' + linkUrl.value;
    }
  }
};

watch([linkUrlType], () => {
  if (linkUrlType.value === URL_OPTIONS.REGULAR) {
    if (startsWith(linkUrl.value, 'tel:') || startsWith(linkUrl.value, 'mailto:')) linkUrl.value = '';
  }
});
watch([phoneLinkValue, linkUrlType], () => {
  if (linkUrlType.value === URL_OPTIONS.PHONE)
    linkUrl.value = sanitizeLinkString(phoneLinkValue.value, URL_OPTIONS.PHONE);
});
watch([emailLinkValue, linkUrlType], () => {
  if (linkUrlType.value === URL_OPTIONS.EMAIL)
    linkUrl.value = sanitizeLinkString(emailLinkValue.value, URL_OPTIONS.EMAIL);
});

const pageX = ref<number | null>(null);
const pageY = ref<number | null>(null);

const isEmail = (text: string) => {
  return String(text.trim())
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const showLinkMenu = async (event: Event) => {
  linkUrl.value = '';
  pageX.value = null;
  pageY.value = null;
  await nextTick();
  const selection = document.getSelection();
  const selectionNode = selection?.focusNode as Node & { wholeText: string | number };
  const selectionText: string | number = selectionNode?.wholeText ?? '';
  linkText.value = selectionText.toString();
  if (selection) {
    const node = selection.anchorNode;
    if (node) {
      const elem = node.parentElement as HTMLAnchorElement;
      if (elem && elem.href && elem.href.trim().length > 0) {
        linkUrl.value = elem.href;
      }
    }
  }

  const target = event.target as HTMLInputElement;
  pageX.value = target.getBoundingClientRect().x - 370;
  pageY.value = target.getBoundingClientRect().y - 280;

  linkMenuIsActive.value = true;
  linkUrlType.value = URL_OPTIONS.REGULAR;

  if (linkUrl.value === undefined || !linkUrl.value || (linkUrl.value && linkUrl.value.trim().length === 0)) {
    linkUrl.value = 'https://';
    if (!isNaN(selectionText as number)) {
      linkUrl.value = 'tel:' + (selectionText as string).trim();
      phoneLinkValue.value = (selectionText as string).trim();
    }
    if (isEmail(selectionText as string)) {
      linkUrl.value = 'mailto:' + (selectionText as string).trim();
      emailLinkValue.value = (selectionText as string).trim();
    }
  }

  if (startsWith(linkUrl.value, 'tel:')) {
    linkUrlType.value = URL_OPTIONS.PHONE;
    phoneLinkValue.value = linkUrl.value ? linkUrl.value.slice(4) : '';
  }
  if (startsWith(linkUrl.value, 'mailto:')) {
    linkUrlType.value = URL_OPTIONS.EMAIL;
    emailLinkValue.value = linkUrl.value ? linkUrl.value.slice(7) : '';
  }
};

const hideLinkMenu = () => {
  linkUrl.value = '';
  phoneLinkValue.value = '';
  emailLinkValue.value = '';
  linkMenuIsActive.value = false;
};

const goToLink = () => {
  if (linkUrl.value) {
    window.open(linkUrl.value);
  }
};

const checkIfAllowed = () => {
  if (!props.canEdit) return;
  if (focusedBySomeone.value !== null) {
    nextTick(() => {
      (document.activeElement as HTMLElement)?.blur();
    });
  }
};

const isUrlValid = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

const toast = useToast();

const clearLink = () => {
  editor.value?.chain().focus().extendMarkRange('link').unsetLink().run();
  hideLinkMenu();
};

const setLink = (url: string) => {
  if (!url) return clearLink();
  if (url.trim().length === 0) return clearLink();
  if (!isUrlValid(url)) {
    clearLink();
    return toast.error('Invalid URL');
  }

  // update link
  editor.value
    ?.chain()
    .focus()
    .extendMarkRange('link')
    .setLink({ href: url, target: !props.targetBlank ? '_blank' : '' })
    .run();

  hideLinkMenu();
};

const buttons = ref([
  {
    name: 'bold',
    icon: 'fa-bold',
    function: () => editor.value?.chain().focus().toggleBold().run(),
  },
  {
    name: 'italic',
    icon: 'fa-italic',
    function: () => editor.value?.chain().focus().toggleItalic().run(),
  },
  {
    name: 'underline',
    icon: 'fa-underline',
    function: () => editor.value?.chain().focus().toggleUnderline().run(),
  },
  {
    name: 'paragraph',
    icon: 'fa-paragraph',
    function: () => editor.value?.chain().focus().setParagraph().run(),
  },
  {
    name: 'h1',
    function: () => editor.value?.chain().focus().toggleHeading({ level: 1 }).run(),
  },
  {
    name: 'h2',
    function: () => editor.value?.chain().focus().toggleHeading({ level: 2 }).run(),
  },
  {
    name: 'h3',
    function: () => editor.value?.chain().focus().toggleHeading({ level: 3 }).run(),
  },
  {
    name: 'unOrderedList',
    icon: 'fa-list-ul',
    function: () => editor.value?.chain().focus().toggleBulletList().run(),
  },
  {
    name: 'orderedList',
    icon: 'fa-list-ol',
    function: () => editor.value?.chain().focus().toggleOrderedList().run(),
  },
  {
    name: 'link',
    icon: 'fa-link',
    activeClass:
      'before:block before:bg before:z-10 before:border-backgroundColor-inputs before:border-r before:border-b before:absolute before:w-2 before:h-2 before:rotate-45 before:top-[-0.91rem] before:left-[10px]',
    function: showLinkMenu,
  },
]);

const activeOption = (option: { name: string }) => {
  if (['h1', 'h2', 'h3'].includes(option.name)) {
    switch (option.name) {
      case 'h1':
        return editor.value?.isActive('heading', { level: 1 });
      case 'h2':
        return editor.value?.isActive('heading', { level: 2 });
      case 'h3':
        return editor.value?.isActive('heading', { level: 3 });
      default:
        return false;
    }
  }

  if (option.name === 'link') {
    return linkMenuIsActive.value;
  }
  if (option.name === 'phone') {
    return phoneMenuIsActive.value;
  }
  if (option.name === 'email') {
    return emailMenuIsActive.value;
  }
  return editor?.value?.isActive(option.name);
};

const addWordToContent = (word: string) => {
  if (editor.value) {
    editor.value.commands.insertContent(word, {
      parseOptions: {
        preserveWhitespace: false,
      },
    });
  }
};

const linkButtonActive = ref(false);
const phoneButtonActive = ref(false);
const emailButtonActive = ref(false);

const onClickAction = () => {
  if (linkButtonActive.value) {
    linkButtonActive.value = false;
  }
  if (phoneButtonActive.value) {
    phoneButtonActive.value = false;
  }
  if (emailButtonActive.value) {
    emailButtonActive.value = false;
  }
};

window.document.addEventListener('click', onClickAction);

onBeforeUnmount(() => {
  window.document.removeEventListener('click', onClickAction);
});

const selectingSomething = async () => {
  if (!props.canEdit) return;
  const selection = window.getSelection() as Selection & { baseOffset: number };
  const focusOffset = selection?.focusOffset;
  const baseOffset = selection?.baseOffset;
  linkButtonActive.value = false;
  phoneButtonActive.value = false;
  emailButtonActive.value = false;
  if (!selection) return;
  setTimeout(() => {
    try {
      if (!selection.anchorNode?.parentElement?.classList.contains('ProseMirror')) {
        if (!selection.anchorNode?.parentElement?.parentElement?.classList.contains('ProseMirror')) {
          if (!selection.anchorNode?.parentElement?.parentElement?.parentElement?.classList.contains('ProseMirror')) {
            if (
              !selection.anchorNode?.parentElement?.parentElement?.parentElement?.parentElement?.classList.contains(
                'ProseMirror'
              )
            ) {
              return;
              // if (
              //   !selection.anchorNode.parentElement.parentElement.parentElement.parentElement.parentElement.classList.contains(
              //     'ProseMirror'
              //   )
              // ) {
              //   return;
              // }
            }
          }
        }
      }
    } catch (e) {
      // console.error(e);
      // throw e;
    }
    if (baseOffset !== focusOffset) {
      linkButtonActive.value = true;
      phoneButtonActive.value = true;
      emailButtonActive.value = true;
    }
  }, 20);
};

const getButtonName = (btnName: string) => {
  if (btnName === 'link') {
    if (linkButtonActive.value) return 'Add a link';
    return 'You must select some text to add a link to it';
  }
  if (btnName === 'phone') {
    if (phoneButtonActive.value) return 'Add a phone link';
    return 'You must select some text to add a phone link to it';
  }
  if (btnName === 'email') {
    if (emailButtonActive.value) return 'Add an email link';
    return 'You must select some text to add a email link to it';
  }
  return btnName;
};

const getIsButtonDiabled = (btnName: string) => {
  if (btnName === 'link') return !linkButtonActive.value;
  if (btnName === 'phone') return !phoneButtonActive.value;
  if (btnName === 'email') return !emailButtonActive.value;

  return false;
};

type ArrayType<T> = T extends (infer Item)[] ? Item : T;

const getButtonFunction = (button: ArrayType<typeof buttons.value>, evt: Event) => {
  if (linkMenuIsActive.value) return hideLinkMenu();

  return button.function(evt);
};

const getIsTestLinkDisabled = () => {
  if (linkUrlType.value === URL_OPTIONS.REGULAR) return !linkUrl.value;

  return true;
};
const focusedBySomeone = whisperForArea(props.whisper, inFocus);
</script>

<template>
  <div>
    <div class="@container">
      <InputLabel
        :label="label"
        :class="labelClasses" />
      <div
        v-if="editor"
        :class="{
          ' !cursor-not-allowed  ': !canEdit,
          ' border-soft ': canEdit,
          ' !border-highlight  ': inFocus,
        }"
        class="tip-tap-editor-styling w-full rounded border relative"
        @click="checkIfAllowed">
        <WhisperIndicator
          v-if="focusedBySomeone !== null"
          class="top-0 right-3 z-[1]"
          show-name
          :name="focusedBySomeone?.name" />
        <EditorContent
          :key="keyNumber"
          class="editor flex max-h-[50vh] min-h-[150px] w-full overflow-x-auto rounded-t bg-inputs-background p-3 active:border-highlight [&>.ProseMirror-focused]:!border-l-highlight [&>.ProseMirror-focused]:outline-none [&>.ProseMirror]:border-l-2 [&>.ProseMirror]:border-l-transparent [&>.ProseMirror]:pl-2 [&>.ProseMirror]:transition-colors [&>.prose]:max-w-full"
          :editor="editor"
          @click="selectingSomething"
          @keydown="[(unsavedChanges = true)]" />

        <div
          v-if="canEdit"
          class="tiny-scrollbar force-scrollbar flex overflow-auto border-t">
          <div
            class="ml-3 flex gap-1"
            @mouseenter="selectingSomething()">
            <button
              v-for="(button, index) in buttons"
              :key="button.name"
              class="btn-regular btn-sm relative min-w-[30px] px-1 py-0 disabled:text-soft"
              :title="getButtonName(button.name)"
              :disabled="getIsButtonDiabled(button.name)"
              :class="[
                (activeOption(button)
                  ? 'bg-content text-highlight' + ' ' + button.activeClass
                  : 'hover:bg-content hover:text-highlight') +
                  ' ' +
                  button.class,
                {
                  '@[500px]:ml-10': index === 3,
                },
                {
                  '@[500px]:mr-10': index === 6,
                },
              ]"
              @click="getButtonFunction(button, $event)">
              <div
                class="border-b-2 border-b-transparent px-1 py-1 text-sm"
                :class="activeOption(button) ? '!border-b-highlight' : ''">
                <i
                  v-if="button.icon"
                  class="fa fa-fw"
                  :class="button.icon" />
                {{ button.icon ? '' : button.name }}
              </div>
            </button>
          </div>
          <div
            v-if="unsavedIndicator"
            class="ml-auto mr-2 flex items-center gap-1">
            <span class="sub-title mr-1 text-xxs">{{
              working ? 'Saving...' : unsavedChanges ? 'Unsaved changes' : justSaved ? 'Saved' : ''
            }}</span>
            <i
              v-if="working"
              class="fa fa-fw fa-lg fa-circle-o-notch fa-spin" />
            <span
              v-else-if="unsavedChanges"
              class="fa-stack fa-fw fa-xs">
              <i class="fa fa-save fa-stack-1x" />
              <i class="fa fa-ban fa-stack-2x text-warning" />
            </span>
            <i
              v-else-if="justSaved"
              class="fa fa-fw fa-lg fa-check text-highlight" />
          </div>
        </div>
      </div>
      <WordExchanger
        v-if="exchangeWords.length > 0"
        :items="exchangeWords"
        @add-word="addWordToContent" />
    </div>

    <FloatingWindowContainer
      v-if="(linkMenuIsActive || phoneMenuIsActive || emailMenuIsActive) && pageX && pageY"
      :page-x="pageX"
      :page-y="pageY"
      @closed="setLink(linkUrl)">
      <div class="w-[400px] flex flex-col gap-6">
        <TextInput
          v-model="linkText"
          label="Text to display"
          :can-edit="false"
          left-icon="fa-text" />
        <div>
          <ModeSelector
            v-model="linkUrlType"
            class="mb-2"
            :modes="[
              { value: URL_OPTIONS.REGULAR, name: 'Link', hoverText: 'Link' },
              { value: URL_OPTIONS.PHONE, name: 'Phone', hoverText: 'Phone' },
              { value: URL_OPTIONS.EMAIL, name: 'Email', hoverText: 'Email' },
            ]" />
          <template v-if="linkUrlType === URL_OPTIONS.REGULAR">
            <UrlInput
              v-model="linkUrl"
              :label="processedLinkOptions.label"
              set-focus
              @keydown.esc="hideLinkMenu"
              @keydown.enter="setLink(linkUrl)" />
          </template>
          <template v-else-if="linkUrlType === URL_OPTIONS.PHONE">
            <PhoneInput
              :phone="phoneLinkValue"
              :label="processedLinkOptions.label"
              set-focus
              :with-country-code="false"
              @update:phone="phoneLinkValue = $event"
              @keydown.esc="hideLinkMenu"
              @keydown.enter="setLink(phoneLinkValue)" />
          </template>
          <template v-else-if="linkUrlType === URL_OPTIONS.EMAIL">
            <EmailInput
              v-model="emailLinkValue"
              :label="processedLinkOptions.label"
              set-focus
              @keydown.esc="hideLinkMenu"
              @keydown.enter="setLink(emailLinkValue)" />
          </template>
          <template v-else>
            <TextInput
              v-model="linkUrl"
              :label="processedLinkOptions.label"
              set-focus
              :left-icon="processedLinkOptions.icon"
              @keydown.esc="hideLinkMenu"
              @keydown.enter="setLink(linkUrl)" />
          </template>
        </div>
        <div class="grid grid-cols-4 gap-5">
          <div>
            <VButton
              v-if="linkUrlType === URL_OPTIONS.REGULAR"
              size="extra-small"
              :disabled="getIsTestLinkDisabled()"
              tool-tip-text="Open Link"
              title="Open"
              icon="fa-external-link"
              @click.stop="goToLink" />
          </div>
          <VButton
            size="extra-small"
            tool-tip-text="Save"
            type="success"
            icon="fa-save"
            title="Save"
            @click="setLink(linkUrl)" />
          <VButton
            size="extra-small"
            tool-tip-text="Remove"
            type="warning"
            icon="fa-trash"
            title="Remove"
            @click="setLink('')" />
          <VButton
            size="extra-small"
            tool-tip-text="Clear"
            type="pending"
            title="Cancel"
            icon="fa-times"
            @click="hideLinkMenu()" />
        </div>
      </div>
    </FloatingWindowContainer>
  </div>
</template>
