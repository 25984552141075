<script lang="ts" setup>
import ActiveUsers from '@/components/Navbar/ActiveUsers.vue';
import NotificationListModal from '@/components/Navbar/NotificationListModal.vue';
import { usePageTabsStore } from '@/store/PageTabsStore';
import { goBack, openExternalPage } from '@/util/route';
import { usePage } from '@inertiajs/vue3';
import { onMounted, ref, useTemplateRef } from 'vue';
import TheSubNav from '@/components/Navbar/TheNavbar.vue';
import { useNavBarTitleStore } from '@/composables/use-nav-bar-title';
import UserMenu from '@/components/Navbar/UserMenu.vue';
import TableButtonSelector from '@/components/Tables/Headers/TableButtonSelector.vue';
import { safeHtmlStringify } from '@/util/safe-html-stringify';
import { useUserStore } from '@/store/UserStore';
import { useContextSidebarStore } from '@/store/ContextSidebarStore';

const pageTabsStore = usePageTabsStore();
const contextSidebarStore = useContextSidebarStore();

const userStore = useUserStore();

const navbarTitleStore = useNavBarTitleStore();

const notificationSlideOutOpen = ref(false);
const items = () => {
  return [
    {
      title: 'Knowledge Base ',
      preIcon: 'fa-book fa-regular',
      action: (close: () => void) => {
        openExternalPage('https://help.crescat.io', null, true);
        close();
      },
    },
    {
      type: 'divider',
    },
    {
      title: 'Feedback & Suggestions',
      preIcon: 'fa-envelope-open-text fa-regular',
      action: (close: () => void) => {
        openExternalPage('https://meet.crescat.io/support_suggestion', null, true);
        close();
      },
    },
    {
      title: 'Ask a question',
      preIcon: 'fa-message-question fa-regular',
      action: (close: () => void) => {
        openExternalPage('https://meet.crescat.io/support_question', null, true);
        close();
      },
    },
    {
      title: 'Report an Issue',
      preIcon: 'fa-flag fa-regular',
      action: (close: () => void) => {
        openExternalPage('https://meet.crescat.io/support_issue', null, true);
        close();
      },
    },
    {
      type: 'divider',
    },
    {
      title: "What's new in Crescat?",
      preIcon: 'fa-bullhorn fa-regular',
      action: (close: () => void) => {
        openExternalPage('https://crescat.io/release-notes/', null, true);
        close();
      },
    },
  ];
};

const header = useTemplateRef('header');

const resizeObserver = new ResizeObserver((entries) => {
  const root = document.querySelector(':root');
  if (root) {
    root.style?.setProperty('--navbar-current-height', `${entries[0].target.clientHeight}px`);
  }
});

onMounted(() => {
  resizeObserver.observe(header.value);
});
</script>

<template>
  <header
    ref="header"
    class="main-nav flex flex-col justify-between overflow-hidden">
    <div
      data-theme="base"
      class="flex flex-nowrap gap-5 pr-edge bg text items-center h-navbar justify-between relative"
      :class="{ 'border-b': userStore.theme === 'base' }">
      <div class="flex items-center gap-5 flex-1">
        <div
          id="breadcrumb"
          class="w-full" />

        <div id="after-breadcrumb" />
      </div>

      <div class="flex items-center gap-3 justify-end min-w-fit absolute right-0">
        <div id="before-navbar" />

        <div
          v-if="usePage().props.authenticated"
          id="custom_notification_area" />

        <div
          v-if="usePage().props.authenticated"
          id="share_or_invite_button_area" />

        <ActiveUsers v-if="$page.props.authenticated" />

        <NotificationListModal
          v-if="usePage().props.authenticated"
          v-model:is-open="notificationSlideOutOpen" />

        <TableButtonSelector
          v-if="usePage().props.authenticated"
          dusk="navbar-help-button"
          icon="fa-question"
          tool-tip-text="Need Help?"
          :selected-text="''"
          button-text="Help"
          :options="items()" />

        <UserMenu
          v-if="usePage().props.authenticated"
          dusk="navbar-user-menu" />
      </div>
    </div>

    <div
      v-if="pageTabsStore.inNavbar && navbarTitleStore.allowItemsInSubNav"
      class="flex flex-col gap-3 pt-2">
      <div class="flex gap-2 items-center justify-between mt-3 pr-edge">
        <div
          v-if="navbarTitleStore.navBarBackButton || navbarTitleStore.navBarTitle"
          :class="navbarTitleStore.navBarTitleLarge ? 'text-4xl' : 'text-3xl'"
          class="px-edge flex gap-4 flex-1 items-center truncate">
          <span
            v-if="!navbarTitleStore.navBarBackButton"
            class="rounded border aspect-1 w-[28px] h-[28px] flex items-center justify-center bg-[hsl(var(--color-event-type-blue))]">
            <i
              class="fa fa-fw text-base fa-regular text-[hsl(var(--blue-1100))]"
              :class="navbarTitleStore.navBarTitleIcon" />
          </span>
          <span
            v-if="navbarTitleStore.navBarBackButton"
            @click="goBack()">
            <i class="fa fa-fw fa-arrow-left text-2xl mx-auto" />
          </span>

          <span class="truncate">{{ safeHtmlStringify(navbarTitleStore.navBarTitle) }}</span>
        </div>
        <div>
          <div id="after-navbar-title"></div>
        </div>
      </div>

      <TheSubNav :is-primary="!navbarTitleStore.navBarBackButton && !navbarTitleStore.navBarTitle" />
    </div>
  </header>
</template>
