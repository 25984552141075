import tippy, { type Targets, Placement } from 'tippy.js';
import { type Ref, ref, watch } from 'vue';
import 'tippy.js/dist/tippy.css';

export const tooltip = (
  selector: Targets,
  content: string,
  placement?: Placement = 'top',
  delay?: number = 500,
  disabled: Ref<boolean> = ref(false)
) => {
  if (!selector || !content?.length) return null;

  const instance = tippy(selector, {
    content: content,
    delay,
    placement,
    allowHTML: true,
    theme: '',
  });

  watch(
    disabled,
    (newValue) => {
      if (!instance) return;
      if (newValue) instance.disable();
      if (!newValue) instance.enable();
    },
    {
      immediate: true,
    }
  );
};
