<script lang="ts" setup>
import { cva } from 'class-variance-authority';
import { computed, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    type?: Type;
    size?: Size;
    disabled?: boolean;
    loading?: boolean;
    error?: boolean;
    icon?: string | null;
    title?: string | null;
    toolTipText?: string;
    disabledToolTipText?: string;
    mainAction?: boolean;
  }>(),
  {
    type: 'primary',
    size: 'medium',
    disabled: false,
    loading: false,
    error: false,
    icon: null,
    title: '',
    toolTipText: '',
    disabledToolTipText: '',
    mainAction: false,
  }
);

defineEmits<{
  (e: 'click', arg: MouseEvent): void;
}>();

const button = cva(
  'font-mediumbold whitespace-nowrap capitalize disabled:border disabled:text-disabled disabled:!ring-0 disabled:!cursor-not-allowed   disabled:bg-disabled',
  {
    variants: {
      type: {
        primary:
          'rounded  bg-[--color-background-button-default] border hover:bg-[--color-background-button-default-hover]',
        success: 'btn-success rounded ',
        pending: props.mainAction
          ? 'bg-pending rounded  hover:bg-pending-hover border-pending-hover focus-visible:outline-pending'
          : 'hover:bg-pending rounded  hover:border-pending focus-visible:outline-pending',
        info: 'bg-[--color-background-button-default] rounded  border hover:bg-[--color-background-button-default-hover] ',
        warning: props.mainAction
          ? 'bg-warning rounded   enabled:border-transparent enabled:hover:text-[--color-text-button-warning-hover] text-[--color-text-button-warning] enabled:hover:bg-warning-hover focus-visible:outline-warning'
          : 'hover:bg-warning rounded  hover:border-warning hover:focus-visible:outline-warning enabled:hover:text-[--color-text-button-warning-hover] ',
        hidden: 'btn-hidden focus-visible:outline-white/50 rounded ',
        inTable: 'rounded border bg-[--color-background-button-table] hover:bg-[--color-background-button-table-hover]',

        // To be removed
        'btn-in-table': 'rounded border',
      },
      size: {
        'extra-small': 'btn-xs',
        tiny: 'px-[0.75rem] py-[0.3rem] text-sm [&>*]:text-sm',
        medium: 'px-[2rem] py-[0.4rem]',
        small: 'btn-sm',
        large: 'btn-lg',
        inTable: 'p-2  fa-sm',

        // To be removed
        sm: 'btn-sm',
      },
    },
    defaultVariants: {
      type: 'primary',
      size: 'medium',
    },
  }
);

type Type = 'primary' | 'success' | 'pending' | 'warning' | 'hidden' | 'inTable' | 'info';
type Size = 'extra-small' | 'medium' | 'small' | 'large' | 'inTable' | 'tiny';

const tooltip = computed(() => {
  if (props.loading) return 'loading';
  if (props.disabled && props.disabledToolTipText) return props.disabledToolTipText;
  return props.toolTipText;
});

const btn = ref<HTMLButtonElement | null>();

defineExpose({
  button: btn,
});

const getSizeType = (size: Size) => {
  if (props.type === 'inTable') return 'inTable';
  return size;
};
</script>

<template>
  <button
    ref="btn"
    :class="button({ type, size: getSizeType(size) })"
    class="relative focus:outline-none"
    :title="tooltip"
    type="button"
    :disabled="disabled || loading"
    @click.stop="$emit('click', $event)">
    <span :class="loading && !icon ? 'invisible' : 'visible'">
      <slot>
        <i
          v-if="icon && !loading"
          :class="[icon, { 'mr-1': !!title }]"
          class="fa fa-fw" />
        <i
          v-else-if="icon && loading"
          :class="{ 'mr-1': !!title }"
          class="fa fa-fw fa-circle-o-notch fa-spin" />
        {{ title ? title : '' }}
      </slot>
    </span>

    <span
      v-show="loading && !icon"
      class="absolute left-0 top-0 flex h-full w-full items-center justify-center">
      <i class="fa fa-fw fa-circle-o-notch fa-spin" />
    </span>
  </button>
</template>
