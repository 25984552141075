<script lang="ts" setup>
import { TaskOwnerModels, TaskResource } from '@/types/tasks';
import { patchTask } from '@/services/api-tasks';
import {
  changeAndFormatStamp,
  dateIsToday,
  formatStampAsDate,
  formatStampAsHumanReadableDate,
  getNow,
  stampIsPast,
  timeStampsAreSame,
} from '@/util/timeFunctions';
import { computed, inject, ref } from 'vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import { humanReadableDateFormat } from '@/variables/date-format';
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import VButton from '@/components/Inputs/VButton.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import moment from 'moment';
import { startKey } from '@/provide/keys';

type Props = {
  task: TaskResource;
  lockedKeys?: string[];
  inList?: boolean;
  isCreate?: boolean;
  emitResults?: boolean;
  ownerModel?: TaskOwnerModels;
};

const props = withDefaults(defineProps<Props>(), {
  lockedKeys: () => [],
  inList: true,
  emitResults: false,
  isCreate: false,
  ownerModel: null,
});
const emit = defineEmits<{
  (e: 'updated', value: TaskResource): void;
}>();

const isClicked = ref(false);

const selectDate = async (newDate: string | null) => {
  const localTask = { ...props.task };
  overrideStartDate.value = null;
  localTask.due_date = formatStampAsDate(newDate);
  if (props.emitResults) {
    emit('updated', localTask);
    return;
  }

  await patchTask(props.task.uuid, {
    due_date: localTask.due_date,
  });

  emit('updated', localTask);
  isClicked.value = false;
};
const overrideStartDate = ref(null);
const calendarKey = ref(0);
const stateDate = computed(() => {
  if (overrideStartDate.value) {
    return overrideStartDate.value;
  }
  if (props.task.event) {
    return props.task.event.start_date;
  }
  if (props.task.due_date) {
    return props.task.due_date;
  }
  return null;
});
const getTaskDueDate = () => {
  if (!props.task.due_date) {
    if (props.isCreate) {
      return 'Add Due Date';
    }
    return 'No Due Date';
  }
  if (dateIsToday(props.task.due_date)) {
    return 'Today';
  }
  if (timeStampsAreSame(props.task.due_date, changeAndFormatStamp({ stamp: getNow(), addDays: 1 }))) {
    return 'Tomorrow';
  }
  return formatStampAsHumanReadableDate(
    props.task.due_date,
    'MMM Do ' + (!timeStampsAreSame(props.task.due_date, getNow(), 'year') ? 'Y' : '')
  );
};
const isTaskOverDue = () => {
  if (!props.task.due_date || props.task.completed_at) {
    return false;
  }
  return stampIsPast(props.task.due_date, 'day');
};

const viewingMonth = ref(null);
const viewingYear = ref(null);
const startFromKey = inject(startKey, null);

const goToTodayIsDisabled = () => {
  if (!viewingMonth.value || !viewingYear.value) {
    return timeStampsAreSame(props.task.due_date, getNow(), 'month');
  }
  return timeStampsAreSame(getNow(), moment([viewingYear.value, viewingMonth.value]), 'month');
};

const changeCalendarToDate = (newDate) => {
  overrideStartDate.value = newDate;
  viewingYear.value = formatStampAsDate(newDate, 'Y');
  viewingMonth.value = formatStampAsDate(newDate, 'M') - 1;
  calendarKey.value++;
};
</script>

<template>
  <VDropdown
    close-on-click
    :items="[]"
    :have-max-width="false"
    @dropdown-closed="isClicked = false"
    @dropdown-opened="[(isClicked = true)]">
    <template #click-area>
      <button
        :class="{
          '!bg-row-hover': isClicked,
          'text-soft': task.completed_at || !task.due_date,
          'italic': !task.due_date,
          '!text-warning [&_*]:!text-warning': isTaskOverDue(),
          'hover:!bg-row-alternate min-w-[150px] ': inList,
          '  min-w-[250px] max-w-[250px] ': !inList,
        }"
        :title="formatStampAsHumanReadableDate(task.due_date, humanReadableDateFormat + ' YYYY')"
        class="btn btn-tiny btn-info text bg-transparent !ring-transparent border-transparent py-[2px] flex items-center gap-1 justify-between">
        <!--        class="button-for-due-date font-headers font-semibold btn-tiny text bg-transparent !ring-transparent truncate py-[2px] flex items-center gap-1 justify-between">-->
        <i
          v-if="isCreate && !task.due_date"
          class="fa fa-fw fa-calendar-o fa-regular mr-2"></i>
        {{ getTaskDueDate() }}
        <i class="fa fa-fw text-xxs fa-chevron-down" />
        <!--        <i class="fa fa-fw text-xxs fa-chevron-down text" />-->
      </button>
    </template>
    <template #dropdown="{ close }">
      <div class="flex justify-between p-edge">
        <InputLabel label="Due Date" />
        <div class="flex gap-edge items-center">
          <div
            v-if="
              ['Festival', 'FestivalSection'].includes(ownerModel) ||
              ['App\\Festival', 'App\\Models\\Festivals\\FestivalSection'].includes(task.owner_type)
            ">
            <VButton
              v-if="!goToTodayIsDisabled() || !startFromKey"
              size="extra-small"
              :disabled="goToTodayIsDisabled()"
              title="Today"
              icon="fa-calendar-day fa-regular"
              @click="changeCalendarToDate(getNow())" />
            <VButton
              v-else-if="startFromKey"
              size="extra-small"
              icon="fa-calendar-day fa-regular"
              title="Festival"
              @click="changeCalendarToDate(startFromKey)" />
          </div>
          <VButton
            size="extra-small"
            :disabled="!task.due_date"
            title="Clear"
            @click="[selectDate(null), close()]"></VButton>
        </div>
      </div>
      <VDatepicker
        :key="calendarKey"
        inline
        :model-value="overrideStartDate ? null : task.due_date"
        :label="null"
        :start-date="stateDate"
        :with-icon="false"
        can-edit
        set-focus
        @viewing:month="viewingMonth = $event"
        @viewing:year="viewingYear = $event"
        @closed="[(isClicked = false), close()]"
        @clear="[selectDate(null), close()]"
        @update:model-value="[selectDate($event), close()]" />
    </template>
  </VDropdown>
</template>
