<script setup lang="ts">
import { nextTick, ref, watch } from 'vue';
import AuditsSidebar from '@/components/Audits/AuditsSidebar.vue';
import { usePage } from '@inertiajs/vue3';
import { getNow } from '@/util/timeFunctions';

type Props = {
  url: string;
  title: string;
  delay?: number | null;
  auditTicker?: number | null;
  externalAuditTicker?: number | null;
  queryableParameters: any;
  allowedFields?: any[];
  canFilterModels?: boolean;
  canFilterAuditEvents?: boolean;
  canOpenAuditsModal?: boolean | null;
  openAuditsOutside?: boolean | null;
};

const props = withDefaults(defineProps<Props>(), {
  allowedFields: () => [],
  canFilterModels: false,
  canOpenAuditsModal: false,
  canFilterAuditEvents: false,
  openAuditsOutside: false,
  delay: 0,
  auditTicker: 0,
  externalAuditTicker: 0,
});

const emit = defineEmits<{
  (event: 'open-audits'): void;
  (event: 'closed', ...args: any[]): void;
  (event: 'restored', ...args: any[]): void;
}>();

const loading = ref(true);
const lastAudit = ref(null);
const audits = ref([]);

const loadAudits = async () => {
  loading.value = true;

  const { data } = await axios.get(props.url, {
    params: {
      page: 1,
      per_page: 1,
      auditable_types: props.queryableParameters.flatMap((type) => {
        if (type.hasOwnProperty('param')) {
          if (Array.isArray(type.param)) {
            return type.param.map((param) => param);
          }
          return type.param;
        }
        return type.id;
      }),
    },
  });
  if (data.data.length) {
    audits.value = data.data;
    lastAudit.value = data.data[0];
  }
  loading.value = false;
};
setTimeout(() => {
  loadAudits();
}, props.delay);

const auditsModalOpen = ref(false);
const openAuditModal = async () => {
  if (!props.canOpenAuditsModal) return;
  if (props.openAuditsOutside) {
    emit('open-audits');
    return;
  }
  auditsModalOpen.value = false;
  await nextTick();
  auditsModalOpen.value = true;
};
watch(
  () => props.auditTicker,
  () => {
    lastAudit.value = {
      user: { name: usePage().props.auth.user.name },
      created_at: getNow(),
    };
  }
);
watch(
  () => props.externalAuditTicker,
  () => {
    setTimeout(() => {
      loadAudits();
    }, props.delay);
  }
);
</script>

<template>
  <div>
    <div class="pt-2 pl-edge pr-2 text-right h-[20px]">
      <div
        v-if="lastAudit"
        :class="{ 'hover:underline cursor-pointer': canOpenAuditsModal }"
        class="!sub-title text-soft"
        @click="openAuditModal">
        Last edited by <span class="text-highlight">{{ lastAudit.user ? lastAudit.user.name : 'Someone' }}</span> at
        <span class="text-highlight">{{ lastAudit.created_at }}</span>
      </div>
    </div>

    <AuditsSidebar
      v-if="canOpenAuditsModal && auditsModalOpen"
      :allowed-fields="allowedFields"
      :queryable-parameters="queryableParameters"
      :can-filter-audit-events="canFilterAuditEvents"
      :url="url"
      :with-button="false"
      :title="title" />
  </div>
</template>
