<script lang="ts" setup>
import { TaskResource } from '@/types/tasks';
import { formatModelAndIdToId, formatUserForTaskListForEvent, TaskAssignableListResource } from '@/util/task-functions';
import { getItemFromArrayBasedOnId, getKey, uniqueObjectsFromArray } from '@/util/globals';
import {
  eventMembersKey,
  festivalProjectLeadersKey,
  festivalSectionKey,
  festivalSectionsKey,
  groupKey,
  groupResourceKey,
} from '@/provide/keys';
import { inject, nextTick, ref, watch } from 'vue';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import { patchTask } from '@/services/api-tasks';
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import UserPlaceholderIcon from '../UserPlaceholderIcon.vue';

type Props = {
  task: TaskResource;
  lockedKeys?: string[];
  openDropdown?: boolean;
  inList?: boolean;
  isCreate?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  lockedKeys: () => [],
  inList: true,
  isCreate: false,
  openDropdown: false,
});
const emit = defineEmits<{
  (e: 'updated', value: TaskResource): void;
  (e: 'newUserId', value: number): void;
}>();

const eventMembers = inject(eventMembersKey, null);
const festivalProjectLeaders = inject(festivalProjectLeadersKey, null);
const allAssignableUsers = ref([]);
const group = inject(groupKey, null);
const groupResource = inject(groupResourceKey, null);
const allFestivalSections = inject(festivalSectionsKey, null);
const thisOneFestivalSection = inject(festivalSectionKey, null);

const selectUser = async (userId: number | null) => {
  if (props.isCreate) {
    emit('newUserId', userId);
    return;
  }
  const localTask = { ...props.task };
  localTask.assigned_user_id = userId;
  await patchTask(localTask.uuid, {
    assigned_user_id: localTask.assigned_user_id,
  });

  if (userId) {
    const user = getItemFromArrayBasedOnId(userId, allAssignableUsers.value);
    if (user) {
      localTask.assigned_user = {
        id: userId,
        name: user.name,
      };
    }
  } else {
    localTask.assigned_user = null;
  }
  emit('updated', localTask);
};
const getPossibleAssignableUsers = (assignableUsers: TaskAssignableListResource[]) => {
  if (props.task.context_id) {
    return uniqueObjectsFromArray(
      getKey(
        assignableUsers.find(
          (i) => i.formattedModelId === formatModelAndIdToId(props.task.context_type, props.task.context_id)
        ),
        'options',
        []
      )
    );
  }
  return uniqueObjectsFromArray(
    getKey(
      assignableUsers.find((i) => i.formattedModelId === null),
      'options',
      []
    )
  );
};
const searchTerm = ref('');
const loadingContent = ref(false);

const getMembersFromStore = async () => {
  if (!eventMembers) return;
  loadingContent.value = true;
  await eventMembers.fetch(false);
  const assignableUsers = formatUserForTaskListForEvent(eventMembers.invites.value, eventMembers.allMembers.value);
  allAssignableUsers.value = getPossibleAssignableUsers(assignableUsers);
  loadingContent.value = false;
};
const fetchUsersForTask = async () => {
  if (eventMembers) return;
  loadingContent.value = true;
  const { data } = await axios.get(`/api/tasks/${props.task.uuid}/assignable-users`);
  allAssignableUsers.value = data;
  loadingContent.value = false;
};
const loadData = async () => {
  searchTerm.value = '';
  if (props.isCreate) {
    if (groupResource) {
      await groupResource.fetch();
    }
    allAssignableUsers.value = getUsersForCreate();
    return;
  }
  if (eventMembers) {
    await getMembersFromStore();
  } else {
    await fetchUsersForTask();
  }
};

const getOptionsForList = () => {
  const filteredAssignableUsers = allAssignableUsers.value?.filter((f) =>
    searchTerm.value.length === 0 ? true : f.name.toLowerCase().includes(searchTerm.value.toLowerCase())
  );

  return [
    {
      color: null,
      postIcon: props.task.assigned_user_id === null ? 'fa-check' : null,
      title: '--',
      action: (close) => {
        selectUser(null);
        close();
      },
    },
  ].concat(
    filteredAssignableUsers.map((user) => {
      return {
        title: user.name,
        postIcon: user.id === props.task.assigned_user_id ? 'fa-check' : null,
        action: (close) => {
          selectUser(user.id);
          close();
        },
      };
    })
  );
};

const open = ref(false);
const forceOpen = ref(false);

watch(
  () => props.openDropdown,
  async () => {
    if (!props.openDropdown) return;
    forceOpen.value = false;
    await loadData();
    if (allAssignableUsers.value.length === 0) {
      return;
    }
    await nextTick();
    forceOpen.value = true;
    open.value = true;
  }
);

const getUsersForCreate = () => {
  if (group) {
    if (!props.task.context_id || (props.task.context_id === group.id && props.task.context_type === 'App\\Group')) {
      return group.members.map((m) => {
        return {
          id: m.id,
          name: m.name,
        };
      });
    }
    const child =
      props.task.context_type === 'App\\Group' ? group.childGroups.find((c) => c.id === props.task.context_id) : null;
    if (child) {
      return child.members.map((m) => {
        return {
          id: m.id,
          name: m.name,
        };
      });
    }
  }
  if (groupResource && groupResource.group.value) {
    if (
      !props.task.context_id ||
      (props.task.context_id === groupResource.group.value?.id && props.task.context_type === 'App\\Group')
    ) {
      return groupResource.group.value?.members.map((m) => {
        return {
          id: m.id,
          name: m.name,
        };
      });
    }
    const child =
      props.task.context_type === 'App\\Group'
        ? groupResource.group.value?.children.find((c) => c.id === props.task.context_id)
        : null;
    if (child) {
      return child.members.map((m) => {
        return {
          id: m.id,
          name: m.name,
        };
      });
    }
  }

  if (eventMembers && eventMembers.loaded && eventMembers.invites.value) {
    const option = formatUserForTaskListForEvent(eventMembers.invites.value, eventMembers.allMembers.value).find(
      (o) =>
        o.formattedModelId ===
        (props.task.context_id ? formatModelAndIdToId(props.task.context_type, props.task.context_id) : null)
    );
    if (option) {
      return option.options;
    }
  }
  if (festivalProjectLeaders && (!props.task.context_type || props.task.context_type === 'App\\Role')) {
    festivalProjectLeaders.fetchProjectLeaders(false);
    return festivalProjectLeaders.festivalProjectLeaders.value
      ?.filter((p) => {
        if (!props.task.context_type) return true;
        if (props.task.context_type === 'App\\Role') {
          return p.role_id === props.task.context_id;
        }
        return false;
      })
      .map((p) => {
        return {
          id: p.user.id,
          name: p.user.name,
          avatar_url: p.user.avatar_url,
        };
      });
  }
  if (allFestivalSections && props.task.context_type === 'App\\Models\\Festivals\\FestivalSection') {
    allFestivalSections.fetchSections(false);
    return allFestivalSections.festivalSections.value
      ?.filter((p) => {
        return p.id === props.task.context_id;
      })
      .flatMap((p) => {
        return p.users.map((u) => {
          return {
            id: u.id,
            name: u.name,
            avatar_url: u.avatar_url,
          };
        });
      });
  }
  if (thisOneFestivalSection && props.task.context_type === 'App\\Models\\Festivals\\FestivalSection') {
    return thisOneFestivalSection.users.map((u) => {
      return {
        id: u.id,
        name: u.name,
        avatar_url: u.avatar_url,
      };
    });
  }
  return [];
};

if (props.isCreate) {
  allAssignableUsers.value = getUsersForCreate();
}

const getNameOfUser = () => {
  if (props.isCreate) {
    const user = getItemFromArrayBasedOnId(props.task.assigned_user_id, allAssignableUsers.value);
    if (user) {
      return user.name;
    }
    return 'Add Assignee';
  }
  return props.task.assigned_user ? props.task.assigned_user.name : 'No Assignee';
};
</script>

<template>
  <VDropdown
    v-if="!lockedKeys.includes('assignee')"
    close-on-click
    :items="getOptionsForList()"
    :have-max-width="false"
    :open-dropdown-from-outside="forceOpen"
    :highlight-text="searchTerm"
    with-arrows-up-and-down
    @dropdown-closed="open = false"
    @dropdown-opened="[loadData(), (open = true)]">
    <template #click-area>
      <button
        :class="{
          '!bg-row-hover': open,
          'text-soft': task.completed_at,
          'hover:!bg-row-alternate min-w-[190px] max-w-[190px] ': inList,
          ' min-w-[250px] max-w-[250px]  ': !inList,
          ' text-soft italic': !task.assigned_user_id,
        }"
        class="btn btn-tiny btn-info text border-transparent bg-transparent !ring-transparent py-[2px] flex items-center gap-1 justify-between">
        <span class="truncate">
          <i
            v-if="!task.assigned_user_id && isCreate"
            class="fa fa-fw fa-user fa-regular mr-2"></i>
          {{ getNameOfUser() }}
        </span>
        <i class="fa fa-fw text-xxs fa-chevron-down" />
      </button>
    </template>
    <template
      v-if="!loadingContent"
      #aboveDropdown>
      <TextInput
        v-model="searchTerm"
        placeholder="Search"
        set-focus
        square />
    </template>
    <template
      v-if="loadingContent || (eventMembers !== null && eventMembers.loading.value)"
      #dropdown>
      <div class="bg w-full h-10 flex justify-center">
        <IconWithLoading loading />
      </div>
    </template>
  </VDropdown>
  <div
    v-else
    class="text px-edge text-sm font-headers">
    {{ getNameOfUser() }}
  </div>
</template>
