<script setup lang="ts">
import InvoiceRowCRUDModal from '@/components/Models/Invoices/InvoiceRowCRUDModal.vue';
import { getInvoiceBase, getInvoiceRow } from '@/services/api-invoice-rows';
import { InvoiceBasesResource, InvoiceRowCategoryResource, InvoiceRowResource } from '@/types/invoice-row';
import { ShowTimeResource } from '@/types/show-time';
import { getItemFromArrayBasedOnId, numberWithCommas } from '@/util/globals';
import ContentContainer from '@/components/Content/ContentContainer.vue';
import { computed, inject, markRaw, nextTick, onMounted, ref, watch } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { useToast } from 'vue-toastification';
import InvoiceBasisDownloadModal from '@/components/Models/Invoices/InvoiceBasisDownloadModal.vue';
import TestTable, {
  type BlurData,
  SortEmit,
  TableCell,
  type TableHeader,
  type TableRow,
} from '@/components/TestTable.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import NumberInput from '@/components/Inputs/NumberInput.vue';
import PercentInput from '@/components/Inputs/PercentInput.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import { eventTypesKey } from '@/provide/keys';
import { useSmallScreen } from '@/composables/use-small-screen';

type Props = {
  model: 'Group' | 'Invite';
  modelId: number;
  canEdit: boolean;
  isSingleInvoice: boolean;
  isTemplate: boolean;
  showTimes: ShowTimeResource[];
  categories: InvoiceRowCategoryResource[];
  products: InvoiceRowResource[];
  invoiceBase: InvoiceBasesResource;
  templateGroupId: number | null;
  isDisplay: boolean;
};
const props = withDefaults(defineProps<Props>(), {
  isSingleInvoice: false,
  canEdit: false,
  showTimes: () => [],
  categories: () => [],
  products: () => [],
});

defineEmits<{
  (event: 'edit', ...args: unknown[]): void;
}>();

const { isSmallScreen } = useSmallScreen();

const editMode = ref(props.canEdit);
const open = ref(false);
const hasLoaded = ref(false);
const rows = ref<Map<number | null, InvoiceRowResource[]>>(new Map());
const loading = ref(false);

const newRows = ref<
  {
    groupId: number;
    groupTitle: string;
    rows: TableRow[];
  }[]
>();

const fetchInvoiceRow = async (id: number) => {
  const { data } = await axios.get(`/api/invoice-rows/${id}`);

  const groupRow = newRows.value?.find((r) => r.rows.find((rr) => rr.id === id));
  if (!groupRow) return;

  const row = groupRow.rows.find((r) => r.id === id);
  if (!row) return;

  const oldRow = getRow(id);

  if (!oldRow) return;

  if (oldRow.category_id !== data.category_id) {
    removeRow(id);
    addRow(data);
    return;
  }
  row.columns = getColumns(data);
};

const listenForBroadcastOnRow = async (rowId: number) => {
  if (!props.model) return;

  Echo.channel(`private-${props.model}.${props.modelId}`).listen(`.invoiceRow.${rowId}.updated`, () => {
    fetchInvoiceRow(rowId);
  });
};

const getColumns = (row: InvoiceRowResource) => {
  const data = [
    {
      id: 'title',
      value: row.title,
      component: markRaw(TextareaInput),
      componentProps: {
        'is-hidden': true,
        'min-rows': 1,
        'min-height': 40,
        class: 'w-[300px]',
      },
    },
    {
      id: 'quantity',
      value: row.quantity,
      component: markRaw(NumberInput),
      componentProps: {
        'is-hidden': true,
        'with-decimals': true,
        'min': -999999999,
        'placeholder': 'Quantity',
        class: '[&_input]:w-[80px]',
      },
      class: `w-[80px]`,
    },
    {
      id: 'price',
      value: row.price,
      component: row.component === 'invoice-row-list' ? markRaw(VSelect) : markRaw(NumberInput),
      componentProps: {
        'is-hidden': true,
        'with-decimals': true,
        'min': -999999999,
        'placeholder': 'Quantity',
        'dont-force-decimals': false,
        'options': row.component === 'invoice-row-list' ? row.options : [],
        'option-value': 'title',
        'option-key': 'value',
        class: '[&_input]:w-[110px]',
      },
      class: `w-[110px]`,
      canSlot: row.component === 'invoice-row-list',
    },
    {
      id: 'discount',
      value: row.discount,
      component: markRaw(PercentInput),
      componentProps: {
        'is-hidden': true,
        'placeholder': 'Discount',
        size: 'block',
        class: '[&_input]:w-[100px]',
      },
      class: `w-[100px]`,
    },
  ] as TableCell[];

  if (props.invoiceBase.with_vat) {
    data.push({
      id: 'vat',
      value: row.vat,
      component: markRaw(PercentInput),
      componentProps: {
        'is-hidden': true,
        'placeholder': 'VAT',
        size: 'block',
        class: '[&_input]:w-[100px]',
      },
      class: `w-[100px]`,
    });

    data.push({
      id: 'total',
      value: row.total,
      canSlot: true,
    });

    data.push({
      id: 'total_with_vat',
      value: row.total * (1 + parseFloat(row.vat)),
      canSlot: true,
    });
  } else {
    data.push({
      id: 'total',
      value: row.total,
      canSlot: true,
      class: `w-[100px]`,
    });
  }

  return data;
};

const createTableRows = () => {
  let temp = [];

  rows.value.forEach((value, key) => {
    temp = [
      ...(temp ?? []),
      {
        groupId: Number(key) > 0 ? Number(key) : 0,
        groupTitle: getItemFromArrayBasedOnId(Number(key), props.categories, { title: 'Without Category' }).title,
        groupOrder: getItemFromArrayBasedOnId(Number(key), props.categories, { order: 0 }).order,
        rows: value.map((row) => ({
          id: row.id,
          canDrag: true,
          canEdit: true,
          columns: getColumns(row),
        })),
      },
    ];
  });
  newRows.value = temp.slice().sort((a, b) => {
    if (a.groupOrder === 0) {
      return 1;
    }
    if (b.groupOrder === 0) {
      return -1;
    }
    return a.groupOrder < b.groupOrder ? -1 : 1;
  });
};

const getRow = (rowId: number) => {
  let row: InvoiceRowResource | null = null;

  rows.value.forEach((value, key) => {
    value.forEach((r) => {
      if (r.id === Number(rowId)) {
        row = r;
      }
    });
  });

  if (!row) throw new Error(`Row with id ${rowId} not found`);

  return row;
};

watch(
  rows,
  () => {
    createTableRows();
  },
  { deep: true }
);

watch(
  () => props.categories,
  () => {
    createTableRows();
  },
  { deep: true }
);

watch(
  () => props.invoiceBase.with_vat,
  () => {
    createTableRows();
  }
);

const setupInvoiceFromData = (data) => {
  data.rows.forEach((row) => {
    if (rows.value.has(row.category_id)) {
      const data = rows.value.get(row.category_id);
      if (data) {
        data.push(row);
      }
    } else {
      rows.value.set(row.category_id, [row]);
    }
  });

  data.rows.forEach((row) => {
    listenForBroadcastOnRow(row.id);
  });
};

const fetchInvoiceBasis = async () => {
  if (loading.value) return;

  loading.value = true;

  const { data } = await getInvoiceBase(props.invoiceBase.id);
  setupInvoiceFromData(data);
  hasLoaded.value = true;
  loading.value = false;
};

setupInvoiceFromData(props.invoiceBase);
if (props.isDisplay) {
  open.value = true;
}

const openInvoiceBase = async () => {
  open.value = !open.value;
};

const showRowModal = ref(false);
const rowToEdit = ref<InvoiceRowResource | null>(null);

const updateRow = (row: InvoiceRowResource) => {
  const oldRow = getRow(row.id);

  if (oldRow.category_id !== row.category_id) {
    removeRow(row.id);
    addRow(row);
    return;
  }

  const category = rows.value.get(row.category_id);

  if (category === undefined) return;

  let newData = category.map((r) => (r.id === row.id ? row : r));

  rows.value.set(row.category_id, newData);
};

const addRow = (newRow: InvoiceRowResource) => {
  const category = rows.value.get(newRow.category_id);

  if (category === undefined) {
    rows.value.set(newRow.category_id, [newRow]);
  } else {
    category.push(newRow);
  }
};

const removeRow = (rowId: number) => {
  const row = getRow(rowId);
  if (!row) return;

  const category = rows.value.get(row.category_id);
  if (category === undefined) return;

  category.splice(
    category.findIndex((r) => r.id === rowId),
    1
  );

  if (category.length === 0) {
    rows.value.delete(row.category_id);
  }
};

const templateGroup = ref(null);

const index = _.findIndex(usePage().props.auth.user.groups, (u) => u.id === props.templateGroupId);

if (index > -1) {
  templateGroup.value = usePage().props.auth.user.groups[index];
}

const saveAsTemplate = async () => {
  if (!templateGroup.value) return;

  const certain = await useCertaintyModal().assertCertain(
    `Save ${props.invoiceBase.title} as template on ${templateGroup.value.name}`,
    `Are you sure you want to add ${props.invoiceBase.title} as a template to ${templateGroup.value.name}?`
  );

  if (!certain) return;

  await axios.post(`/api/invoice-bases/${props.invoiceBase.id}/save-as-template`, {
    model_type: 'App\\Group',
    model_id: templateGroup.value.id,
  });

  useToast().success('Added');
};

const downloadModalOpen = ref(false);
const downloadingReport = ref(false);
const openProductModal = async () => {
  rowToEdit.value = null;
  showRowModal.value = false;
  await nextTick();
  showRowModal.value = true;
};
const actions = computed(() => {
  const array = [];
  if (editMode.value && props.canEdit) {
    array.push({
      title: 'Add Product',
      icon: 'fa-plus',
      action: () => {
        openProductModal();
      },
    });
  }

  if (props.model === 'Invite' && !isSmallScreen.value) {
    array.push({
      title: 'Download',
      icon: 'fa-download',
      loading: downloadingReport.value,
      action: () => {
        downloadModalOpen.value = false;
        nextTick(() => {
          downloadModalOpen.value = true;
        });
      },
    });
    if (templateGroup.value) {
      array.push({
        icon: 'fa-save',
        title: 'Save as template',
        action: () => {
          saveAsTemplate();
        },
      });
    }
  }
  return array;
});

const listenForBroadcast = () => {
  Echo.channel(`private-${props.model}.${props.modelId}`)
    .listen(`.invoiceBasis.${props.invoiceBase.id}.updated`, () => {
      fetchInvoiceBasis();
    })
    .listen(`.invoiceBasis.${props.invoiceBase.id}.invoiceRow.created`, (e) => {
      fetchInvoiceRow(e.id);
    })
    .listen(`.invoiceBasis.${props.invoiceBase.id}.invoiceRow.orderChanged`, ({ ids }) => {
      const row = getRow(Number(ids[0]));

      if (!row) return;

      const oldRow = getRow(row.id);

      if (oldRow.category_id !== row.category_id) {
        removeRow(row.id);
        addRow(row);
        return;
      }

      const category = rows.value.get(row.category_id);

      if (category === undefined) return;

      const array = [];

      ids.forEach((id, index) => {
        const row = category.find((r) => r.id === Number(id));
        if (row) {
          array.push(row);
        } else {
          throw new Error(`Row with id ${id} not found`);
        }
      });

      rows.value.set(row.category_id, array);
    })
    .listen(`.invoiceBasis.${props.invoiceBase.id}.invoiceRow.deleted`, (e) => {
      fetchInvoiceRow(e.id);
    });
};

onMounted(() => {
  listenForBroadcast();
});

const invoiceHeaders = computed(() => {
  const data = [
    {
      key: 'description',
      sortable: true,
      label: 'Description',
      class: 'w-[400px]',
    },
    {
      key: 'quantity',
      sortable: true,
      label: 'Quantity',
    },
    {
      key: 'price',
      sortable: true,
      label: 'Unit Price',
    },
    {
      key: 'discount',
      sortable: true,
      label: 'Discount',
    },
  ] as TableHeader[];

  if (props.invoiceBase.with_vat) {
    data.push({
      key: 'vat',
      sortable: true,
      label: 'VAT',
    });
    data.push({
      key: 'total',
      sortable: true,
      label: 'Price',
      html: `<div>Price <span class="text-highlight">Ex VAT</span></div>`,
      labelPosition: 'right',
    });
    data.push({
      key: 'total_with_vat',
      sortable: true,
      label: 'Price Inc VAT',
      html: `<div>Price <span class="text-highlight">Inc VAT</span></div>`,
      labelPosition: 'right',
    });
  } else {
    data.push({
      key: 'total',
      sortable: true,
      label: 'Price',
      labelPosition: 'right',
    });
  }

  return data;
});

const getTotalVat = (rows: TableRow[]) => {
  let total = 0;

  rows.forEach((row) => {
    const totalColumn = row.columns.find((c) => c.id === 'total');
    const vatColumn = row.columns.find((c) => c.id === 'vat');
    if (!totalColumn || !vatColumn) return;
    total += Number(totalColumn.value) * Number(vatColumn.value);
  });

  return total;
};

const getTotal = (rows: TableRow[]) => {
  let total = 0;

  rows.forEach((row) => {
    const value = props.invoiceBase.with_vat ? 'total_with_vat' : 'total';

    const totalColumn = row.columns.find((c) => c.id === value);

    if (!totalColumn) return;

    total += Number(totalColumn.value);
  });

  return total;
};

const saveData = async (data: BlurData, dataRows: TableRow[]) => {
  const oldRow = getRow(Number(data.rowId));

  if (!oldRow) {
    useToast().error('Something went wrong');
    return;
  }

  if (oldRow[data.columnId] === data.value) return;

  const row = dataRows.find((r) => r.id === data.rowId);

  if (!row) {
    useToast().error('Something went wrong');
    return;
  }

  const newData = row.columns.find((c) => c.id === data.columnId);

  if (!newData) {
    useToast().error('Something went wrong');
    return;
  }

  if (data.columnId === 'title' && (!data.value || data.value.length === 0)) {
    return;
  }

  const quantity = row?.columns.find((c) => c.id === 'quantity');
  const price = row?.columns.find((c) => c.id === 'price');
  const discount = row?.columns.find((c) => c.id === 'discount');
  const total = row?.columns.find((c) => c.id === 'total');
  const totalWithVat = props.invoiceBase.with_vat ? row?.columns.find((c) => c.id === 'total_with_vat') : true;
  const vat = props.invoiceBase.with_vat ? row?.columns.find((c) => c.id === 'vat') : true;

  if (!quantity || !price || !discount || !total || !totalWithVat || !vat) {
    useToast().error('Something went wrong');
    return;
  }

  const newTotal = Number(
    (
      Math.round(parseFloat(quantity.value) * parseFloat(price.value) * (1 - parseFloat(discount.value)) * 100) / 100
    ).toFixed(2)
  ).toFixed(2);

  await axios.patch(`/api/invoice-rows/${data.rowId}`, {
    [data.columnId]: data.value,
    total: newTotal,
  });

  if (props.invoiceBase.with_vat) {
    totalWithVat.value = numberWithCommas(Number(newTotal) * (1 + parseFloat(vat.value)));
  }

  if (!oldRow) return;

  oldRow[data.columnId] = data.value;
  oldRow.total = newTotal;

  useToast().success('Saved');
};

const concertRowToNumber = async (invoiceRow: InvoiceRowResource) => {
  await axios.patch(`/api/invoice-rows/${invoiceRow.id}`, {
    options: null,
    component: 'invoice-row-number',
  });
  await fetchInvoiceRow(invoiceRow.id);
};

const editTableRow = async (row: TableRow) => {
  const { data } = await getInvoiceRow(Number(row.id));

  rowToEdit.value = data;

  showRowModal.value = true;
};

const groupedTotal = computed(() => {
  let total = 0;

  newRows.value?.forEach((group) => {
    group.rows.forEach((row) => {
      total += Number(row.columns.find((c) => c.id === 'total')?.value ?? 0);
    });
  });

  return total;
});

const groupedVat = computed(() => {
  let total = 0;

  newRows.value?.forEach((group) => {
    group.rows.forEach((row) => {
      total +=
        Number(row.columns.find((c) => c.id === 'total')?.value) *
        Number(row.columns.find((c) => c.id === 'vat')?.value ?? 1);
    });
  });

  return total;
});

const updateOrder = async (data: SortEmit) => {
  await axios.patch(`/api/invoice-rows/${data.selectedItem}/order`, {
    order: data.newOrder,
  });
};

const priceCell = 'cell_price';
const totalCell = 'cell_total';
const totalVatCell = 'cell_total_with_vat';

const openSelectId = ref<string | null>(null);

const { eventTypes, fetch: fetchEventTypes } = inject(eventTypesKey, {
  eventTypes: computed(() => []),
  fetch: (force?: boolean = false) => {},
});

fetchEventTypes();

const connectetedEventTypes = computed(() => {
  if (!props.invoiceBase) return [];

  return eventTypes.value.filter((ev) => ev.pivot.invoice_bases.some((cl) => cl.id === props.invoiceBase.id));
});

const concatAllEvenTypeNames = computed(() => {
  if (!connectetedEventTypes.value) return '';

  return connectetedEventTypes.value.map((ev) => ev.name).join(', ');
});
</script>

<template>
  <ContentContainer
    :can-edit="canEdit"
    :edit-mode="canEdit"
    :loading="loading"
    :start-open="isSingleInvoice"
    :title="invoiceBase.title"
    :just-content-without-header="isDisplay"
    :actions="actions"
    pre-icon="fa-money"
    :extra-height="isTemplate || isDisplay ? 0 : 70"
    @edit="$emit('edit')"
    @open="openInvoiceBase">
    <template #underHeader>
      <div class="flex gap-3 items-center">
        <div
          class="flex items-center gap-2 text-soft font-headers"
          :class="!open ? 'pl-9' : ''">
          Total:
          <span class="text text-nowrap font-headers">
            {{ numberWithCommas(groupedTotal + Number(invoiceBase.with_vat ? groupedVat : 0)) }}
          </span>
        </div>
        <div
          v-if="invoiceBase.with_vat"
          class="flex items-center gap-2 text-soft font-headers">
          VAT:
          <span class="text font-headers">
            {{ numberWithCommas(Number(invoiceBase.with_vat ? groupedVat : 0)) }}
          </span>
        </div>
      </div>

      <div
        v-if="!open && !isTemplate && invoiceBase?.last_updated_row"
        class="!sub-title text-soft !pl-10 mb-4 font-headers">
        Last edited by <span class="text-highlight">{{ invoiceBase.last_updated_row.updated_by }}</span> at
        <span class="text-highlight">{{ invoiceBase.last_updated_row.updated_at }}</span>
      </div>
    </template>
    <template #content>
      <div class="flex flex-col gap-5 pb-10 [&_*]:font-normal">
        <div
          v-for="group in newRows"
          :key="group.groupId"
          class="">
          <div class="pl-6 pt-5">
            <h3 class="text font-headers !font-mediumbold">{{ group.groupTitle }}</h3>
          </div>
          <TestTable
            :headers="invoiceHeaders"
            :draggable="true"
            :with-edit="true"
            :can-edit="editMode && canEdit"
            :rows="group.rows"
            @sorted="updateOrder"
            @edit="editTableRow"
            @blur="saveData($event, group.rows)">
            <template #[totalCell]="{ data }">
              <div class="text-base">{{ numberWithCommas(data.value) }}</div>
            </template>
            <template #[totalVatCell]="{ data }">
              <div>{{ numberWithCommas(data.value) }}</div>
            </template>
            <template #[priceCell]="{ data, save, row }">
              <div class="group relative w-[110px]">
                <div
                  v-if="editMode && canEdit"
                  :class="{ 'hidden': openSelectId !== `${data.id}_${row.id}` }"
                  class="group-hover:block">
                  <VSelect
                    :model-value="Number(data.value)"
                    :is-hidden="true"
                    :can-edit="editMode && canEdit"
                    :nullable="!isTemplate"
                    nullable-display-text="Convert to number"
                    :options="
                      data.componentProps.options.map((o) => ({
                        name: o.title + ' (' + numberWithCommas(o.value) + ')',
                        id: Number(o.value),
                      }))
                    "
                    @dropdown-opened="openSelectId = `${data.id}_${row.id}`"
                    @dropdown-closed="openSelectId = null"
                    @update:model-value="
                      $event === null
                        ? concertRowToNumber(row)
                        : [(data.value = $event), save(data.value, data.id, row.id)]
                    " />
                </div>

                <div
                  :class="[
                    { 'group-hover:hidden': editMode && canEdit },
                    { 'hidden': openSelectId === `${data.id}_${row.id}` },
                  ]">
                  <NumberInput
                    :model-value="data.value"
                    :can-edit="editMode && canEdit"
                    :is-hidden="true" />
                </div>
              </div>
            </template>
            <template #after_rows>
              <tr v-if="invoiceBase.with_vat">
                <td :colspan="invoiceHeaders.length - 1" />
                <td class="font-bold text-right text-highlight py-table-cell-y px-table-cell-x">Vat</td>
                <td class="text-right font-bold py-table-cell-y px-table-cell-x text-base">
                  {{ numberWithCommas(getTotalVat(group.rows)) }}
                </td>
              </tr>
              <tr>
                <td :colspan="invoiceHeaders.length - 2" />
                <td
                  class="font-bold text-right text-highlight py-table-cell-y px-table-cell-x text-base"
                  :colspan="2">
                  Total
                  <template v-if="invoiceBase.with_vat"> Inc VAT</template>
                </td>
                <td class="text-right font-bold py-table-cell-y px-table-cell-x text-base text-nowrap">
                  {{ numberWithCommas(Number(getTotal(group.rows))) }}
                </td>
              </tr>
            </template>
          </TestTable>
        </div>
        <EmptyStateFullPage
          v-if="rows.size === 0"
          icon="fa-money"
          description="No Products Added to Invoice Yet"
          button-text="Add Products"
          :button-function="
            editMode && canEdit
              ? () => {
                  openProductModal();
                }
              : null
          " />
      </div>

      <InvoiceRowCRUDModal
        v-if="showRowModal"
        :inital-row="rowToEdit"
        :categories="categories"
        :products="products"
        :with-vat="invoiceBase.with_vat"
        model="InvoiceBasis"
        :model-id="invoiceBase.id"
        @deleted="removeRow"
        @updated="updateRow"
        @created="addRow"
        @closed="showRowModal = false" />

      <InvoiceBasisDownloadModal
        v-if="downloadModalOpen"
        v-model:downloading="downloadingReport"
        :invoice-basis-id="invoiceBase.id"
        :show-times="showTimes"
        :model="model"
        :model-id="modelId" />
    </template>
  </ContentContainer>
</template>
