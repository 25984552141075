<script setup lang="ts">
import { computed, reactive, ref, watchEffect } from 'vue';
import HoverBox from '@/components/HoverBox.vue';
import { usePage } from '@inertiajs/vue3';

import { useContextSidebarStore } from '@/store/ContextSidebarStore';
import { getDiffInInterval } from '@/util/timeFunctions';
import VButton from '@/components/Inputs/VButton.vue';
import { getGroupUsage, GroupUsage } from '@/services/api-group';
import { useEmitStore } from '@/store/EmitStore';
import { getKey } from '@/util/globals';
import BaseModal from '@/components/Base/BaseModal.vue';
import { getLockPageItem } from './unlock-features-helper';
import { sendAnErrorToSlack } from '@/util/error-handler';

const isModalOpen = ref(false);

const page = usePage();
const userGroups = page.props.auth.user.groups;
const contextStore = useContextSidebarStore();

const getViewedGroup = () => {
  if (contextStore.currentContextType === 'personal' && contextStore.currentView?.includes('group')) {
    const groupId = contextStore.currentView.split('_')[1];

    return userGroups.find((userGroup) => userGroup.id == groupId);
  }

  if (contextStore.currentContextType === 'group') {
    return userGroups.find((userGroup) => userGroup.id === contextStore.currentContextId);
  }

  return null;
};

const shouldShow = computed(() => {
  let group = getViewedGroup();

  // Not member of the group, or we are not in a group.
  if (!group) {
    return false;
  }

  // Self-onboarded free group
  if (group.is_free) {
    return 'free';
  }

  // Group is currently on trial
  if (group.trial_ends_at != null) {
    return 'trial';
  }

  // Group probably has a subscription
  return false;
});

const trialDaysRemaining = computed(() => {
  const g = getViewedGroup();

  const group = userGroups.find((userGroup) => {
    return userGroup.id === g.id;
  });

  if (!group) {
    return 0;
  }

  return getDiffInInterval(new Date(), group.trial_ends_at, 'days');
});

const showDropdown = ref(false);
const button = ref(null);
const buttonContainer = ref(null);

const dropdownStyle = reactive({
  y: 0,
  x: 0,
});

const updateDropdownPosition = () => {
  const rect = button?.value?.getBoundingClientRect();
  if (!rect) {
    return;
  }

  dropdownStyle.y = rect.bottom;
  dropdownStyle.x = rect.right - 250;
};

watchEffect(() => {
  if (showDropdown.value) {
    updateDropdownPosition();
  }
});

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
};

window.addEventListener('resize', updateDropdownPosition);

const momentOfLoading = ref(false);
[50, 100, 150, 250, 300, 350, 400, 460, 500, 600, 760].map((e) => {
  setTimeout(() => {
    if (!momentOfLoading.value) {
      const item = document.getElementById('before-navbar');
      if (item) {
        momentOfLoading.value = true;
      }
    }
  }, e);
});

const usage = ref<GroupUsage | null>(null);

const refreshUsageCount = () => {
  const group = getViewedGroup();

  if (!group) {
    usage.value = null;
    return;
  }

  getGroupUsage(group?.id).then((res) => {
    usage.value = res.data;
  });
};

const talkToSales = () => {
  isModalOpen.value = false;

  sendAnErrorToSlack(
    'User Wants to talk to sales about upgrading group with ' + lockPageItem.key,
    ' <@U5KFQVBU5> <@U05USSDMAJG>',
    'https://hooks.slack.com/services/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'
  );
  window.open('https://meet.crescat.io/talk-to-sales-enterprise');
};

watchEffect(() => {
  if (shouldShow.value && getViewedGroup != null) {
    refreshUsageCount();
  }
});

useEmitStore().$subscribe((mutation, state) => {
  if (state.item?.key === 'admin-count-changed' || state.item?.key === 'event-count-changed') {
    refreshUsageCount();
  }
  if (state.item?.key === 'change-event-count') {
    const allowed = getKey(state.item.payload, 'allowed');
    const created = getKey(state.item.payload, 'created');
    if (!allowed || !created) {
      refreshUsageCount();
    } else {
      usage.value.events_allowed_count = allowed;
      usage.value.events_used_count = created;
    }
  }
});

refreshUsageCount();

const lockPageItem = getLockPageItem({
  title: 'Group',
  key: 'group',
});
</script>

<template>
  <BaseModal
    v-if="isModalOpen"
    width="495px"
    only-close-button
    @closed="isModalOpen = false">
    <template #header="{ close }">
      <div class="bg-highlight h-[212px] flex items-center justify-center rounded-t overflow-hidden">
        <img
          :src="usePage().props.asset_url + 'assets/icons/Organic.png'"
          class="select-none pointer-events-none"
          alt="" />

        <div class="absolute top-4 right-4">
          <button
            class="float-right h-6 w-6 rounded-full bg-content hover:bg-content/90 text-soft hover:text duration-200 ease-in-out"
            @click="close()">
            <i class="fa fa-fw fa-times" />
          </button>
        </div>
      </div>
    </template>

    <div>
      <div class="pt-8 px-6 text-center">
        <h2 class="font-semibold text-4xl leading-tight text text-pretty">Upgrade your execution on Crescat</h2>
        <p class="text-soft text-lg mt-4 text-pretty">
          Upgrade to a Paid Plan today and Boost Your Event Success with a wider range of features
        </p>

        <div class="flex mx-auto items-center justify-center flex-row gap-x-6 mt-8">
          <VButton
            type="primary"
            title="Talk to Sales"
            @click="talkToSales" />

          <VButton
            type="success"
            title="Upgrade"
            @click="[lockPageItem.action(), (isModalOpen = false)]" />
        </div>
      </div>
    </div>
  </BaseModal>

  <teleport
    v-if="momentOfLoading"
    to="#before-navbar">
    <transition
      name="fade"
      :duration="150">
      <div
        v-if="shouldShow"
        ref="buttonContainer">
        <button
          ref="button"
          class="bg-pending text hover:brightness-110 duration-200 ease-in-out w-auto px-3 py-2 shrink-0 text-base rounded whitespace-nowrap inline-flex justify-center items-center gap-x-3 border-none"
          @click="toggleDropdown">
          <i class="fas fa-star-shooting fa-sm"></i>
          <div
            v-if="shouldShow == 'trial'"
            class="text-sm font-mediumbold">
            <span v-if="trialDaysRemaining > 0">{{ trialDaysRemaining }} Days Left of Your Free Trial</span>
            <span v-else>Your trial has expired</span>
          </div>
          <div
            v-if="shouldShow == 'free'"
            class="text-sm font-mediumbold">
            <span>Free Group</span>
          </div>

          <i class="fas fa-chevron-down fa-sm"></i>
        </button>

        <HoverBox
          v-if="showDropdown"
          :ignore-elements="[buttonContainer]"
          :x-pos="dropdownStyle.x"
          :y-pos="dropdownStyle.y"
          @closed="showDropdown = false">
          <div style="width: 250px">
            <div class="box-container-container mt-4">
              <div class="bg-box border p-3 rounded box-container hover:border-strong">
                <div class="relative flex justify-between items-center p-3 pb-0">
                  <div class="grid grid-cols-2 gap-x-8 gap-y-5">
                    <div class="flex flex-col gap-2">
                      <span class="text-soft font-mediumbold text-sm">Events</span>
                      <span class="gap-2 flex text font-mediumbold text-base">
                        <span
                          v-if="usage?.unlimited_events"
                          class="whitespace-nowrap gap-2 flex items-baseline">
                          <span class="inline-block">{{ usage?.events_used_count }}</span>
                          <span class="inline-block"> / </span>
                          <i class="fa fa-infinity fa-sm inline-block"></i>
                        </span>
                        <span
                          v-else
                          class="whitespace-nowrap">
                          {{ usage?.events_used_count }} /
                          {{ usage?.events_allowed_count }}
                        </span>
                        <span class="ml-1">Events</span>
                      </span>
                    </div>
                    <div class="flex flex-col gap-2">
                      <span class="text-soft font-mediumbold text-sm">Admins</span>
                      <span class="gap-2 flex text font-mediumbold text-base">
                        <span class="whitespace-nowrap gap-2 flex items-baseline">
                          <span class="inline-block">{{ usage?.admins_used_count }}</span>
                          <span class="inline-block"> / </span>
                          <span class="inline-block">{{ usage?.admins_allowed_count }}</span>
                        </span>
                        <span class="ml-1">Seats</span>
                      </span>
                    </div>

                    <div class="flex flex-col items-start justify-center">
                      <VButton
                        type="success"
                        size="small"
                        class="py-1 px-4"
                        title="Upgrade"
                        @click="isModalOpen = true" />
                    </div>
                    <div class="flex flex-col items-start justify-center">
                      <a
                        href="https://meet.crescat.io/talk-to-sales-enterprise"
                        target="_blank"
                        class="underline font-mediumbold text-sm text underline-offset-4"
                        @click="showDropdown = false">
                        Talk to sales
                      </a>
                    </div>
                  </div>
                </div>
                <div class="border-t mt-6 pt-3 -mx-3">
                  <div class="p-3">
                    <p class="text-sidebarTextSoft text-sm text-center text-balance">
                      Unlock exclusive features by upgrading to Crescat Premium
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HoverBox>
      </div>
    </transition>
  </teleport>
</template>
