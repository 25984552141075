<script lang="ts" setup>
import { TaskOwnerModels, TaskResource } from '@/types/tasks';
import IntermediateStep from '@/components/IntermediateStep/IntermediateStep.vue';
import { getRoute, openRoute } from '@/util/route';
import { computed, nextTick, ref } from 'vue';
import { formatAppModel } from '@/util/format-app-model';

type Props = {
  ownerModel: TaskOwnerModels;
  ownerId: number;
  task: TaskResource;
  pillClasses?: string | null;
  alwaysOpenEventOnClick?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  alwaysOpenEventOnClick: false,
  pillClasses: 'max-w-[200px]',
});
const emit = defineEmits<{
  (e: 'close'): void;
}>();
const alreadyOnPage = computed(() => {
  return getRoute('events.show', props.task.event.slug) === window.location.origin + window.location.pathname;
});

const openEvent = async () => {
  if (alreadyOnPage.value) return;
  if (!props.alwaysOpenEventOnClick) {
    if (props.task.invite_id) {
      await openInviteById(props.task.invite_id);
      return;
    }
    if (['Group', 'Festival'].includes(props.ownerModel) && props.task.event_id) {
      const { data } = await axios.get<{ id: number }>(`/api/invites/`, {
        params: {
          event_id: props.task.event_id,
          model_type: formatAppModel(props.ownerModel),
          model_id: props.ownerId,
        },
      });
      if (data && data.id) {
        await openInviteById(data.id);
        emit('close');
        return;
      }
    }
  }
  if (props.task.event) {
    openRoute(getRoute('events.show', props.task.event.slug));
  }
};

const selectedInviteId = ref(null);
const openInviteById = async (inviteId: number) => {
  selectedInviteId.value = null;
  await nextTick();
  selectedInviteId.value = inviteId;
};
</script>

<template>
  <div class="flex px-2">
    <div
      v-if="task.event_id && task.event"
      :class="{ 'hover:bg cursor-pointer hover:border-soft ': !alreadyOnPage }"
      class="border flex gap-2 items-center rounded px-3 py-1"
      @click="openEvent()">
      <span
        :class="pillClasses"
        class="truncate">
        {{ task.event.name }}
      </span>
      <i
        v-if="!alreadyOnPage"
        class="fa fa-fw fa-regular fa-external-link text-xs"></i>
    </div>
  </div>
  <IntermediateStep
    v-if="selectedInviteId"
    :inital-invite-id="selectedInviteId"
    initial-tab="tasks"
    @closed="selectedInviteId = null" />
</template>
