<script lang="ts" setup>
import CrudModal from '@/components/Modals/CrudModal.vue';
import { useMultipleButtonsModal } from '@/composables/modals/use-multiple-buttons-modal';
import VButton from '@/components/Inputs/VButton.vue';
import { ref } from 'vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { useToast } from 'vue-toastification';

type Props = {
  eventName: string;
  eventId: number;
  isRecurring: boolean;
  isSimple: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (event: 'closed', ...args: any[]): void;
  (event: 'eventDeleted', ...args: any[]): void;
  (event: 'deleting', args: number): void;
}>();
const modalOpen = ref(true);
const deleteEvent = async () => {
  modalOpen.value = false;
  const res = props.isRecurring
    ? await useMultipleButtonsModal().threeButtonModal({
        title: 'Delete All Events',
        description: 'Do you want to delete all recurrences of this event, or just this one?',
        button: {
          text: 'Delete',
          type: 'warning',
        },
        options: [
          {
            value: 'first',
            label: 'This event only',
          },
          {
            value: 'second',
            label: 'All recurrences of this event',
          },
        ],
      })
    : false;

  if (res === 'cancel') {
    modalOpen.value = false;
    return;
  }

  try {
    if (!props.isRecurring) {
      const yes = await useCertaintyModal().assertCertain(
        'Delete Event',
        `Please confirm the deletion of ${props.eventName}.`
      );
      if (!yes) return;
    }
    emit('deleting', props.eventId);
    await window.axios.delete(`/api/events/${props.eventId}`, {
      params: {
        is_global: res === 'second',
      },
    });
    useToast().success(`${props.eventName} Deleted`);
    emit('eventDeleted', props.eventId);
  } catch (e) {
    useToast().warning('Error deleting event');
  }
};
</script>

<template>
  <CrudModal
    v-if="modalOpen"
    :title="`Are you sure you want to delete ${eventName}?`"
    :title-highlight="eventName"
    only-close-button
    small
    @closed="$emit('closed')">
    <div class="form-layout">
      <p class="sub-title text-center text-soft">
        If you delete this event, it can not be restored. All files, assignments, invitations, running orders and others
        will be deleted. This is the final warning.
      </p>
      <VButton
        size="small"
        type="warning"
        icon="fa-trash"
        :title="'Delete ' + eventName"
        @click="deleteEvent()" />
    </div>
  </CrudModal>
</template>
