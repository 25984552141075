<script lang="ts" setup>
import { useSmallScreen } from '@/composables/use-small-screen';
import { ref } from 'vue';

type Props = {
  label?: string | null;
  icon?: string | null;
  title?: string | null;
  mandatoryText?: string | null;
  isHidden?: boolean;
  superText?: boolean;
};

withDefaults(defineProps<Props>(), {
  label: null,
  icon: '',
  title: '',
  mandatoryText: null,
  isHidden: false,
  superText: false,
});
const smallScreen = useSmallScreen().isSmallScreen;

const maxWidth = ref(300);
const element = ref<HTMLDivElement | null>(null);
const getWidthOfElement = () => {
  if (!element.value) return;
  try {
    maxWidth.value = element.value.parentNode.parentNode.offsetWidth;

    if (maxWidth.value === 0) {
      setTimeout(() => {
        getWidthOfElement();
      }, 1000);
    }
  } catch (__) {}
};
[0, 10, 50, 100, 200, 300, 500, 1000, 1500, 2000, 3000, 5000].forEach((delay) => {
  setTimeout(() => {
    getWidthOfElement();
  }, delay);
});
</script>

<template>
  <label
    v-if="label || $slots.default"
    ref="element"
    :title="title ? title : label"
    :class="
      isHidden
        ? 'indent-[12px] '
        : '' + (superText ? ' super-text-input capitalized font-headers text-sm font-mediumbold text-soft ' : '')
    "
    :style="'max-width: ' + maxWidth + 'px'"
    class="indent mb-2 flex items-center gap-1 font-[--label-font-weight-default] capitalize font-headers">
    <slot>
      <i
        v-if="icon"
        class="fa fa-fw"
        :class="icon" />
      <div
        class="text-sm"
        :title="title ? title : label"
        :class="smallScreen ? '' : 'truncate'">
        {{ label }}
      </div>

      <i
        v-if="title"
        class="fa fa-fw fa-info-circle text-sm" />
      <span
        v-if="mandatoryText"
        :title="mandatoryText">
        <i class="fa fa-fw fa-asterisk fa-2xs text-[8px] text-warning" />
      </span>
    </slot>
  </label>
</template>
