<script lang="ts" setup>
import { nextTick, ref } from 'vue';
import SearchSelect from '@/components/Inputs/SearchSelect.vue';

type Props = {
  pageX: number;
  pageY: number;
  url: string;
  placeholder?: string;
  searchParam?: string;
  canCreate?: boolean | ((option: any) => boolean);
  params?: object | null;
  alreadySelectedIds?: number[] | string[];
  searchOnOpen?: boolean;
  infiniteScroll?: boolean;
  minSearchLength?: number;
  createString?: string;
  debounceTime?: number;
};

const props = withDefaults(defineProps<Props>(), {
  placeholder: 'Search',
  searchParam: 'search',
  canCreate: false,
  params: null,
  infiniteScroll: false,
  searchOnOpen: false,
  minSearchLength: 1,
  createString: 'Create',
  alreadySelectedIds: () => [],
  debounceTime: 0,
});

const emit = defineEmits<{
  (event: 'selected', option: any): void;
  (event: 'create', option: any): void;
}>();

const open = ref(false);

const openDropdown = () => {
  if (!props.pageX || !props.pageY) return;
  open.value = false;
  nextTick(() => {
    open.value = true;
  });
};
openDropdown();
</script>
<template>
  <div
    v-if="open"
    class="fixed top-0 left-0 z-[2002] !h-full w-full !bg-transparent"
    @click="open = false" />
  <div
    v-if="open"
    :style="`left: ${pageX}px; top: ${pageY}px;`"
    class="fixed z-[2003] rounded border bg p-4 !h-[70px]">
    <SearchSelect
      :url="url"
      is-object
      autofocus
      :create-string="createString"
      :can-create="canCreate"
      :placeholder="placeholder"
      :debounce-time="debounceTime"
      :override-width="300"
      :infinite-scroll="infiniteScroll"
      :params="params"
      :left-icon="null"
      :search-on-open="searchOnOpen"
      :min-search-length="minSearchLength"
      :already-selected-items="alreadySelectedIds"
      :search-param="searchParam"
      @update:model-value="[$event.id === null ? null : $emit('selected', $event), (open = false)]"
      @create="[$emit('create', $event), (open = false)]">
      <template #default="{ option }">
        <slot :option="option"></slot>
      </template>
    </SearchSelect>
  </div>
</template>
