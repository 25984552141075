<script setup lang="ts">
import VTableCell from '@/components/Tables/VTableCell.vue';
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import { documentKey, showModeKey } from '@/provide/keys';
import { defaultColumnWidths } from '@/variables/mysc';
import DocumentFolderDownloader from '@/components/Documents/DocumentFolderDownloader.vue';
import { computed, inject, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';

type Props = {
  column: any;
  canEdit?: boolean;
  showAudits?: boolean;
  showDropdown?: boolean;
  isHidden?: boolean | null;
  sortUp?: boolean | null;
  sortDown?: boolean | null;
  canMoveLeft?: boolean | null;
  canMoveRight?: boolean | null;
  rows?: any;
  classes?: string | null;
  id?: string | null;
  board?: any;
  runningOrder?: any;
  addStyle?: string;
};

const props = withDefaults(defineProps<Props>(), {
  showDropdown: true,
  showAudits: false,
  canEdit: false,
  isHidden: null,
  sortUp: null,
  sortDown: null,
  classes: null,
  canMoveRight: false,
  canMoveLeft: null,
  id: null,
  rows: () => [],
  board: null,
  runningOrder: null,
  addStyle: undefined,
});

const emit = defineEmits<{
  (event: 'edit'): void;
  (event: 'moveLeft'): void;
  (event: 'moveRight'): void;
  (event: 'sort', arg: string | null): void;
  (event: 'toggleHide'): void;
  (event: 'openAudits'): void;
}>();

const inShowMode = inject(showModeKey, false);
const documents = inject(documentKey, null);

const loading = ref(false);
const workingSort = [
  'board-column-text',
  'board-column-toggle',
  'board-column-date',
  'board-column-list',
  'board-column-time',
  'board-column-user',
  'board-column-document',
] as const;

const tinyWidth = ['width-small'] as const;

const downloadableColumns = ref(['board-column-document', 'runningorder-column-document', 'runningorder-column-image']);
const filedToDownload = ref(null);

const allDocuments = ref(documents?.allDocuments ?? []);

const downloadFiles = async () => {
  if (!downloadableColumns.value.includes(props.column.component)) return;
  if (!allDocuments.value) return;
  if (loading.value) return;
  loading.value = true;
  if (!documents) return;
  await documents.fetch(true);

  if (props.board) {
    const docs = [];
    props.board.board_cells
      .filter((cell) => cell.board_column_id === props.column.id)
      .forEach((cell, index) => {
        const doc = allDocuments.value.find((doc) => doc.id === Number(cell.value));
        if (doc) {
          docs.push({ ...doc, filename: 'Row ' + (index + 1) + ': ' + doc.filename });
        }
      });
    if (docs.length === 0) {
      useToast().warning('No documents to download');
      loading.value = false;
      return;
    }
    filedToDownload.value = {
      children: [],
      documents: docs,
    };
  } else {
    const docs = [];
    props.runningOrder.running_order_cells
      .filter((cell) => cell.running_order_column_id === props.column.id)
      .forEach((cell, index) => {
        const doc = allDocuments.value.find((doc) => doc.id === Number(cell.value));
        if (doc) {
          docs.push({ ...doc, filename: 'Row ' + (index + 1) + ': ' + doc.filename });
        }
      });

    if (docs.length === 0) {
      useToast().warning('No documents to download');
      loading.value = false;
      return;
    }

    filedToDownload.value = {
      children: [],
      documents: docs,
    };
  }
};

watch(filedToDownload, (val) => {
  if (!filedToDownload.value) {
    loading.value = false;
  }
});

const getWidthOfColumn = () => {
  const width = defaultColumnWidths.find((i) => props.column.width === i.class);
  if (!width) return 100;
  return width.size * 100;
};
const dropdownItems = computed(() => {
  return [
    props.canEdit
      ? {
          title: 'Edit',
          preIcon: 'fa-pencil',
          action: (close: () => void) => {
            emit('edit');
            close();
          },
        }
      : null,
    downloadableColumns.value.includes(props.column.component) && !inShowMode
      ? {
          title: 'Download Files',
          preIcon: 'fa-download',
          action: (close: () => void) => {
            downloadFiles();
            close();
          },
        }
      : null,

    props.canEdit && props.isHidden !== null
      ? {
          title: props.isHidden ? 'Show Column' : 'Hide Column',
          preIcon: props.isHidden ? 'fa-eye' : 'fa-eye-slash',
          action: (close: () => void) => {
            emit('toggleHide');
            close();
          },
        }
      : null,

    props.canEdit && props.canMoveLeft
      ? {
          title: 'Move Left',
          preIcon: 'fa-arrow-left',
          action: (close: () => void) => {
            emit('moveLeft');
            close();
          },
        }
      : null,

    props.canEdit && props.canMoveRight
      ? {
          title: 'Move Right',
          preIcon: 'fa-arrow-right',
          action: (close: () => void) => {
            emit('moveRight');
            close();
          },
        }
      : null,
    props.showAudits
      ? {
          name: 'Show Audits',
          icon: 'fa-history',
          action: () => {
            emit('openAudits');
          },
        }
      : null,
  ].filter((i) => i !== null);
});
</script>
<template>
  <VTableCell
    :id="id"
    :style="'width: ' + getWidthOfColumn() + 'px!important; ' + addStyle"
    class="!py-0"
    component-type="th"
    :classes="classes">
    <div class="flex items-center gap-1">
      <i
        v-if="sortDown !== null && sortUp !== null && workingSort.includes(column.component)"
        class="fa fa-fw cursor-pointer"
        :class="
          (sortDown ? 'fa-sort-down' : '') + (sortUp ? 'fa-sort-up' : '') + (!sortDown && !sortUp ? 'fa-sort' : '')
        "
        @click="sortUp ? $emit('sort', 'desc') : $emit('sort', 'asc')" />
      {{ !tinyWidth.includes(column.width) && !showDropdown ? column.title : '' }}
      <VDropdown
        v-if="showDropdown"
        wrapper-classes="flex-1"
        close-on-click
        :can-open-dropdown="dropdownItems.length > 0"
        :items="dropdownItems">
        <template #click-area>
          <div
            :title="column.title + '. Click to open up a menu for this column'"
            class="grid grid-cols-[1fr_37px] items-center">
            <div class="truncate text text-sm">
              {{ column.title }}
            </div>
            <div
              v-if="dropdownItems.length > 0"
              class="btn btn-in-table flex h-full w-[35px] min-w-[35px] items-center justify-center -mt-3">
              <i
                v-if="!loading"
                class="fa fa-fw fa-chevron-down float-right" />
              <i
                v-if="loading"
                class="fa fa-fw fa-circle-o-notch fa-spin" />
            </div>
          </div>
        </template>
      </VDropdown>
      <DocumentFolderDownloader
        v-if="filedToDownload"
        :document-folder="filedToDownload"
        :filename-save="'Files from ' + column.title"
        @remove-link="filedToDownload = null" />
    </div>
  </VTableCell>
</template>
