<script setup lang="ts">
import { InviteFestival, InviteResource } from '@/types/invite';
import { nextTick, ref } from 'vue';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import SearchSelectFloatingWindow from '@/components/Inputs/Components/SearchSelectFloatingWindow.vue';
import { router } from '@inertiajs/vue3';
import { getIndexFromArrayBasedOnId, getItemFromArrayBasedOnId } from '@/util/globals';
import { useToast } from 'vue-toastification';
import { getRoute } from '@/util/route';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';

type Props = {
  invite: InviteResource;
  performanceId: number | null;
  festival: InviteFestival | null;
};

const props = defineProps<Props>();

const performances = ref(props.invite.performances);

const openPerformance = (performance, event) => {
  if (props.performanceId && performance.id === props.performanceId) {
    return;
  }
  if (event && event.ctrlKey) {
    window.open(performance.slug);
  } else {
    router.visit(performance.slug);
  }
};

const pageX = ref(null);
const pageY = ref(null);
const performanceSearchOpen = ref(false);
const openPerformanceDropdown = (event) => {
  if (!props.invite || !props.invite.access_levels || !props.invite.access_levels.has_performances) return;
  pageX.value = null;
  pageY.value = null;
  // open.value = false;
  nextTick(() => {
    performanceSearchOpen.value = true;
    pageX.value = event.target.getBoundingClientRect().x - 235;
    pageY.value = event.target.getBoundingClientRect().y + 35;
  });
};

const addPerformance = async (performance) => {
  if (getItemFromArrayBasedOnId(performance.id, performances.value) !== null) {
    useToast().warning(performance.name + ' already added.');
    return;
  }
  await axios.post(`/api/performances/${performance.id}/invites/attach`, {
    invite_id: props.invite.id,
  });
  performances.value.push({
    ...performance,
    slug: getRoute('groups.performances.show', [props.invite.invitable.slug, performance.slug]),
  });
  // const index = getIndexFromArrayBasedOnId(performance.id, event.value.performances);
  // if (index === -1) {
  //   event.value.performances.push(performance);
  //   return;
  // }
  useToast().success(performance.name + ' added.');
};

const removePerformance = async (performance) => {
  const certain = await useDeleteObjectModal().assertReadyToDeleteModal(
    'Remove ' + performance.name,
    'Are you sure you want to remove ' + performance.name + ' from ' + props.invite.event.name + '?'
  );
  if (!certain) return;
  await axios.post(`/api/performances/${performance.id}/invites/detach`, {
    invite_id: props.invite.id,
  });
  const index = getIndexFromArrayBasedOnId(performance.id, performances.value);
  if (index > -1) {
    performances.value.splice(index, 1);
  }
  useToast().success(performance.name + ' removed.');
};
</script>

<template>
  <BoxContainer
    v-if="
      (invite.hasOwnProperty('performances') && invite.performances !== null && invite.performances.length > 0) ||
      invite.access_levels.has_performances
    "
    :actions="[
      invite.access_levels.has_performances
        ? {
            icon: 'fa-plus',
            title: 'Add',
            action: ($event) => {
              openPerformanceDropdown($event);
            },
          }
        : null,
    ]"
    header="Performances">
    <SearchSelectFloatingWindow
      v-if="pageX && pageY && performanceSearchOpen"
      url="/api/performances"
      :params="{
        model_type: invite.invitable_type,
        model_id: invite.invitable_id,
      }"
      search-on-open
      :page-y="pageY"
      :page-x="pageX"
      :can-create="false"
      @selected="addPerformance">
      <template #default="{ option }">
        <div class="flex justify-between items-center">
          <h4>{{ option.name }}</h4>
          <div>
            <span
              :class="{ 'text-soft': option.invites_count === 0 }"
              class="font-headers text-xs">
              {{
                option.invites_count === 0
                  ? 'No Events'
                  : option.invites_count + ' Event' + (option.invites_count === 1 ? '' : 's')
              }}
            </span>
          </div>
        </div>
      </template>
    </SearchSelectFloatingWindow>

    <div
      class="flex flex-col gap-3"
      :class="{ 'striped-children': performances.length }">
      <div
        v-for="performance in performances"
        :key="performance.id"
        class="cursor-pointer px-4 py-2 hover:!text"
        @click="openPerformance(performance, $event)">
        <div
          :title="'Open ' + performance.name"
          class="w-full">
          <div class="grid grid-cols-[auto_40px] items-center gap-4">
            {{ performance.name }}
            <div class="flex gap-2">
              <i
                v-if="!festival"
                class="fa fa-fw fa-trash text-sm"
                @click.stop="removePerformance(performance)" />
              <i
                v-if="performanceId ? performance.id !== performanceId : true"
                class="fa fa-fw fa-external-link float-right flex text-sm" />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!performances.length"
        class="text-soft">
        No Performances added
      </div>
    </div>
  </BoxContainer>
</template>
