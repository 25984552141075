<script lang="ts" setup>
import VButton from '@/components/Inputs/VButton.vue';

type Props = {
  icon?: string | null;
  description?: string | null;
  buttonIcon?: string;
  buttonText?: string | null;
  size?: string | null;
  buttonFunction?: (() => void) | null;
};

const props = withDefaults(defineProps<Props>(), {
  icon: null,
  buttonIcon: 'fa-plus',
  buttonText: null,
  size: null,
  buttonFunction: null,
  description: null,
});

const getClasses = () => {
  switch (props.size) {
    case 'tiny': {
      return 'p-2 gap-2 [&>i]:p-2 [&>.item_i]:text-4xl [&>.item]:h-[60px] [&>.item]:w-[60px] [&_.description-text]:sub-title';
    }
    case 'small': {
      return 'p-3 gap-3 [&>i]:p-4 [&>.item_i]:text-4xl [&>.item]:h-[75px] [&>.item]:w-[75px] ';
    }
    default: {
      return 'p-10 gap-5 [&>i]:p-9 [&>.item_i]:text-5xl [&>.item]:h-[100px] [&>.item]:w-[100px] ';
    }
  }
};
</script>

<template>
  <div
    :class="getClasses()"
    class="mx-auto flex flex-col justify-center">
    <div
      v-if="icon"
      class="item m-auto flex items-center rounded-full bg-[--color-background-empty-state-icon]">
      <i
        :class="icon"
        class="fa m-auto place-items-center text-[--color-empty-state-icon]" />
    </div>
    <p
      v-if="description"
      class="text-center text-soft description-text">
      {{ description }}
    </p>
    <VButton
      v-if="buttonFunction"
      :icon="`${buttonIcon} `"
      :title="buttonText"
      type="success"
      class="mx-auto"
      size="small"
      @click="buttonFunction && buttonFunction()" />
  </div>
</template>
