<script lang="ts" setup>
import { computed, getCurrentInstance, onMounted, provide, ref } from 'vue';

type Props = {
  head?: boolean;
  noBackground?: boolean;
  clickable?: boolean;
  sticky?: boolean;
  classes?: string;
  mainRow?: boolean;
  canDrag?: boolean;
  hideEditButton?: boolean;
  hideAllButtons?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  head: false,
  noBackground: false,
  clickable: false,
  sticky: false,
  classes: '',
  mainRow: false,
  canDrag: false,
  hideEditButton: false,
  hideAllButtons: false,
});

defineEmits<{
  (e: 'click', event: MouseEvent): void;
}>();

const instance = getCurrentInstance();
const addBackgroundClassesToRows = ref(true);
const getPropsFromVTable = () => {
  setTimeout(() => {
    try {
      if (instance.parent.props.hasOwnProperty('addBackgroundClassesToRows')) {
        addBackgroundClassesToRows.value = instance.parent.props.addBackgroundClassesToRows;
      }
    } catch (_) {}
  }, 50);
};

onMounted(() => {
  getPropsFromVTable();
});

const getClasses = () => {
  let string = '';

  if (props.sticky) {
    string += ' sticky';
  }
  if (props.classes) {
    string += ` ${props.classes} `;
  }

  if (props.head) {
    return `${string} uppercase [&>*]:capitalize [&>*>*>*]:text-sm  [&>*>*]:text-sm [&>*]:py-2  [&>*]:font-normal z-10`;
  }
  if (props.noBackground && addBackgroundClassesToRows.value) {
    if (!addBackgroundClassesToRows.value) {
      return string;
    }
    return `${string} !bg-transparent [&>td]:!bg-transparent`;
  }
  string += ' text-left ';
  if (addBackgroundClassesToRows.value) {
    string += ' hover:!bg-row-hover [&:hover_td]:!bg-row-hover [&>*]:hover:!text ';
  }
  if (props.clickable) {
    string += ' cursor-pointer';
  }
  if (props.hideEditButton) {
    string +=
      ' group/item [&_td:last-child_button_i]:invisible [&>td:last-child_button_i]:hover:visible [&_td:last-child_button_i]:group-hover/item:!visible ';
  }
  if (props.hideAllButtons) {
    string +=
      ' group/item [&_td_button_i]:invisible [&>td_button_i]:hover:visible [&_td_button_i]:group-hover/item:!visible ';
  }

  return string;
};

provide(
  'mainRow',
  computed(() => props.mainRow)
);
</script>

<template>
  <tr
    :class="getClasses()"
    :draggable="canDrag"
    @click="$emit('click', $event)">
    <slot :main-row="mainRow" />
  </tr>
</template>
