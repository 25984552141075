<script setup lang="ts">
import { computed } from 'vue';

type Props = {
  disabled?: boolean;
  canEdit?: boolean;
  loading?: boolean;
  impossible?: boolean;
  size?: string;
  overrideIcon?: string;
};

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  canEdit: true,
  loading: false,
  impossible: false,
  size: '',
  overrideIcon: undefined,
});

const model = defineModel<boolean>();

const allowedToEdit = computed(() => props.canEdit && !props.disabled);

const status = computed(() => {
  if (!allowedToEdit.value) return 'disabled';
  if (props.loading) return 'loading';
  if (props.impossible) return 'impossible';
  if (model.value) return 'active';
  return 'default';
});

const onClick = () => {
  if (allowedToEdit.value) {
    model.value = !model.value;
  }
};
</script>

<template>
  <span
    class="fa-stack fa-fw fa-xs group"
    :class="[
      allowedToEdit ? 'cursor-pointer' : 'cursor-not-allowed',
      {
        'mr-0 scale-50': size === 'xs',
        'mr-0 scale-75': size === 'sm',
        'scale-110': size === 'md',
        'scale-125': size === 'lg',
      },
    ]"
    @click="onClick">
    <template v-if="loading">
      <i class="fa fa-circle-o-notch fa-spin fa-stack-1x" />
    </template>
    <template v-else>
      <div
        class="absolute left-[4px] mr-1 h-[18px] w-[18px] rounded border"
        :class="[
          {
            'hover:border-[--color-border-checkbox-active] bg-[--color-background-checkbox]': allowedToEdit,
          },
          {
            'border-[--color-border-checkbox-disabled] bg-[--color-background-checkbox-disabled]':
              status === 'disabled',
          },
          { 'border-[--color-border-checkbox-default]': status === 'default' },
          { 'border-[--color-border-checkbox-active]': status === 'active' },
        ]" />
      <i
        v-if="model && !impossible"
        class="fa-solid fa-stack-1x text-[--color-checkbox-icon-active]"
        :class="overrideIcon ?? 'fa-check'" />
      <i
        v-if="impossible"
        class="fa fa-square fa-stack-1x text-warning" />
    </template>
  </span>
</template>
