<script lang="ts" setup>
import DisplayText from '@/components/Display/DisplayText.vue';
import SectionAssignments from '@/components/Groups/Settings/EventRequest/Sections/SectionAssignments.vue';
import SectionContactDetails from '@/components/Groups/Settings/EventRequest/Sections/SectionContactDetails.vue';
import SectionDocuments from '@/components/Groups/Settings/EventRequest/Sections/SectionDocuments.vue';
import SectionGeneralInfo from '@/components/Groups/Settings/EventRequest/Sections/SectionGeneralInfo.vue';
import SectionKeyContacts from '@/components/Groups/Settings/EventRequest/Sections/SectionKeyContacts.vue';
import SectionMetaData from '@/components/Groups/Settings/EventRequest/Sections/SectionMetaData.vue';
import SectionMoreInformation from '@/components/Groups/Settings/EventRequest/Sections/SectionMoreInformation.vue';
import SectionMultipleDaysSelector from '@/components/Groups/Settings/EventRequest/Sections/SectionMultipleDaysSelector.vue';
import SectionRoomBooking from '@/components/Groups/Settings/EventRequest/Sections/SectionRoomBooking.vue';
import SectionShowTimes from '@/components/Groups/Settings/EventRequest/Sections/SectionShowTimes.vue';
import SectionTermsOfUse from '@/components/Groups/Settings/EventRequest/Sections/SectionTermsOfUse.vue';
import SectionTextField from '@/components/Groups/Settings/EventRequest/Sections/SectionTextField.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import type { EventRequestForm } from '@/types/event-request';
import { EventRequestFormSection, EventRequestResource } from '@/types/event-request';
import { getKey } from '@/util/globals';
import { copyObject } from '@/util/object-helpers';
import { changeAndFormatStamp, formatStampAsHumanReadableDate, getDiffInInterval } from '@/util/timeFunctions';
import Sortable from 'sortablejs';
import { computed, ref, watch } from 'vue';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';
import { useSmallScreen } from '@/composables/use-small-screen';

type Props = {
  sections: EventRequestFormSection[];
  request?: EventRequestForm | null;
  eventRequest?: EventRequestResource | null;
  isTemplate?: boolean;
  isNewRequest?: boolean;
  editMode?: boolean;
  responseMode?: boolean;
  isAnswered?: boolean;
  moveSections?: boolean;
  slug?: string | null;
  rooms: {
    id: number;
    name: string;
  }[];
};

const props = withDefaults(defineProps<Props>(), {
  request: null,
  eventRequest: null,
  isTemplate: false,
  isNewRequest: false,
  editMode: false,
  responseMode: false,
  isAnswered: false,
  moveSections: false,
  slug: null,
  rooms: () => [],
});

const emit = defineEmits<{
  (event: 'update:sections', args: EventRequestFormSection[]): void;
  (event: 'update:request', arg: EventRequestForm): void;
  (event: 'editSection'): void;
}>();

const roomBookings = computed(() =>
  props.sections?.filter((s) => s.type === 'roomBooking').flatMap((section) => section.content?.roomBookings)
);
const { isSmallScreen } = useSmallScreen();

const sectionWrapper = ref<HTMLDivElement | null>(null);

const arrayMove = (arr: any[], old_index: number, new_index: number) => {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

let sortableInstance: Sortable | null = null;

watch(
  () => props.moveSections,
  (canDrag) => {
    if (canDrag) {
      sortableInstance = new Sortable(sectionWrapper.value, {
        animation: 150,
        sort: true,
        ghostClass: 'invisible',
        onEnd: (e) => {
          const newArray = arrayMove(copyObject(props.sections), e.oldIndex, e.newIndex);
          emit('update:sections', newArray);
        },
      });
    } else {
      sortableInstance?.destroy();
    }
  }
);

const changeDate = (date) => {
  const eventStart = changeAndFormatStamp({
    stamp: date,
    startOf: 'day',
    addMinutes: getDiffInInterval(
      changeAndFormatStamp({
        stamp: props.request.start,
        startOf: 'day',
      }),
      props.request.start,
      'minutes'
    ),
  });
  const eventEnd = changeAndFormatStamp({
    stamp: date,
    startOf: 'day',
    addMinutes: getDiffInInterval(
      changeAndFormatStamp({
        stamp: props.request.start,
        startOf: 'day',
      }),
      props.request.end,
      'minutes'
    ),
  });
  const newRequest = {
    ...props.request,
    event_start: eventStart,
    event_end: eventEnd,
    start: eventStart,
    end: eventEnd,
  };
  emit('update:request', newRequest);
};

const moveSectionId = ref<string | null>(null);

const sectionActions = (section: EventRequestFormSection) => {
  if (props.moveSections) return [];

  if (section.type === 'metaData') {
    return [
      {
        icon: 'fa-pencil',
        title: 'Edit',
        action: () => {
          emit('editSection', section);
        },
      },
      {
        icon: moveSectionId.value !== section.id ? 'fa-up-down' : 'fa-circle-check',
        title: 'move',
        action: () => {
          if (moveSectionId.value === section.id) {
            moveSectionId.value = null;
          } else if (moveSectionId.value === null) {
            moveSectionId.value = section.id;
          }
        },
      },
    ];
  }

  return [
    {
      icon: 'fa-pencil',
      title: 'Edit',
      action: () => {
        emit('editSection', section);
      },
    },
  ];
};

const timesOfBookingChanged = (newTimes: { start: string; end: string }) => {
  emit('update:request', { ...props.request, start: newTimes.start, end: newTimes.end });
};
</script>

<template>
  <div :class="{ 'divide-y': editMode && isTemplate, 'flex flex-col gap-7': isSmallScreen }">
    <div
      v-if="eventRequest && (eventRequest.accepted_at !== null || eventRequest.declined_at !== null)"
      class="content-container flex flex-col gap-3 p-edge">
      <h3 class="mb-3 text-highlight">Response Information</h3>
      <div class="grid gap-5 sm:grid-cols-2">
        <div>
          <InputLabel :label="eventRequest.accepted_at !== null ? 'Accepted By' : 'Declined By'" />
          {{ eventRequest.responded_by }}
        </div>
        <div :title="eventRequest.accepted_at !== null ? eventRequest.accepted_at : eventRequest.declined_at">
          <InputLabel :label="eventRequest.accepted_at !== null ? 'Accepted At' : 'Declined At'" />
          {{ eventRequest.declined_at ? formatStampAsHumanReadableDate(eventRequest.declined_at) : null }}
          {{ eventRequest.accepted_at ? formatStampAsHumanReadableDate(eventRequest.accepted_at) : null }}
        </div>
        <div
          v-if="eventRequest.decline_reason"
          class="col-span-2">
          <InputLabel label="Decline Reason" />
          {{ eventRequest.decline_reason }}
        </div>
        <div
          v-if="eventRequest.other_remarks"
          class="col-span-2">
          <InputLabel label="Other Remarks" />
          {{ eventRequest.other_remarks }}
        </div>
      </div>
    </div>

    <SectionGeneralInfo
      v-if="!moveSections"
      :edit-mode="editMode"
      :is-template="isTemplate"
      :model-value="request"
      :new-request="isNewRequest"
      :sections="sections"
      class="flex flex-col gap-3 p-edge"
      @update:model-value="$emit('update:request', $event)" />

    <SectionContactDetails
      v-if="!moveSections"
      :can-submit="false"
      :edit-mode="editMode"
      :is-template="isTemplate"
      :model-value="request"
      :new-request="isNewRequest"
      :sections="sections"
      class="flex flex-col gap-3 p-edge"
      @update:model-value="$emit('update:request', $event)" />

    <div
      ref="sectionWrapper"
      :class="{ 'divide-y': editMode && isTemplate, 'flex flex-col gap-7': isSmallScreen }">
      <div
        v-for="(section, index) in sections"
        :id="section.id"
        :key="section.id"
        class="flex flex-col gap-3 p-edge [&_a]:text-highlight [&_a]:hover:text-highlight-hover">
        <div class="flex justify-between">
          <div class="min-w-[200px]">
            <InputLabel
              v-if="isNewRequest"
              :label="'Section ' + (index + 3) + ' of ' + (sections.length + 2)"
              super-text />
            <h3
              class="text-highlight"
              :title="getKey(section, 'required', false) ? section.title + ' is required to fill out' : ''">
              <i
                v-if="moveSections"
                class="fa fa-fw fa-up-down cursor-grab" />
              {{ section.title }}
              <i
                v-if="getKey(section, 'required', false)"
                :title="section.title + ' is required to fill out'"
                class="fa fa-fw fa-asterisk fa-2xs text-[8px] text-warning" />
            </h3>
          </div>
          <ActionButtonGroup
            v-if="isTemplate && editMode"
            :actions="sectionActions(section)" />
        </div>
        <DisplayText :content="section.description" />
        <div v-show="!moveSections">
          <SectionAssignments
            v-if="section.type === 'assignments'"
            v-model:content="section.content"
            :edit-mode="editMode"
            :event-start="request?.start"
            :is-new-request="isNewRequest"
            :is-template="isTemplate"
            data-intro="event-request-assignments" />

          <SectionDocuments
            v-if="section.type === 'documents'"
            v-model:content="section.content"
            :edit-mode="editMode"
            :is-new-request="isNewRequest"
            :is-template="isTemplate" />

          <SectionKeyContacts
            v-if="section.type === 'keyContacts'"
            v-model:content="section.content"
            :edit-mode="editMode"
            :is-new-request="isNewRequest"
            :is-template="isTemplate"
            data-intro="event-request-key-contacts" />

          <SectionMetaData
            v-if="section.type === 'metaData'"
            v-model:content="section.content"
            :can-drag="section.id === moveSectionId"
            :edit-mode="editMode"
            :is-new-request="isNewRequest"
            :is-template="isTemplate"
            :section="section" />

          <SectionShowTimes
            v-if="section.type === 'showTimes'"
            v-model:content="section.content"
            :edit-mode="editMode"
            :event-name="getKey(props.request, 'name')"
            :event-start="getKey(props.request, 'start')"
            :is-new-request="isNewRequest"
            :is-template="isTemplate" />

          <SectionRoomBooking
            v-if="section.type === 'roomBooking'"
            v-model:content="section.content"
            :edit-mode="editMode"
            :event-end="request?.end"
            :event-start="request?.start"
            :inital-room-bookings="roomBookings"
            :is-answered="isAnswered"
            :is-new-request="isNewRequest"
            :is-template="isTemplate"
            :override-edit-mode="false"
            :override-is-template="false"
            :response-mode="responseMode"
            :rooms="rooms"
            :slug="slug"
            data-intro="event-request-room-booking"
            @times-changed="timesOfBookingChanged" />

          <SectionTermsOfUse
            v-if="section.type === 'termsOfUse'"
            v-model:content="section.content"
            :edit-mode="editMode"
            :is-new-request="isNewRequest"
            :is-template="isTemplate" />

          <SectionTextField
            v-if="section.type === 'textField'"
            v-model:content="section.content"
            :edit-mode="editMode"
            :is-new-request="isNewRequest"
            :is-template="isTemplate" />

          <SectionMultipleDaysSelector
            v-if="section.type === 'recurringDates'"
            v-model:content="section.content"
            :edit-mode="editMode"
            :event-end="request?.end"
            :event-start="request?.start"
            :is-answered="isAnswered"
            :is-new-request="isNewRequest"
            :is-template="isTemplate"
            :response-mode="responseMode"
            :room-bookings="roomBookings"
            :rooms="rooms"
            :slug="slug"
            data-intro="event-request-recurring-dates"
            type="recurringDates" />

          <SectionMoreInformation
            v-if="section.type === 'moreInformation'"
            v-model:content="section.content"
            :edit-mode="editMode"
            :is-template="isTemplate"
            :override-edit-mode="false"
            :override-is-template="false" />

          <SectionMultipleDaysSelector
            v-if="section.type === 'alternativeDates'"
            v-model:content="section.content"
            :edit-mode="editMode"
            :event-end="request?.end"
            :event-start="request?.start"
            :is-answered="isAnswered"
            :is-new-request="isNewRequest"
            :is-template="isTemplate"
            :response-mode="responseMode"
            :room-bookings="roomBookings"
            :rooms="rooms"
            :slug="slug"
            data-intro="event-request-alternative-dates"
            type="alternativeDates"
            @change-date="changeDate" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
/* .chosenClass {
  @apply border border bg;
} */
</style>
