<script lang="ts" setup>
import VButton from '@/components/Inputs/VButton.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import moment from 'moment';
import { dateFormat } from '@/variables/date-format';
import { computed, nextTick, ref } from 'vue';
import { useEmitStore } from '@/store/EmitStore';
import FloatingWindowContainer from '@/components/Inputs/Components/FloatingWindowContainer.vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import { formatStampAsDate, formatStampAsHumanReadableDate, timeStampsAreSame } from '@/util/timeFunctions';
import { getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import { ShiftResource } from '@/types/event';
import { shiftEvent } from '@/types/shifts';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';
import RadioBox from '@/components/Icons/RadioBox.vue';

type Props = {
  shift: ShiftResource;
  initialShift: object;
  eventId: number | null;
  events?: object[];
  modelId: number;
  model: string;
};

const props = withDefaults(defineProps<Props>(), {
  events: () => [],
});

const emit = defineEmits<{
  (e: 'update:eventId', value: number[]): void;
  (e: 'addEvents', value: object[]): void;
}>();

const selectedEvent = computed(() => {
  return getItemFromArrayBasedOnId(props.eventId, uniqueEvents.value);
});

const searchOpen = ref<boolean>(false);
const selectedDate = ref(moment(props.shift.start).format(dateFormat));
const selectedDateEvents = ref<object[]>([]);
const allEvents = ref<object[]>(props.events);
const fetchEventsForGroup = async () => {
  selectedDateEvents.value = props.events.filter(
    (e) => moment(e.start).format(dateFormat) === moment(selectedDate.value).format(dateFormat)
  );
  allEvents.value = props.events;
};

const { rootEmit } = useEmitStore();
const pageX = ref(0);
const pageY = ref(0);

const openDropdown = (event) => {
  pageX.value = event.target.getBoundingClientRect().x - 595;
  pageY.value = event.target.getBoundingClientRect().y + 30;
  searchOpen.value = false;
  if (selectedEvent.value) {
    selectedDate.value = selectedEvent.value.start;
  } else {
    selectedDate.value = props.shift.start;
  }
  fetchEventsForGroup();
  rootEmit('close-all-drop-downs');
  nextTick(() => {
    searchOpen.value = true;
  });
};
const toggleEventById = (eventId) => {
  if (props.eventId === eventId) {
    emit('update:eventId', null);
  } else {
    emit('update:eventId', eventId);
  }
};

const uniqueEvents = computed((): shiftEvent[] => {
  const unique = [];
  allEvents.value.forEach((e) => {
    if (!unique.find((u) => u.id === e.id)) unique.push(e);
  });
  props.initialShift?.initial_events?.forEach((e) => {
    if (!unique.find((u) => u.id === e.id)) unique.push(e);
  });
  return unique;
});
</script>

<template>
  <div>
    <div class="flex justify-between">
      <h3>Connected Event</h3>
      <div>
        <ActionButtonGroup
          :actions="[
            {
              title: 'Connect Event',
              icon: 'fa-plus',
              action: ($event) => {
                openDropdown($event);
              },
            },
          ]" />
      </div>
    </div>

    <div class="grid grid-cols-2">
      <VTable
        edge-to-edge
        row-size="small">
        <VTableRow v-if="eventId && selectedEvent">
          <VTableCell main-cell>
            {{ getKey(selectedEvent, 'name') }}
            <span
              v-if="!timeStampsAreSame(selectedEvent.start, shift.start)"
              class="text-soft">
              - {{ formatStampAsDate(selectedEvent.start) }}</span
            >
          </VTableCell>
          <VTableCell style="width: 50px">
            <VButton
              size="inTable"
              icon="fa-trash"
              @click="
                () => {
                  emit('update:eventId', null);
                }
              " />
          </VTableCell>
        </VTableRow>
      </VTable>
    </div>

    <FloatingWindowContainer
      v-if="pageX && pageY && searchOpen"
      :page-x="pageX"
      :page-y="pageY">
      <div class="w-[700px] grid grid-cols-2 gap-5">
        <div>
          <h3>Search by date</h3>
          <VDatepicker
            v-model="selectedDate"
            inline
            :start-date="shift.start"
            @update:model-value="fetchEventsForGroup" />
        </div>
        <div>
          <h3>Events on {{ formatStampAsHumanReadableDate(selectedDate) }}</h3>
          <div class="overflow-auto max-h-[300px]">
            <VTable>
              <VTableRow
                v-for="event in selectedDateEvents"
                :main-row="event === eventId"
                clickable
                @click="toggleEventById(event.id)">
                <VTableCell>
                  <RadioBox :model-value="event.id === eventId" />
                </VTableCell>
                <VTableCell>
                  {{ event.name }}
                </VTableCell>
              </VTableRow>
            </VTable>
          </div>
        </div>
      </div>
    </FloatingWindowContainer>
  </div>
</template>
