<script setup lang="ts">
import type { ShiftTypeResource } from '@/types/shifts';
import { getNow, stampIsPast } from '@/util/timeFunctions';
import { timeFormat } from '@/variables/date-format';
import { arrayToJoinString, sortArrayBy } from '@/util/globals';
import moment from 'moment';
import { computed, defineAsyncComponent, nextTick, onMounted, ref, Ref } from 'vue';
import type { EventImpl } from '@fullcalendar/core/internal';
import { auditableShiftFields } from '@/helpers/auditHelper';
import { useEventListener } from '@vueuse/core';
import type { Crew as CrewResource } from '@/views/Group/Show/GroupMembers.vue';

type Props = {
  event: EventImpl;
  shiftTypes: ShiftTypeResource[];
  crew?: CrewResource[];
  hasHover?: boolean;
  hasMenu?: boolean;
  withHoverBackground?: boolean | null;
  groupId: number;
};

const props = withDefaults(defineProps<Props>(), {
  withHoverBackground: true,
  hasHover: true,
  hasMenu: true,
  crew: () => [],
});

defineEmits<{
  (e: 'editShift'): void;
  (e: 'refetch-events'): void;
  (e: 'openEvent'): void;
}>();

const AuditsSidebar = defineAsyncComponent(() => import('@/components/Audits/AuditsSidebar.vue'));
const ShiftDuplicateModal = defineAsyncComponent(() => import('@/components/Shifts/ShiftDuplicateModal.vue'));
const CalendarMemberShiftHover = defineAsyncComponent(
  () => import('@/components/calendar/events/CalendarMemberShiftHover.vue')
);
const CalendarMemberShiftMenu = defineAsyncComponent(
  () => import('@/components/calendar/event-menues/CalendarMemberShiftMenu.vue')
);
const ShiftAssignAndReplacementModal = defineAsyncComponent(
  () => import('@/components/Shifts/ShiftAssignAndReplacementModal.vue')
);
const ShiftCheckModal = defineAsyncComponent(() => import('@/components/Shifts/ShiftCheckModal.vue'));

const time = ref(
  moment(props.event.startStr).format(timeFormat) + ' - ' + moment(props.event.endStr).format(timeFormat)
);

const hours = Math.floor(moment.duration(moment(props.event.endStr).diff(props.event.startStr)).asHours());

if (hours > 20) {
  time.value = `${time.value}   (+${hours} H)`;
}

const shiftTypeTitle = computed(() => {
  const shiftType = props.shiftTypes.find((s) => s.id === props.event.extendedProps.shift_type_id);
  return shiftType?.title;
});

const borderColor = computed(() => {
  if (!props.event.extendedProps.isAssigned) {
    return 'border-l-white';
  }
  if (props.event.extendedProps.isCancelled) {
    // TODO: NELLA Y JORGEN
    return 'border-l-[hsl(var(--green-400))]';
  }

  if (props.event.extendedProps.isDeclined) {
    return 'border-l-warning';
  }

  if (props.event.extendedProps.isAccepted && props.event.extendedProps.approved) {
    return 'border-l-highlight';
  }

  if (!props.event.extendedProps.isAccepted && props.event.extendedProps.approved) {
    return 'border-l-pending';
  }
  return 'border-l-white';
});

const elem = ref<HTMLDivElement | null>();
const top = ref(0);
const left = ref(0);

const menuBox = ref<HTMLDivElement | null>(null);
const showMenu = ref(false);
const displayMenuBox = ref(false);

const hoverBox = ref<HTMLDivElement | null>(null);
const showHoverBox = ref(false);
const displayHoverBox = ref(false);

const setPositionOfBox = (box: Ref<HTMLElement | null>, toDisplay: Ref<boolean>) => {
  const rect = elem.value?.getBoundingClientRect();
  if (rect) {
    top.value = rect.bottom;
    left.value = rect.left;
    toDisplay.value = false;

    setTimeout(async () => {
      const hoverBoxRect = box.value?.getBoundingClientRect();
      if (!hoverBoxRect) return;

      if (left.value + hoverBoxRect.width > window.innerWidth) {
        const maybeLeft = window.innerWidth - hoverBoxRect.width - 10;
        if (maybeLeft > 0) {
          left.value = maybeLeft;
        } else {
          left.value = 0;
        }
      }

      if (top.value + hoverBoxRect.height > window.innerHeight) {
        const maybeTop = rect.top - hoverBoxRect.height - 10;
        if (maybeTop > 0) {
          top.value = maybeTop;
        } else {
          top.value = 0;
        }
      }

      toDisplay.value = true;
    }, 100);
  }
};

const openMenu = (e: MouseEvent) => {
  displayHoverBox.value = false;
  showHoverBox.value = false;
  e.preventDefault();
  showMenu.value = true;
  setPositionOfBox(menuBox, displayMenuBox);
};

onMounted(async () => {
  if (stampIsPast(props.event.startStr, 'day') && stampIsPast(props.event.endStr, 'minute')) {
    const parent = elem.value?.parentElement?.parentElement;
    if (parent) {
      if (!parent.classList.contains('fc-event-past')) {
        parent.classList.add('fc-event-past');
      }
    }
  }

  if (props.hasMenu) {
    await nextTick();

    useEventListener(document, 'contextmenu', (e) => {
      if (showMenu.value) {
        if (!elem.value?.parentElement?.parentElement?.contains(e.target as HTMLElement)) {
          showMenu.value = false;
        }
      }
    });

    elem.value?.parentElement?.parentElement?.addEventListener('contextmenu', openMenu);
  }
});

const hoverTimeout = ref<NodeJS.Timeout>();

const onMouseEnter = () => {
  if (showMenu.value || !props.hasHover) return;

  hoverTimeout.value = setTimeout(async () => {
    showHoverBox.value = true;
    setPositionOfBox(hoverBox, displayHoverBox);
  }, 250);
};

const onMouseLeave = () => {
  if (!props.hasHover) return;

  showHoverBox.value = false;
  displayHoverBox.value = false;
  clearTimeout(hoverTimeout.value);
};

const showDuplicateShiftModal = ref(false);

const getViaGroup = () => {
  let resources = props.event.getResources();

  if (resources.length > 0 && resources[0]._resource.id.includes('user')) {
    const item = props.event._context.calendarApi.getResourceById(resources[0]._resource.parentId);

    if (item) {
      resources = [item];
    }
  }

  return resources.length === 0 ? null : resources[0].title;
};

const getViaGroupName = () => {
  const resources = props.event.getResources();

  let parentName = undefined;

  if (resources[0]._resource.id.includes('Group')) {
    parentName = resources[0]._resource.title;
  } else {
    parentName =
      props.event._context.calendarApi.getResourceById(props.event.getResources()[0]._resource.parentId)?.title ?? null;
  }

  return parentName;
};

const getUserId = () => {
  const resources = props.event.getResources();
  const userId =
    resources.length > 0 && resources[0]._resource.id.includes('user')
      ? resources[0]._resource.extendedProps.model_id
      : null;
  return userId;
};

const getEvents = () => {
  const allEvents = props.event._context.calendarApi.getEvents();
  return allEvents.map((e) => ({
    event_id: e._def.extendedProps.event_id,
    shift_id: e._def.extendedProps.shift_id,
    isAssigned: e._def.extendedProps.isAssigned,
    start: e.start,
    end: e.end,
    resourceIds: e._def.resourceIds,
    events: e._def.extendedProps.events,
    title: e._def.title,
  }));
};

const getOtherUsersOfGroup = () => {
  if (!props.crew) return [];

  const allEvents = props.event._context.calendarApi.getEvents();
  const eventFromEvents = allEvents.filter(
    (e) =>
      e._def.extendedProps.hasOwnProperty('shift_id') &&
      e._def.extendedProps.shift_id !== null &&
      e._def.extendedProps.shift_id === props.event.extendedProps.shift_id
  );
  if (eventFromEvents && eventFromEvents.length > 0) {
    const resource = props.crew.filter((r) => r.id === eventFromEvents[0]._def.resourceIds[0]);
    if (resource.length > 0) {
      return sortArrayBy(
        props.crew.filter(
          (r) =>
            r.model === 'user' &&
            r.parentId === (resource[0].hasOwnProperty('parentId') ? resource[0].parentId : resource[0].id)
        ),
        'title'
      );
    }
  }

  return [];
};

const showAudit = ref(false);

const showReplaceModal = ref(false);

const selectedCheckShift = ref(null);
const showCheckModal = ref(false);
const isCheckIn = ref(false);

const openCheckInModal = (shift) => {
  showCheckModal.value = false;
  isCheckIn.value = true;
  selectedCheckShift.value = shift;
  nextTick(() => {
    showCheckModal.value = true;
  });
};
const openCheckOutModal = (shift) => {
  showCheckModal.value = false;
  isCheckIn.value = false;
  selectedCheckShift.value = shift;
  nextTick(() => {
    showCheckModal.value = true;
  });
};

useEventListener(
  'wheel',
  () => {
    showHoverBox.value = false;
    showMenu.value = false;
    displayMenuBox.value = false;
    displayHoverBox.value = false;
    clearTimeout(hoverTimeout.value);
  },
  { passive: true }
);

const shiftInterestPivots = ref(props.event.extendedProps.shift_interest_pivots);
</script>

<template>
  <div
    ref="elem"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave">
    <div
      class="relative border-[1px] border-l-[5px] pl-3 pr-2 flex pt-2 pb-2 gap-1 flex-col text bg-row-hover"
      :class="[
        borderColor,
        {
          'cancelled-shift': event.extendedProps.isCancelled,
          'hover:bg cursor-pointer': withHoverBackground,
        },
      ]">
      <div class="truncate text-sm">
        {{ event.extendedProps.shift_title ? event.extendedProps.shift_title : shiftTypeTitle }}
      </div>

      <div class="grid grid-cols-[auto_15px] gap-3 items-center">
        <div class="truncate text-sm h-[16px] p-0">
          {{ time }}
        </div>
        <i
          v-if="!event.extendedProps.isCancelled"
          class="fa text-sm text-highlight"
          :class="event.extendedProps.approved ? 'fa-square-check' : 'fa-square-o'" />

        <i
          v-if="event.extendedProps.isCancelled"
          class="fa fa-fw fa-times text-sm" />
      </div>

      <div class="flex items-center gap-2">
        <div
          v-if="!event.extendedProps.events?.length"
          class="truncate bg-borderColor-strong rounded px-3 py-1 text-xxs uppercase font-mediumbold">
          No Events added
        </div>
        <div
          v-else
          class="truncate text-xs text-soft">
          {{ arrayToJoinString(event.extendedProps.events.map((e) => e.name)) }}
        </div>
      </div>
      <div
        v-if="event.extendedProps.for_sale"
        class="flex items-center gap-2"
        @click.stop="showReplaceModal = true">
        <div class="truncate text-xs font-headers hover:bg-borderColor-strong px-3 py-1 rounded">
          <i class="fa fa-fw fa-cart-shopping fa-regular"></i>
          {{
            shiftInterestPivots.filter((p) => p.declined_at === null)?.length === 0 ? 'No' : shiftInterestPivots?.length
          }}
          Request{{ shiftInterestPivots.filter((p) => p.declined_at === null)?.length === 1 ? '' : 's' }}
        </div>
      </div>
    </div>

    <Teleport to="body">
      <div
        v-if="showHoverBox"
        ref="hoverBox"
        :style="`top: ${top}px; left: ${left}px;`"
        :class="{ 'invisible': !displayHoverBox }"
        class="fixed left-0 top-0 z-50">
        <CalendarMemberShiftHover
          :shift-types="shiftTypes"
          :shift-interest-pivots="shiftInterestPivots"
          :shift="event" />
      </div>

      <div
        v-if="showMenu"
        class="fixed left-0 top-0 z-[49] h-[99vh] w-[99vw]"
        @click="showMenu = false"></div>

      <div
        v-if="showMenu"
        ref="menuBox"
        :style="`top: ${top}px; left: ${left}px;`"
        :class="{ 'invisible': !displayMenuBox }"
        class="fixed left-0 top-0 z-50">
        <CalendarMemberShiftMenu
          v-if="event.extendedProps.shift_id"
          :can-edit="true"
          :shift="event"
          :can-duplicate="true"
          :can-cancel="true"
          :can-replace="true"
          :shift-types="shiftTypes"
          :events="event._context.calendarApi.getEvents()"
          :crew="crew"
          @open-event="$emit('openEvent', $event)"
          @open-check-out="openCheckOutModal"
          @open-check-in="openCheckInModal"
          @find-replacement="showReplaceModal = true"
          @open-audit="showAudit = true"
          @duplicate-shift="showDuplicateShiftModal = true"
          @refetch-events="event._context.calendarApi.refetchEvents()"
          @closed="showMenu = false"
          @edit-shift="$emit('editShift')" />
      </div>
    </Teleport>

    <AuditsSidebar
      v-if="showAudit"
      title="Audits for shift"
      :can-filter-models="false"
      :queryable-parameters="[
        {
          name: 'Shift',
          id: 'App\\Models\\Shifts\\Shift',
        },
      ]"
      :open-on-create="true"
      :can-filter-audit-events="true"
      :allowed-fields="[{ model: 'App\\Models\\Shifts\\Shift', fields: auditableShiftFields() }]"
      :url="'/api/audits/shifts/' + event.extendedProps.shift_id"
      @closed="showAudit = false" />

    <ShiftDuplicateModal
      v-if="showDuplicateShiftModal"
      :shift-id="event.extendedProps.shift_id"
      :shift-type-id="event.extendedProps.shift_type_id"
      :shift-end="event.endStr"
      :shift-start="event.startStr"
      :shift-types="shiftTypes"
      :via-group="getViaGroup()"
      @duplicated="event._context.calendarApi.refetchEvents()"
      @closed="showDuplicateShiftModal = false" />

    <ShiftAssignAndReplacementModal
      v-if="showReplaceModal"
      :group-id="groupId"
      :shift="{
        shift_id: event.extendedProps.shift_id,
      }"
      @shift-interest-pivot-declined="
        shiftInterestPivots = shiftInterestPivots.map((p) => {
          if (p.id === $event) {
            p.accepted_at = null;
            p.declined_at = getNow();
          }
          return p;
        })
      "
      @replacement-done="event._context.calendarApi.refetchEvents()"
      @closed="showReplaceModal = false" />

    <ShiftCheckModal
      v-if="showCheckModal"
      :allow-updating="false"
      :is-check-out-modal="!isCheckIn"
      :shift="selectedCheckShift"
      @checked-in="event._context.calendarApi.refetchEvents()"
      @checked-out="event._context.calendarApi.refetchEvents()"
      @closed="showCheckModal = false" />
  </div>
</template>
