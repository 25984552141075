<script setup lang="ts">
import { ModelType } from '@/types/global';
import { PartnerFieldResource } from '@/types/partners';
import { computed, nextTick, ref } from 'vue';
import { useToast } from 'vue-toastification';
import {
  addContactToCompany,
  createPartnerCompany,
  createPartnerContact,
  getPartnerFields,
  getPartnerTypes,
  PartnerType,
  patchPartnerValue,
} from '@/services/api-partners';
import { getItemFromArrayBasedOnId, getKey, tooShortOrLong } from '@/util/globals';
import TextInput from '@/components/Inputs/TextInput.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import PhoneInput from '@/components/Inputs/PhoneInput.vue';
import EmailInput from '@/components/Inputs/EmailInput.vue';
import { getComponent, getProps } from '@/util/get-component';
import CrudSlideout from '@/components/Slideout/CrudSlideout.vue';
import SearchSelectFloatingWindow from '@/components/Inputs/Components/SearchSelectFloatingWindow.vue';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';

type Props = {
  model: ModelType;
  modelId: number;
  initCompany?: object | null;
  canAddContacts?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  canAddContacts: true,
  initCompany: null,
});

const emit = defineEmits<{
  (event: 'saved', arg: any): void;
  (event: 'closed'): void;
  (event: 'contact', arg: any): void;
}>();

const toast = useToast();

type Company = {
  id: number | null;
  partner_type_id: number | null;
  name: string | null;
  phone: string | null;
  email: string | null;
};

const company = ref<Company>({
  id: getKey(props.initCompany, 'id'),
  partner_type_id: getKey(props.initCompany, 'partner_type_id'),
  name: getKey(props.initCompany, 'name'),
  email: getKey(props.initCompany, 'email'),
  phone: getKey(props.initCompany, 'phone'),
});

const partnerTypes = ref<PartnerType[]>([]);
const partnerFields = ref<PartnerFieldResource[]>([]);
const fieldValues = ref([]);

type Contact = {
  id: number | null;
  first_name: string | null;
  last_name: string | null;
  phone: string | null;
  email: string | null;
  role: string | null;
};

const contacts = ref<Contact[]>([]);
const working = ref(false);

const canSave = computed(() => {
  return company.value && company.value.name && company.value.name.length >= 1;
});

const fetchEverything = async () => {
  partnerFields.value = (await getPartnerFields(props.model, props.modelId, 'App\\PartnerCompany')).data;
  partnerFields.value = _.orderBy(partnerFields.value, 'order');

  const { data } = await getPartnerTypes(props.model, props.modelId);
  partnerTypes.value = data;
};
fetchEverything();

const createCompany = async (close: () => void) => {
  if (!company.value.name) return;
  if (tooShortOrLong(company.value.name, 'name')) return;
  if (!canSave.value || working.value) return;
  working.value = true;
  try {
    const { data } = await createPartnerCompany(props.model, props.modelId, {
      name: company.value.name,
      partner_type_id: company.value.partner_type_id,
      phone: company.value.phone,
      email: company.value.email,
    });
    company.value.id = data.id;

    for (let i = 0; i < contacts.value.length; i++) {
      if (!contacts.value[i].id) {
        if (contacts.value[0].first_name && contacts.value[0].first_name.trim().length) {
          const response = await createPartnerContact(props.model, props.modelId, {
            first_name: contacts.value[i].first_name,
            last_name: contacts.value[i].last_name,
            phone: contacts.value[i].phone,
            email: contacts.value[i].email,
          });
          contacts.value[i].id = response.data.id;
        }
      }
      await addContactToCompany(
        props.model,
        props.modelId,
        company.value.id,
        contacts.value[i].id,
        contacts.value[i].role,
        contacts.value[i].is_primary
      );
      emit('contact', contacts.value[i]);
    }
    for (let j = 0; j < fieldValues.value.length; j++) {
      await patchPartnerValue(
        props.model,
        props.modelId,
        fieldValues.value[j].id,
        fieldValues.value[j].value,
        company.value.id
      );
    }
    toast.success('Company Created');
    working.value = false;
    emit('saved', data);
  } catch (_) {
    toast.error('Something went wrong');
    working.value = false;
  }
  close();
};

const assignValue = (value, field) => {
  const index = _.findIndex(fieldValues.value, (f) => f.id === field.id);
  if (index > -1) {
    fieldValues.value[index].value = value;
  } else {
    fieldValues.value.push({
      id: field.id,
      value,
    });
  }
};

const pageY = ref<number | null>(null);
const pageX = ref<number | null>(null);
const openContactSearch = (event) => {
  pageY.value = null;
  pageX.value = null;
  nextTick(() => {
    pageX.value = event.target.getBoundingClientRect().x - 250;
    pageY.value = event.target.getBoundingClientRect().y + 30;
  });
};
const showContactCreation = computed(() => {
  return props.model === 'Group' && props.canAddContacts;
});
</script>

<template>
  <CrudSlideout
    :disabled="!canSave || working"
    :loading="working"
    :base-z-index="2000"
    :tiny="!showContactCreation"
    :small="showContactCreation"
    title="Create New Company"
    @closed="$emit('closed')"
    @create="createCompany">
    <div class="[&>div:last-child]:border-none [&>div]:border-b [&>div]:p-edge">
      <div
        class="form-layout"
        :class="showContactCreation ? 'grid-cols-2' : ''">
        <TextInput
          v-model="company.name"
          set-focus
          label="Name" />
        <VSelect
          v-if="partnerTypes.length > 0"
          v-model="company.partner_type_id"
          label="Type"
          option-value="title"
          :options="[{ id: null, title: 'Without Type' }].concat(partnerTypes)" />

        <PhoneInput
          v-model:phone="company.phone"
          size="block"
          :with-country-code="false" />

        <EmailInput v-model:email="company.email" />

        <template
          v-for="field in partnerFields"
          :key="field.id">
          <component
            :is="getComponent(field.component)"
            :label="field.title"
            v-bind="getProps(field)"
            :model-value="getItemFromArrayBasedOnId(field.id, fieldValues, { value: null }).value"
            :options="field.options"
            :is-template="true"
            :can-edit="true"
            placeholder="..."
            :with-buttons="false"
            @blur="assignValue($event, field)" />
        </template>
      </div>
      <div
        v-if="showContactCreation"
        class="flex justify-between p-edge">
        <h3>Contacts</h3>
        <ActionButtonGroup
          :actions="[
            {
              title: 'Add Contact',
              icon: 'fa-plus',
              disabled: !canSave,
              position: 80,
              action: ($event) => {
                openContactSearch($event);
              },
            },
          ]" />
      </div>

      <div v-for="(contact, index) in contacts">
        <div class="flex justify-end">
          <ActionButtonGroup
            :actions="[
              {
                title: 'Delete',
                icon: 'fa-trash',
                action: () => {
                  contacts.splice(index, 1);
                },
              },
            ]" />
        </div>

        <div class="form-layout grid-cols-2">
          <TextInput
            v-model="contact.first_name"
            label="First Name"
            :can-edit="contact.id === null" />

          <TextInput
            v-model="contact.last_name"
            label="Last Name"
            :can-edit="contact.id === null" />

          <PhoneInput
            v-if="contact.id === null"
            v-model:phone="contact.phone"
            size="block"
            :with-country-code="false" />

          <EmailInput
            v-if="contact.id === null"
            v-model:email="contact.email" />

          <TextInput
            v-model="contact.role"
            label="Role" />

          <SettingCheck
            v-model="contact.is_primary"
            title="If enabled, this contact will be a primary contact."
            label="Primary" />
        </div>
      </div>
      <SearchSelectFloatingWindow
        v-if="pageX && pageY"
        url="/api/partners/contacts"
        placeholder="Search For Contact"
        :debounce-time="500"
        can-create
        :params="{
          model_type: 'App\\' + model,
          model_id: modelId,
        }"
        :page-y="pageY"
        :page-x="pageX"
        :already-selected-ids="contacts.map((item) => item.id)"
        @create="contacts.push({ id: null, first_name: $event, last_name: '', email: '', is_primary: false })"
        @selected="
          contacts.push({
            id: $event.id,
            first_name: $event.first_name,
            last_name: $event.last_name,
            email: $event.email,
            phone: $event.phone,
            is_primary: false,
          })
        " />
    </div>
  </CrudSlideout>
</template>
