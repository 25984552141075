<script lang="ts" setup>
export type Mode = {
  name: string;
  value: number | string;
  hoverText?: string;
  icon?: string;
};

type Props = {
  greyDefault?: boolean;
  disabled?: boolean;
  modes: Mode[];
  classes?: string;
};

withDefaults(defineProps<Props>(), {
  greyDefault: false,
  disabled: false,
  classes: '',
});

const model = defineModel<string | number | object | null>();
</script>

<template>
  <ul
    class="flex flex-wrap gap-6"
    :class="classes + ' ' + (disabled ? 'opacity-50' : '')">
    <li
      v-for="mode in modes"
      :key="mode.value"
      :title="mode.hoverText"
      class="text-nowrap flex items-center border-b-[2px] cursor-pointer capitalize hover:text"
      :class="[
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        mode.value === model
          ? 'text font-semibold border-[--border-sub-nav-active]'
          : 'text-soft border-transparent font-mediumbold',
      ]"
      @click.stop="disabled ? null : (model = mode.value)">
      <i
        v-if="mode.icon"
        class="fa fa-fw mr-1"
        :class="mode.icon" />
      <span>
        {{ mode.name }}
      </span>
    </li>
  </ul>
</template>
