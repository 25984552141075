<script setup lang="ts">
import { DocumentResource } from '@/types/document';
import { inject, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import VButton from '@/components/Inputs/VButton.vue';
import { getKey } from '@/util/globals';
import { uploadFile } from '@/util/file-upload';
import { openRoute } from '@/util/route';
import { maxUploadSizeKey } from '@/provide/keys';

type Props = {
  editMode: boolean;
  isTemplate: boolean;
  eventStart?: string | null;
  content: DocumentResource[] | null;
  isNewRequest: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  eventStart: null,
});

const emit = defineEmits<{
  (event: 'update:content', ...args: object[]): void;
}>();

const maxUploadSize = inject(maxUploadSizeKey, 100);

const toast = useToast();
const deleteModal = useDeleteObjectModal();

const getId = (id?: string) => {
  if (id === undefined) {
    return null;
  }
  const res = id.split('/');
  return res[3];
};

type Document = {
  title: string;
  uuid: string | null;
  id: number;
  savedToPlatform: boolean;
};

const documents = ref<Document[]>([]);

if (props.content?.length) {
  documents.value = [
    ...props.content.map((a) => ({ title: a.filename, uuid: getId(a.download_url), id: a.id, savedToPlatform: true })),
  ];
}

watch(
  documents,
  () => {
    emit(
      'update:content',
      documents.value.map((a) => ({ title: a.title, uuid: a.uuid }))
    );
  },
  { deep: true }
);

const uploading = ref(false);

const removeDocument = async (document: Document, index: number) => {
  const deleteIt = await deleteModal.assertReadyToDeleteModal(
    'Remove Document?',
    'Are you sure you want to delete this document? It can not be restored.'
  );
  if (!deleteIt) return;

  if (getKey(document, 'savedToPlatform', false) && getKey(document, 'id') !== null) {
    await axios.delete(`/api/documents/${document.id}`).catch(() => {
      toast.warning('An error occured when deleting the document');
    });
  } else {
    await axios.post('/remove-doc-by-uuid', {
      uploadcare_uuid: document.uuid,
    });
  }
  toast.success(`${document.title} has been deleted.`);
  documents.value.splice(index, 1);
};

const openUploader = async () => {
  uploading.value = true;

  const res = await uploadFile({
    maxUploadSize: maxUploadSize,
  });

  if (!res) {
    uploading.value = false;
    return;
  }

  documents.value.push({
    title: res.name,
    uuid: res.uuid,
    savedToPlatform: false,
  });

  uploading.value = false;
  toast.success(`Uploaded.`);
};
</script>

<template>
  <div
    v-if="isTemplate"
    class="advance-section-item-container">
    <VButton
      size="inTable"
      disabled
      icon="fa-upload"
      title="Upload Document" />
  </div>
  <div
    v-else
    class="advance-section-item-container">
    <VTable v-if="documents.length">
      <template #head>
        <VTableRow head>
          <VTableCell>Title</VTableCell>
          <VTableCell />
          <VTableCell v-if="editMode" />
        </VTableRow>
      </template>
      <VTableRow
        v-for="(document, index) in documents"
        :key="document.uuid"
        main-row>
        <VTableCell>
          <TextInput
            :model-value="document.title"
            :can-edit="isNewRequest"
            @blur="document.title = $event" />
        </VTableCell>
        <VTableCell style="width: 50px">
          <VButton
            icon="fa-external-link"
            size="inTable"
            @click="openRoute('https://ucarecdn.com/' + document.uuid + '/', null, true)" />
        </VTableCell>
        <VTableCell
          v-if="editMode"
          style="width: 50px">
          <VButton
            icon="fa-trash"
            size="inTable"
            @click="removeDocument(document, index)" />
        </VTableCell>
      </VTableRow>
    </VTable>
    <div
      v-if="!isNewRequest && !documents.length"
      class="text-center italic text-soft">
      No Documents uploaded.
    </div>
    <VButton
      v-if="isNewRequest && editMode"
      size="inTable"
      :loading="uploading"
      icon="fa-upload"
      title="Upload Document"
      :disabled="uploading"
      @click="openUploader" />
  </div>
</template>
