<script setup lang="ts">
import ModeSelector from '@/components/Inputs/Components/ModeSelector.vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import { ref, watch } from 'vue';

type Props = {
  modelValue: boolean;
  isTemplate?: boolean | null;
  type: string;
  loading: boolean;
  disabled: boolean;
  titleHighlight: string;
  update: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'closed'): void;
  (event: 'update:modelValue', arg: boolean): void;
}>();

const modes = [
  {
    value: 'template',
    name: 'From Template',
  },
  {
    value: 'scratch',
    name: 'Blank',
  },
] as const;

const currentTab = ref(props.isTemplate || props.update ? 'scratch' : 'template');

watch(currentTab, () => {
  emit('update:modelValue', currentTab.value === 'template');
});
</script>

<template>
  <CrudModal
    :update="update"
    :loading="loading"
    :title-highlight="titleHighlight"
    :disabled="disabled"
    :title="update ? 'Update ' + type : 'Create ' + type"
    @closed="$emit('closed')">
    <div>
      <ModeSelector
        v-if="!isTemplate && !update"
        v-model="currentTab"
        class="mb-3 border-b"
        :modes="modes" />

      <slot />
    </div>
  </CrudModal>
</template>
