<template>
  <div>
    <VTable edge-to-edge>
      <template #head>
        <VTableRow head>
          <VTableCell v-if="roomBookings.length" />
          <VTableCell :classes="roomBookings.length ? '' : '!pl-0'">Room</VTableCell>
          <VTableCell>Title</VTableCell>
          <VTableCell>Start</VTableCell>
          <VTableCell>End</VTableCell>
          <VTableCell
            v-if="canEdit"
            style="width: 50px" />
          <VTableCell
            v-if="canEdit"
            style="width: 50px" />
        </VTableRow>
      </template>
      <VTableRow
        v-for="booking in roomBookings"
        :key="booking.id"
        clickable
        :classes="booking.issues ? 'ring-warning ring-2' : ''"
        @mouseenter="moseOverBooking($event, booking)"
        @mouseleave="moseNotOverBooking">
        <VTableCell @click="emitEditBooking(booking)">
          <template v-if="getKey(booking, 'working')">
            <i class="fa fa-fw fa-circle-o-notch fa-spin" />
          </template>
          <template v-else-if="getKey(booking, 'issues') !== null">
            <i
              v-if="booking.issues"
              class="fa fa-fw fa-info text-warning"
              title="Issues with booking" />
            <CheckBox
              v-if="!booking.issues"
              :model-value="true"
              title="No Issues with" />
          </template>
        </VTableCell>
        <VTableCell
          main-cell
          @click="emitEditBooking(booking)">
          {{ getRoomName(booking.room_id) }}
        </VTableCell>
        <VTableCell @click="emitEditBooking(booking)">
          {{ booking.title }}
        </VTableCell>
        <VTableCell @click="emitEditBooking(booking)">
          {{ formattedDateTime(booking.start) }}
        </VTableCell>
        <VTableCell @click="emitEditBooking(booking)">
          {{ formattedDateTime(booking.end) }}
        </VTableCell>
        <VTableCell v-if="canEdit">
          <VButton
            size="inTable"
            icon="fa-pencil"
            @click="emitEditBooking(booking)" />
        </VTableCell>
        <VTableCell v-if="canEdit">
          <VButton
            size="inTable"
            icon="fa-trash"
            @click="emitRemoveBooking(booking)" />
        </VTableCell>
      </VTableRow>
    </VTable>

    <HoverBox
      v-if="showBooking && displayBooking"
      :y-pos="pageY"
      :x-pos="pageX"
      event-bounds="">
      <div class="flex gap-5 rounded border bg-content p-edge">
        <div v-if="displayBooking.other_uses && displayBooking.other_uses.length">
          <h3>Other Uses</h3>
          <div class="flex flex-col gap-2">
            <div v-for="other_use in displayBooking.other_uses">
              <i class="fa fa-fw fa-circle text-xs"></i>
              {{ other_use.event.name }}: {{ formattedDateTime(other_use.start) }} and
              {{ formattedDateTime(other_use.end) }}
            </div>
          </div>
        </div>
        <div v-if="displayBooking.restrictions && displayBooking.restrictions.length">
          <h3>Restrictions</h3>
          <div class="flex flex-col gap-2">
            <div v-for="restriction in displayBooking.restrictions">
              <i class="fa fa-fw fa-circle text-xs"></i>
              {{ restriction.message }} from {{ getRoomName(restriction.room_id) }} on event
              {{ restriction.eventName }}. In use: {{ formattedDateTime(restriction.start) }} -
              {{ formattedDateTime(restriction.end) }}
            </div>
          </div>
        </div>
      </div>
    </HoverBox>
    <div
      v-if="!roomBookings.length"
      class="sub-title italic text-soft">
      No Bookings Added
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { timeFormat } from '@/variables/date-format';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import HoverBox from '@/components/HoverBox.vue';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import CheckBox from '@/components/Icons/CheckBox.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { getKey } from '@/util/globals';

export default {
  components: {
    VButton,
    CheckBox,
    IconWithLoading,
    HoverBox,
    VTableCell,
    VTableRow,
    VTable,
  },
  props: {
    roomBookings: {
      type: Array,
      required: false,
      default: () => [],
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    rooms: {
      type: Array,
      required: false,
      default: () => [],
    },
    startDate: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      displayBooking: null,
      showBooking: false,
      showBookingTimer: null,

      pageY: 0,
      pageX: 0,
    };
  },
  watch: {
    roomBookings() {
      setTimeout(() => {
        this.showBooking = false;
        this.displayBooking = null;
      }, 300);
    },
  },
  methods: {
    getKey,
    getRoomName(roomId) {
      if (this.rooms.length) {
        const index = _.findIndex(this.rooms, (r) => r.id === roomId);
        if (index > -1) {
          return this.rooms[index].name;
        }
      }
      return '';
    },

    moseNotOverBooking() {
      if (this.showBookingTimer) {
        clearInterval(this.showBookingTimer);
      } else {
        this.showBooking = false;
        this.displayBooking = null;
      }
    },
    clearAll() {
      clearInterval(this.showBookingTimer);
      this.showBooking = false;
      this.displayBooking = null;
    },
    moseOverBooking(mouseEnterInfo, booking) {
      if (!booking.hasOwnProperty('other_uses') && !booking.hasOwnProperty('restrictions')) {
        return;
      }
      if ((booking.other_uses && booking.other_uses.length) || (booking.restrictions && booking.restrictions.length)) {
        this.pageY = mouseEnterInfo.y + 10;
        this.pageX = mouseEnterInfo.x + 10;
        this.clearAll();
        this.$nextTick(() => {
          this.showBookingTimer = setInterval(() => {
            clearInterval(this.showBookingTimer);
            this.showBookingTimer = null;
            this.showBooking = true;
            this.displayBooking = booking;
          }, 500);
        });
      }
    },
    emitEditBooking(booking) {
      this.$emit('editBooking', booking);
    },
    emitRemoveBooking(booking) {
      this.$emit('removeBooking', booking);
    },
    formattedDateTime(time) {
      if (this.startDate) {
        if (moment(this.startDate).isSame(time, 'day')) {
          return moment(time).format(timeFormat);
        }
        return moment(time).format('YYYY-MM-DD HH:mm');
      }
      return moment(time).format('YYYY-MM-DD HH:mm');
    },
  },
};
</script>
