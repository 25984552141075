<script setup lang="ts">
import { useToast } from 'vue-toastification';
import {
  formatMinutesAsHoursAndMinutes,
  formatStampAsHumanReadableDateTimeShort,
  formatStampAsTime,
  timeStampsAreSame,
} from '@/util/timeFunctions';
import { canCheckInShiftCrewSlot, canCheckOutShiftCrewSlot } from '@/helpers/shiftFunctions';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import DisplayPhone from '@/components/Display/DisplayPhone.vue';
import DisplayEmail from '@/components/Display/DisplayEmail.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import ShiftSlotCheckModal from '@/components/Festivals/Shifts/ShiftSlotCheckModal.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import FloatingWindowContainer from '@/components/Inputs/Components/FloatingWindowContainer.vue';
import SearchSelect from '@/components/Inputs/SearchSelect.vue';
import VButton from '@/components/Inputs/VButton.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import { computed, nextTick, ref, watch } from 'vue';
import ModeSelector from '@/components/Inputs/Components/ModeSelector.vue';
import { getKey } from '@/util/globals';
import { getTitleForPublicFormResponseAvailableInSlot } from '@/helpers/publicFormFuctions';

type Props = {
  timeSlot?: any;
  crewSlot: any;
  festivalSection?: any;
  canAssign?: boolean;
  canEdit?: boolean;
  canCheckSlots?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  timeSlot: null,
  festivalSection: null,
  canAssign: false,
  canEdit: false,
  canCheckSlots: false,
});

const emit = defineEmits<{
  (event: 'for-sale', value: any): void;
  (event: 'forSale', value: any): void;
  (event: 'unAssignedSlot', value: any): void;
  (event: 'updated', value: any): void;
  (event: 'addUser', value: any): void;
  (event: 'checkedIn', value: any): void;
  (event: 'checkedOut', value: any): void;
  (event: 'checkCleared', value: any): void;
}>();

const toast = useToast();
const { assertCertain } = useCertaintyModal();

const isEdit = ref(false);
const title = ref(props.crewSlot.title);
const description = ref(props.crewSlot.description);
const hoverInput = ref(false);
const showCheckInModal = ref(false);
const showCheckOutModal = ref(false);
const search = ref(false);
const pageX = ref<number | null>(null);
const pageY = ref<number | null>(null);
const forceSearchCounter = ref(0);
const whoToShow = ref(props.timeSlot === null ? 'available' : 'prefers_timeslot');

const showMain = computed(() => {
  if (isEdit.value) return true;
  return (
    !!props.crewSlot.title || !!props.crewSlot.description || !!props.crewSlot.check_in || !!props.crewSlot.check_out
  );
});

const crew = computed(() => props.crewSlot.slottable);
const shift = computed(() => props.crewSlot.shift);

const openSearchInput = (event: MouseEvent) => {
  if (!props.canAssign) return;
  pageX.value = null;
  pageY.value = null;
  nextTick(() => {
    const box = (event.target as HTMLDivElement).getBoundingClientRect();
    if (!box) return;

    if (box.bottom + 120 > window.innerHeight) {
      pageY.value = box.top - 120;
    } else {
      pageY.value = box.bottom;
    }
    if (box.x + 400 > window.innerWidth) {
      pageX.value = box.x - (box.x + 400 - window.innerWidth);
    } else {
      pageX.value = box.x;
    }
  });
};

const toggleForSale = async () => {
  if (!props.canAssign) {
    return;
  }
  const certain = props.crewSlot.for_sale
    ? await assertCertain(
        `${props.crewSlot.for_sale ? 'Unmark' : 'Mark'} Slot For Sale`,
        `Are you sure you want to ${props.crewSlot.for_sale ? 'Unmark' : 'Mark'} this slot for sale?`
      )
    : true;
  if (!certain) return;
  await axios.patch(`/api/shift-crew-slots/${props.crewSlot.id}`, {
    for_sale: !props.crewSlot.for_sale,
  });
  toast.success(`Slot is ${props.crewSlot.for_sale ? 'no longer' : 'now'} for sale`);
  emit('forSale', !props.crewSlot.for_sale);
};

const remove = async () => {
  if (!props.festivalSection) return;
  const certain = await assertCertain(
    'Un-assign Slot',
    `Are you sure you want to remove ${crew.value.name} from this slot?`
  );
  if (!certain) return;
  await axios.post(`/api/shift-crew-slots/${props.crewSlot.id}/un-assign`);
  toast.success('Removed from slot.');
  emit('unAssignedSlot', {
    shiftId: shift.value.id,
    shiftCrewSlotId: props.crewSlot.id,
    publicFormResponseId: props.crewSlot.slottable_id,
  });
};

const update = async () => {
  try {
    await axios.patch(`/api/shift-crew-slots/${props.crewSlot.id}`, {
      title: title.value,
      description: description.value,
    });
    isEdit.value = false;
    toast.success('Updated slot');
    emit('updated', {
      shiftCrewSlotId: props.crewSlot.id,
      title: title.value,
      description: description.value,
    });
  } catch (e) {
    console.error(e);
    toast.warning('Something went wrong.');
    throw e;
  }
};

const cancel = () => {
  title.value = props.crewSlot.title;
  description.value = props.crewSlot.description;
  isEdit.value = false;
};

const showCheckModal = (showCheckIn = false, showCheckOut = false) => {
  if (!props.canCheckSlots) return;
  if (!crew.value) return;
  showCheckInModal.value = false;
  showCheckOutModal.value = false;
  nextTick(() => {
    showCheckInModal.value = showCheckIn;
    showCheckOutModal.value = showCheckOut;
  });
};

watch(whoToShow, (newValue) => {
  window.localStorage.setItem('who_to_show_for_shift_crew_slots', whoToShow.value);
  forceSearchCounter.value++;
});
whoToShow.value = window.localStorage.getItem('who_to_show_for_shift_crew_slots') ?? 'available';
if (!props.timeSlot && whoToShow.value === 'prefers_timeslot') {
  whoToShow.value = 'available';
}

const getParamsForSearch = () => {
  let params = {
    festival_section_id: props.festivalSection.id,
    status: 'accepted',
    order_by: 'assigned_work_minutes',
    page: 1,
    per_page: 20,
  };
  switch (whoToShow.value) {
    case 'prefers_timeslot':
      params = {
        ...params,
        start: shift.value.start,
        end: shift.value.end,
        preferred_time_slot_ids: [props.timeSlot.id],
      };
      break;
    case 'available':
      params = {
        ...params,
        start: shift.value.start,
        end: shift.value.end,
      };
      break;
    case 'all':
      params = {
        ...params,
        start: shift.value.start,
        end: shift.value.end,
        skip_shift_of_id: shift.value.id,
        filter_out_busy: false,
      };
      break;
  }
  return params;
};

// const getTitleOfOption = (option) => {
//   if (whoToShow.value === 'all') {
//     const otherShifts = getKey(option, 'shift_crew_slots', []);
//     if (otherShifts.length === 0) {
//       return option.name + ' is available in this time frame';
//     }
//     return option.name + ' already has ' + otherShifts.length + ' shifts in this time frame. These are:' + arrayToJoinString((otherShifts.map((s) => {
//       const duration = getDiffInInterval(s.shift.start, s.shift.end, 'hours');
//       return (s.shift?.title ?? 'Shift') + ' for ' + (s.shift.via?.name ?? 'Unknown Section') + ' - ' + formatStampAsTime(s.shift.start) + ' to ' + formatStampAsTime(s.shift.end) + (duration > 20 ? '( ' + duration + 'H)' : '') + ')';
//     })));
//   }
//   return null;
// }
</script>

<template>
  <div>
    <div class="rounded border border">
      <div
        class="flex flex-col gap-2 [&>div:last-child]:border-none [&>div]:border-b [&>div]:p-edge [&_label]:text-soft">
        <div>
          <div>
            <FloatingWindowContainer
              v-if="pageX && pageY"
              :page-x="pageX"
              :page-y="pageY">
              <ModeSelector
                v-model="whoToShow"
                class="mb-2"
                :modes="
                  [
                    timeSlot !== null
                      ? {
                          value: 'prefers_timeslot',
                          name: 'Prefers Timeslot',
                          hoverText: 'Prefers ' + timeSlot.title + ' and does not have any other shifts in time frame',
                        }
                      : null,
                    {
                      value: 'available',
                      name: 'Available',
                      hoverText: 'Available in Time Frame, meaning no other shifts in time frame',
                    },
                    {
                      value: 'all',
                      name: 'All',
                      hoverText: 'Show All Responders, regardless of availability',
                    },
                  ].filter((i) => i !== null)
                " />
              <SearchSelect
                v-if="pageX && pageY"
                url="/api/public-form-responses"
                placeholder="Search For Responder"
                :debounce-time="500"
                :min-search-length="0"
                :add-y="25"
                search-on-open
                nothing-found-text="No Responders Found"
                :can-create="false"
                :override-width="300"
                :params="getParamsForSearch()"
                :page-y="pageY"
                :page-x="pageX"
                is-object
                search-param="search"
                :force-search-outside="forceSearchCounter"
                autofocus
                @clear="pageX = null"
                @update:model-value="[$emit('addUser', $event), (pageY = null)]">
                <template #default="{ option }">
                  <div
                    :title="getTitleForPublicFormResponseAvailableInSlot(option, whoToShow)"
                    :style="'grid-template-columns: auto 100px ' + (whoToShow === 'all' ? '50px' : '')"
                    class="grid h-full items-center">
                    <div class="truncate">
                      <i
                        v-if="whoToShow === 'all' && getKey(option, 'shift_crew_slots', []).length > 0"
                        class="fa fa-fw fa-info-circle text-warning mr-2" />
                      <i
                        v-else
                        class="fa fa-fw fa-user-circle mr-2" />
                      {{ option.name }}
                    </div>
                    <div class="text-sm text-soft">
                      Workload: {{ formatMinutesAsHoursAndMinutes(option.assigned_work_minutes) }}h
                    </div>
                    <div
                      v-if="whoToShow === 'all' && getKey(option, 'shift_crew_slots', []).length > 0"
                      class="text-sm text-soft text-center">
                      <div class="bg-pending w-[20px] m-auto rounded-full text-black">
                        {{ getKey(option, 'shift_crew_slots', []).length }}
                      </div>
                    </div>
                  </div>
                </template>
              </SearchSelect>
            </FloatingWindowContainer>
            <div class="flex flex-col">
              <div class="flex items-center justify-between">
                <InputLabel
                  label="Assignee"
                  :class="{ '!text-highlight': crew }" />
                <div class="flex gap-3">
                  <VButton
                    v-if="canAssign && !isEdit"
                    size="extra-small"
                    :title="crewSlot.for_sale ? 'For Sale' : 'Mark For Sale'"
                    :tool-tip-text="
                      crewSlot.for_sale
                        ? 'Slot is marked as for sale, click to remove it.'
                        : 'Slot is not for sale. Click to put it up for sale.'
                    "
                    :icon="crewSlot.for_sale ? 'fa-cart-shopping fa-regular' : 'fa-cart-plus fa-regular text-soft'"
                    @click="toggleForSale" />
                  <VButton
                    v-if="isEdit"
                    size="inTable"
                    type="warning"
                    tool-tip-text="Cancel"
                    icon="fa-times"
                    @click="cancel" />
                  <VButton
                    v-if="isEdit"
                    size="extra-small"
                    tool-tip-text="Save"
                    type="success"
                    icon="fa-save"
                    @click="update" />
                  <VButton
                    v-if="!isEdit && canEdit"
                    size="inTable"
                    icon="fa-pencil"
                    @click="[(isEdit = !isEdit), (search = false)]" />
                </div>
              </div>

              <div class="grid grid-cols-[auto_85px] items-center gap-5">
                <div
                  :class="{
                    'cursor-pointer rounded p-2 hover:bg-content hover:ring-1 hover:ring-borderColor': canAssign,
                    'ring-1 ring-borderColor': !crew && canAssign,
                  }">
                  <h5
                    class="flex items-center gap-5"
                    :class="{ pointer: canAssign, 'text-xs uppercase italic': !crew, 'font-bold': crew }"
                    @click="openSearchInput($event)">
                    {{ crew ? crew.name : 'Unassigned' }}
                    <template v-if="canAssign">
                      <i
                        v-if="!crew"
                        class="fa fa-fw fa-solid"
                        @click="openSearchInput($event)" />
                      <i
                        v-if="crew"
                        class="fa fa-fw fa-times-circle selectable-icon cursor-pointer"
                        @click.stop="remove" />
                    </template>
                  </h5>
                </div>
                <div
                  v-if="crew && !isEdit && canEdit"
                  class="float-right flex w-full gap-5 [&>div]:w-[47px]">
                  <div class="ml-1">
                    <DisplayEmail
                      icon-only
                      indicate-missing
                      :email="crew.email" />
                  </div>
                  <div>
                    <DisplayPhone
                      icon-only
                      icon-class="text-sm"
                      indicate-missing
                      :phone-number="crew.phone"
                      :country-code="crew.country_code" />
                  </div>
                </div>
              </div>

              <div
                v-if="crew && !isEdit && !canEdit"
                class="flex flex-col gap-2 mt-2">
                <div class="ml-1">
                  <DisplayEmail
                    indicate-missing
                    :email="crew.email" />
                </div>
                <div>
                  <DisplayPhone
                    indicate-missing
                    :phone-number="crew.phone"
                    :country-code="crew.country_code" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!isEdit && crew && (crewSlot.check_in || canCheckInShiftCrewSlot(shift, crewSlot))"
          class="grid grid-cols-2 gap-3">
          <div v-if="!isEdit && crew && canCheckInShiftCrewSlot(shift, crewSlot) && canCheckSlots">
            <VButton
              size="inTable"
              icon="fa-sign-in"
              title="Check In"
              @click="showCheckModal(true, false)" />
          </div>
          <div v-if="crewSlot.check_in">
            <div
              class="group flex cursor-pointer flex-col"
              :title="crewSlot.check_in"
              @click="showCheckModal(true, false)">
              <InputLabel label="Check-in" />
              <div class="flex items-center justify-between font-semibold">
                <div class="flex items-center gap-3">
                  <i class="fa fa-fw fa-sign-in text-highlight" />
                  {{
                    timeStampsAreSame(crewSlot.shift.start, crewSlot.check_in, 'day')
                      ? formatStampAsTime(crewSlot.check_in)
                      : formatStampAsHumanReadableDateTimeShort(crewSlot.check_in)
                  }}
                </div>
                <VButton
                  v-if="canCheckSlots"
                  size="inTable"
                  icon="fa-pencil invisible group-hover:visible"
                  @click="showCheckModal(true, false)" />
              </div>
            </div>
          </div>
          <div
            v-if="crewSlot.check_out"
            :title="crewSlot.check_out"
            class="group flex cursor-pointer flex-col"
            @click="showCheckModal(false, true)">
            <InputLabel label="Check Out" />
            <div class="flex items-center justify-between font-semibold">
              <div class="flex items-center gap-3">
                <i class="fa fa-fw fa-sign-out text-highlight" />
                {{
                  timeStampsAreSame(crewSlot.shift.end, crewSlot.check_out, 'day')
                    ? formatStampAsTime(crewSlot.check_out)
                    : formatStampAsHumanReadableDateTimeShort(crewSlot.check_out)
                }}
              </div>
              <VButton
                size="inTable"
                icon="fa-pencil invisible group-hover:visible"
                @click="showCheckModal(false, true)" />
            </div>
          </div>
          <div>
            <VButton
              v-if="canCheckOutShiftCrewSlot(crewSlot) && canCheckSlots"
              class="float-right mt-[22px]"
              icon="fa-sign-out"
              size="inTable"
              title="Check Out"
              @click="showCheckModal(false, true)" />
          </div>
          <div
            v-if="crewSlot.check_out && crewSlot.message"
            class="col-span-2">
            <InputLabel label="Check Out Message" />
            <p>{{ crewSlot.message }}</p>
          </div>
        </div>
        <div
          v-if="isEdit || crewSlot.title || crewSlot.description"
          class="flex flex-col gap-4 p-edge">
          <div v-if="isEdit || crewSlot.title">
            <InputLabel label="Title" />
            <div v-if="isEdit || crewSlot.title">
              <TextInput
                v-if="isEdit"
                v-model="title"
                set-focus />
              {{ isEdit ? '' : title }}
            </div>
          </div>
          <div v-if="isEdit || crewSlot.description">
            <InputLabel label="Description" />
            <div v-if="isEdit || crewSlot.description">
              <TextareaInput
                v-if="isEdit"
                v-model="description"
                :min-rows="1" />
              {{ isEdit ? '' : description }}
            </div>
          </div>
        </div>
      </div>
      <ShiftSlotCheckModal
        v-if="(showCheckInModal || showCheckOutModal) && canCheckSlots"
        :allow-updating="true"
        :is-check-out-modal="showCheckOutModal"
        :shift-slot="crewSlot"
        :shift="shift"
        :uri="'/api/shift-crew-slots/' + crewSlot.id"
        @closed="[(showCheckInModal = false), (showCheckOutModal = false)]"
        @check-cleared="$emit('checkCleared')"
        @checked-in="$emit('checkedIn', { shiftCrewSlotId: crewSlot.id, check_in: $event })"
        @checked-out="
          $emit('checkedOut', {
            shiftCrewSlotId: crewSlot.id,
            check_out: $event.check_out,
            message: $event.message,
          })
        " />
    </div>
  </div>
</template>
