<script setup lang="ts">
import { documentKey, maxUploadSizeKey } from '@/provide/keys';
import { DocumentResource } from '@/types/document';
import { computed, inject, ref } from 'vue';
import ImageViewModal from '@/components/Documents/ImageViewModal.vue';
import VButton from '@/components/Inputs/VButton.vue';
import DocumentUploader from '@/components/Documents/DocumentUploader.vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import DocumentCellFolder from '@/components/Config/ColumnConfig/Cells/Files/DocumentCellFolder.vue';
import VTable from '@/components/Tables/VTable.vue';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import DocumentCellDocument from '@/components/Config/ColumnConfig/Cells/Files/DocumentCellDocument.vue';

type Props = {
  modelValue: string | number | null;
  canEdit: boolean;
  isShowMode: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:modelValue', arg: any): void;
}>();

const maxUploadSize = inject(maxUploadSizeKey, 100);

const { fetch, folderDocuments, unconnectedDocuments, allDocuments, model, modelId } = inject(documentKey, {
  fetch: async () => {},
  loading: ref(false),
  folderDocuments: computed(() => []),
  unconnectedDocuments: computed(() => []),
  allDocuments: computed(() => []),
  model: 'Event',
  modelId: 0,
});
fetch();

const empty = computed(() => folderDocuments.value.length === 0 && unconnectedDocuments.value.length === 0);

const modalOpen = ref(false);
const loading = ref(false);

const document = computed(() => allDocuments.value.find((doc) => doc.id === Number(props.modelValue)));

const removeDocument = async () => {
  const removeIt = await useDeleteObjectModal().assertReadyToDeleteModal(
    'Remove Document',
    'Are you sure you want to remove this document?'
  );
  if (!removeIt) return;

  emit('update:modelValue', null);
};

const openFolderIds = ref<Set<number>>(new Set());
const getFolderIds = (folder) => {
  return [folder.id].concat(folder.children.map(getFolderIds).flat());
};

const openModal = () => {
  openFolderIds.value = new Set(folderDocuments.value.flatMap((folder) => getFolderIds(folder)));
  modalOpen.value = true;
};

const isImage = computed(() => {
  if (!document.value) return false;
  return document.value.mime_type.startsWith('image');
});
const showImage = ref(false);
const documentAdded = async (doc: DocumentResource, close: () => void, forceFetch = false) => {
  if (forceFetch) {
    await fetch(true);
  }
  emit('update:modelValue', doc.id);
  close();
};
</script>
<template>
  <div
    v-if="document"
    class="flex items-center gap-2">
    <div
      class="w-full truncate"
      :title="document.filename"
      :class="{ 'cursor-pointer': isImage }"
      @click="showImage = true">
      <img
        :src="document.download_url + '/-/resize/x200/'"
        style="max-width: 100%; max-height: 100%; width: auto; height: auto" />
    </div>
    <div v-if="canEdit">
      <VButton
        type="inTable"
        icon="fa-times"
        @click="removeDocument" />
    </div>
    <ImageViewModal
      v-if="isImage && showImage"
      :image="document"
      :can-edit="false"
      @close="showImage = false" />
  </div>
  <div
    v-else-if="!isShowMode"
    class="flex h-full items-center justify-center">
    <VButton
      v-if="canEdit"
      type="inTable"
      icon="fa-image"
      tool-tip-text="Select or Upload image to this cell"
      @click="openModal" />
    <CrudModal
      v-if="modalOpen"
      title="Select Document"
      @closed="modalOpen = false">
      <template #default="{ close }">
        <div
          v-if="empty"
          class="text-soft">
          No Documents or Folders has been created yet.
        </div>
        <VTable>
          <DocumentCellFolder
            v-for="folder in folderDocuments"
            :key="folder.id"
            v-model:open-ids="openFolderIds"
            image-only
            :folder="folder"
            :selected-document-id="modelValue"
            :document-folder="folder"
            @select-document="documentAdded($event, close)" />

          <DocumentCellDocument
            v-for="doc in unconnectedDocuments"
            :key="doc.id"
            image-only
            :document="doc"
            :selected-document-id="modelValue"
            @select-document="documentAdded($event, close)" />
        </VTable>
      </template>

      <template #footer="{ close }">
        <DocumentUploader
          class="float-right"
          :can-edit="canEdit"
          :model="model"
          :model-id="modelId"
          :max-m-b-size="maxUploadSize"
          @document-uploaded="documentAdded($event, close, true)" />
      </template>
    </CrudModal>
  </div>
</template>
