<script lang="ts" setup>
import { useContextSidebarStore } from '@/store/ContextSidebarStore';
import { computed, onMounted, ref, watch } from 'vue';
import { router } from '@inertiajs/vue3';
import ContextSidebarListItem from '@/components/ContextSidebar/ContextSidebarListItem.vue';
import ChevronToggle from '@/components/Icons/ChevronToggle.vue';
import { useAutoAnimate } from '@formkit/auto-animate/vue';
import { tooltip } from '@/util/tooltip';
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';

type Props = {
  modelValue?: boolean;
  canOpen?: boolean;
  route?: string;
  title?: string;
  icon?: string;
  sectionId: string;
  isSubMenu?: boolean;
  isSectionTitle?: boolean;
  iconOnSmallScreen?: boolean;
  showChevron?: boolean;
  withSubMenu?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  showChevron: true,
  modelValue: false,
  canOpen: true,
  route: '',
  title: '',
  icon: '',
  isSubMenu: true,
  isSectionTitle: false,
  iconOnSmallScreen: false,
  withSubMenu: true,
});

const emit = defineEmits<{
  (e: 'openList'): void;
  (e: 'update:modelValue', arg: boolean): void;
}>();

const contextSidebarStore = useContextSidebarStore();

const active = computed(
  () => (contextSidebarStore.currentView?.includes(props.sectionId) && props.isSubMenu && props.modelValue) ?? false
);

const localCanOpen = computed(() => {
  return props.canOpen;
});

const onClick = () => {
  if (!contextSidebarStore.sidebarLarge) return;

  if (localCanOpen.value) {
    emit('update:modelValue', !props.modelValue);
  }
  if (props.route && props.sectionId) {
    router.visit(props.route);
  }
};

onMounted(() => {
  if (props.route && props.sectionId) {
    if (props.route === window.location.href) {
      emit('openList');
    }
  }
});

const [parent, enable] = useAutoAnimate({ duration: 150 });
enable(props.sectionId !== 'main');

const shouldShow = computed(() => {
  if (contextSidebarStore.sidebarLarge) return true;
  return !props.isSectionTitle;
});

const shouldExpend = computed(() => {
  if (contextSidebarStore.sidebarLarge) return true;
  if (props.sectionId === 'main') return true;
  return props.isSectionTitle;
});

const wrapper = ref<HTMLDivElement | null>(null);

const isOpen = ref(contextSidebarStore.sidebarLarge);

onMounted(() => {
  watch(
    () => contextSidebarStore.sidebarLarge,
    (v) => {
      isOpen.value = v;
      tooltip(wrapper.value, props.title, 'right', 10, isOpen);
    },
    {
      immediate: true,
    }
  );
});
</script>

<template>
  <div v-if="isSubMenu">
    <div
      v-if="title && shouldShow"
      ref="wrapper"
      class="flex h-[1.6785rem] items-center justify-between px-2 py-[1px]"
      :class="active || isSectionTitle ? 'text-soft' : 'cursor-pointer'"
      @click="onClick">
      <div
        class="flex items-center gap-3 truncate w-full h-full rounded"
        :class="[
          iconOnSmallScreen && !contextSidebarStore.sidebarLarge ? '' : ' pl-5',
          {
            'hover:bg-[--color-background-context-sidebar-item-hover] ': !isSectionTitle,
          },
          {
            'border text text-center flex justify-center mx-1 !pl-0':
              !isSectionTitle && !contextSidebarStore.sidebarLarge,
          },
        ]">
        <div
          v-if="contextSidebarStore.sidebarLarge && !isSectionTitle"
          class="flex h-full items-center w-[15px] justify-center"
          @click.stop="localCanOpen ? $emit('update:modelValue', !modelValue) : null">
          <ChevronToggle
            classes="text-xxs text-soft fa-fw"
            :model-value="localCanOpen ? modelValue : true" />
        </div>
        <!--        <i-->
        <!--          v-if="iconOnSmallScreen ? !contextSidebarStore.sidebarLarge : icon"-->
        <!--          class="fa fa-fw text-sm"-->
        <!--          :class="[icon, iconOnSmallScreen && !contextSidebarStore.sidebarLarge ? 'mx-auto' : '']" />-->
        <div
          v-if="contextSidebarStore.sidebarLarge"
          :style="isSectionTitle ? 'color: var(--color-text-sidebar-category)' : ''"
          :class="[
            { 'w-[0px] ': !contextSidebarStore.sidebarLarge },
            { 'text-soft text-sm cursor-default': isSectionTitle },
          ]"
          class="flex-1 truncate font-normal">
          {{ title }}
        </div>

        <div v-else-if="!isSectionTitle">
          <VDropdown
            :open-on-hover="false"
            :close-on-esc="true"
            :page-x-modifier="40"
            :data-theme="'base'"
            :can-open-dropdown="true"
            :page-y-modifier="-25"
            :close-on-click="true">
            <template #click-area>
              <div
                ref="wrapper"
                class="w-full text-xs uppercase tracking-context font-mediumbold">
                {{ title.substring(0, 3) }}
              </div>
            </template>

            <template #dropdown="{ close }">
              <div class="py-1">
                <div class="flex justify-between text-soft mb-2">
                  <div class="flex-1 pl-[20px] pt-3 text-sm font-normal">{{ title }}</div>
                  <div>
                    <button
                      class="pr-2 pt-2"
                      @click="close()">
                      <i class="fa fa-fw fa-times" />
                    </button>
                  </div>
                </div>
                <slot
                  :full="true"
                  :close="close"
                  :tooltip="false"
                  :classes="`mx-3 mt-1`" />
              </div>
            </template>
          </VDropdown>
        </div>
      </div>
    </div>
    <div
      v-if="shouldExpend"
      :ref="props.sectionId === 'main' ? null : 'parent'"
      class="flex w-[100%] flex-col">
      <div
        v-show="modelValue && canOpen"
        class="space-y-1">
        <slot />
      </div>
    </div>
  </div>
  <ContextSidebarListItem
    v-else
    :item-id="sectionId"
    :icon="icon"
    hash=""
    :title="title"
    :route="route"
    @open-list="$emit('openList')" />
</template>
