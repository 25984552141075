<script setup lang="ts">
import { usePage } from '@inertiajs/vue3';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import NumberInput from '@/components/Inputs/NumberInput.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VRichText from '@/components/Inputs/VRichText.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import { getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import { ref } from 'vue';
import { copyObject } from '@/util/object-helpers';
import EmailInput from '@/components/Inputs/EmailInput.vue';

type Props = {
  reminderSetting?: any;
  canIncludeLink?: boolean;
  exchangeWords?: any[];
  alreadyExists?: boolean;
  hideEmailTemplates?: boolean;
  canSetHoursBefore?: boolean;
  canSetReceiver?: boolean;
  canSetSendAt?: boolean;
  canSetTitle?: boolean;
  hasSms?: boolean;
  canSetReplyTo?: boolean;
  emailTemplates?: any[];
};

const props = withDefaults(defineProps<Props>(), {
  reminderSetting: null,
  canIncludeLink: false,
  exchangeWords: () => [],
  alreadyExists: false,
  canSetHoursBefore: false,
  hideEmailTemplates: false,
  canSetReceiver: false,
  canSetSendAt: false,
  canSetTitle: true,
  hasSms: false,
  canSetReplyTo: false,
  emailTemplates: () => [],
});

const emit = defineEmits<{
  (e: 'update:forShifts', value: any): void;
  (e: 'update:forShiftCrewSlots', value: any): void;
  (e: 'update:content', value: any): void;
  (e: 'update:email', value: any): void;
  (e: 'update:sms', value: any): void;
  (e: 'update:reminderSetting', value: any): void;
}>();

const emailTemplateId = ref(null);

const typeSelected = (value) => {
  if (props.alreadyExists) return;

  const reminderSetting = copyObject(props.reminderSetting);

  switch (value) {
    case 'sms': {
      reminderSetting.sms = true;
      reminderSetting.email = false;
      break;
    }
    case 'email': {
      reminderSetting.sms = false;
      reminderSetting.email = true;
      break;
    }
    case null: {
      reminderSetting.sms = false;
      reminderSetting.email = false;
      break;
    }
    default: {
      return;
    }
  }
  reminderSetting.content = null;
  emit('update:reminderSetting', reminderSetting);
};

const receiverSelected = (value) => {
  if (props.alreadyExists) return;

  const reminderSetting = copyObject(props.reminderSetting);

  switch (value) {
    case 'Crew': {
      reminderSetting.for_shifts = true;
      reminderSetting.for_shift_crew_slots = false;
      break;
    }
    case 'Public Form Responses': {
      reminderSetting.for_shifts = false;
      reminderSetting.for_shift_crew_slots = true;
      break;
    }
    case null: {
      reminderSetting.for_shifts = false;
      reminderSetting.for_shift_crew_slots = false;
      break;
    }
    default: {
      return;
    }
  }
  emit('update:reminderSetting', reminderSetting);
};

const emitReminderSetting = (key, value) => {
  const reminderSetting = copyObject(props.reminderSetting);

  reminderSetting[key] = value;
  emit('update:reminderSetting', reminderSetting);
};

const updateFromTemplate = (newTemplateId) => {
  const template = getItemFromArrayBasedOnId(newTemplateId, props.emailTemplates, {});
  const reminderSetting = copyObject(props.reminderSetting);
  reminderSetting.email_subject = getKey(template, 'subject', '');
  reminderSetting.content = getKey(template, 'text', '');
  reminderSetting.with_link = getKey(template, 'with_link', true);
  reminderSetting.reply_to_email = getKey(template, 'reply_to_email', null);
  emit('update:reminderSetting', reminderSetting);
};

const replyToUpdated = (newReplyTo) => {
  const reminderSetting = copyObject(props.reminderSetting);
  switch (newReplyTo) {
    case 'Me': {
      reminderSetting.reply_to_id = usePage().props.auth.user.id;
      reminderSetting.reply_to_email = null;
      break;
    }
    case 'Other': {
      reminderSetting.reply_to_id = null;
      reminderSetting.reply_to_email = '';
      break;
    }
    case null:
    default:
      reminderSetting.reply_to_id = null;
      reminderSetting.reply_to_email = null;
      break;
  }
  emit('update:reminderSetting', reminderSetting);
};
</script>

<template>
  <div class="flex flex-col gap-5">
    <div class="form-layout grid-cols-2">
      <VSelect
        v-if="hasSms"
        :model-value="reminderSetting.sms ? 'sms' : reminderSetting.email ? 'email' : null"
        label="Communication Type"
        :options="[
          { id: 'sms', name: 'SMS' },
          { id: 'email', name: 'Email' },
        ]"
        @update:model-value="typeSelected($event)" />

      <VSelect
        v-if="canSetReceiver"
        :model-value="
          reminderSetting.for_shifts ? 'Crew' : reminderSetting.for_shift_crew_slots ? 'Public Form Responses' : null
        "
        nullable
        label="Receiver"
        :options="[
          { id: 'Crew', name: 'Crew' },
          { id: 'Public Form Responses', name: 'Public Form Responses' },
        ]"
        @update:model-value="receiverSelected($event)" />

      <NumberInput
        v-if="canSetHoursBefore"
        :model-value="reminderSetting.hours_before"
        title="How many hours before the start of the shift should the reminder be sent?"
        label="Hours before start *"
        size="block"
        :with-controlles="true"
        @update:model-value="emitReminderSetting('hours_before', $event)" />

      <div
        v-if="canSetSendAt"
        class="form-group">
        <InputLabel label="Send At *" />
        Now!
      </div>

      <TextInput
        v-if="canSetTitle"
        label="Title"
        :model-value="reminderSetting.title"
        @blur="emitReminderSetting('title', $event)" />
      <VSelect
        v-if="reminderSetting.email && emailTemplates.length > 0"
        v-model="emailTemplateId"
        option-value="subject"
        nullable
        nullable-display-text="Blank"
        :options="emailTemplates"
        label="Email Template"
        @update:model-value="updateFromTemplate" />
      <TextInput
        v-if="reminderSetting.email && emailTemplates.length === 0 && !hideEmailTemplates"
        :can-edit="false"
        placeholder="No email templates created yet"
        label="Email Template" />
      <TextInput
        v-if="reminderSetting.email"
        text-wrapper-class="col-span-2"
        label="Email Subject"
        :exchange-words="exchangeWords"
        :model-value="reminderSetting.email_subject"
        required
        @blur="emitReminderSetting('email_subject', $event)" />
    </div>
    <div v-if="reminderSetting.email">
      <InputLabel label="Content of Email" />
      <div class="rounded">
        <VRichText
          :model-value="reminderSetting.content"
          :exchange-words="exchangeWords"
          @update:model-value="emitReminderSetting('content', $event)"></VRichText>
      </div>
    </div>
    <div v-if="reminderSetting.sms">
      <div class="flex flex-col">
        <InputLabel label="Content of SMS" />
        <div class="grid grid-cols-2 mb-1">
          <small
            class="pull-right sub-title text-soft"
            title="As some parts of this will be exchanged for user data, it might become more. 160 characters per SMS.">
            Approx SMS count:
            {{ reminderSetting.content ? Math.ceil(reminderSetting.content.length / 160) : 1 }}
          </small>
          <small
            class="pull-right sub-title text-soft"
            title="As some parts of this will be exchanged for user data, it might become longer or shorter.">
            Approx character count:
            {{ reminderSetting.content ? reminderSetting.content.length : 0 }}
          </small>
        </div>
      </div>
      <TextareaInput
        :model-value="reminderSetting.content"
        placeholder="Content of SMS"
        :exchange-words="exchangeWords"
        @update:model-value="emitReminderSetting('content', $event)" />
    </div>
    <div
      v-if="canSetReplyTo && reminderSetting.email"
      class="grid grid-cols-2 gap-5">
      <VSelect
        label="Reply To"
        nullable-display-text="Default"
        nullable
        :options="[
          { id: 'Me', name: 'Me' },
          { id: 'Other', name: 'Custom' },
        ]"
        :model-value="
          reminderSetting.reply_to_id !== null ? 'Me' : reminderSetting.reply_to_email === null ? null : 'Other'
        "
        @update:model-value="replyToUpdated" />
      <EmailInput
        v-if="reminderSetting.reply_to_email !== null"
        :email="reminderSetting.reply_to_email"
        data-list-options-local-storage-string="reply_to_emails_for_reminders"
        @update:email="emitReminderSetting('reply_to_email', $event)" />
    </div>
    <div>
      <div class="grid grid-cols-[auto_auto_auto] gap-4">
        <div>
          <SettingToggle
            v-if="canIncludeLink"
            :model-value="reminderSetting.with_link"
            label="Include Responder's Page Link"
            title="If enabled, the email will include a link to the public form response page."
            @clicked="emitReminderSetting('with_link', !reminderSetting.with_link)" />
        </div>
        <div>
          <slot name="sendButton" />
        </div>
      </div>
    </div>
  </div>
</template>
