<script setup lang="ts">
import UserProfileModal from '@/components/Modals/UserProfileModal.vue';
import LoaderFunction from '@/components/Helpers/LoaderFunction.vue';
import { getItemFromArrayBasedOnId } from '@/util/globals';
import { ref, watch } from 'vue';

type Props = {
  userId: number;
  show?: boolean;
  editedAt?: string | null;
  includeClass?: string;
  prependText?: string;
  users?: any[];
  customStyle?: string;
  customClasses?: string;
  iconClass?: string;
  allowOpen?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  show: true,
  editedAt: null,
  includeClass: '',
  prependText: '',
  users: () => [],
  customStyle: 'width: 20px; height: 27px;',
  customClasses: 'pull-right text-center',
  iconClass: 'fa-user',
  allowOpen: false,
});

const user = ref(null);
const loaded = ref(false);
const loading = ref(false);
const fetchUser = ref(false);
const modalOpen = ref(false);

const showModal = () => {
  if (props.allowOpen) {
    modalOpen.value = true;
  }
};

const maybeUser = getItemFromArrayBasedOnId(props.userId, props.users);

if (maybeUser) {
  user.value = maybeUser;
  loaded.value = true;
}

watch(
  () => props.userId,
  () => {
    loaded.value = false;
    user.value = null;
  }
);
</script>

<template>
  <div>
    <div
      class="ml-2 flex !w-0 cursor-pointer items-center text-soft transition-all hover:text"
      :class="`${customClasses} ${show ? 'group-hover:!w-3' : ''}`"
      :style="customStyle"
      :title="[loaded ? (editedAt ? prependText + user.name + ' at ' + editedAt : prependText + user.name) : '']"
      @click="showModal()"
      @mouseover="fetchUser = true">
      <i
        class="fa fa-fw fa-xs"
        :class="[includeClass, iconClass]" />
    </div>
    <LoaderFunction
      v-if="fetchUser"
      :storage-string="'user_' + userId + '_info'"
      :url="'/api/users/' + userId"
      @values="user = $event"
      @next="loaded = true" />

    <UserProfileModal
      v-if="modalOpen"
      :name="user.name"
      :email="user.email"
      :phone="user.phone"
      :show-image="true"
      :avatar-url="user.avatar_url" />
  </div>
</template>
