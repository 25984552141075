<script lang="ts" setup>
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import { slotEmpty } from '@/util/globals';
import { updateQueryString } from '@/util/query-helpers';
import InfoIcon from '@/components/Icons/InfoIcon.vue';
import { useSmallScreen } from '@/composables/use-small-screen';
import DisplayRichText from '@/components/Display/DisplayRichText.vue';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';
import { type ActionButtonProps } from '@/types/button';

type Props = {
  loading?: boolean;
  editMode?: boolean;
  withBackButton?: boolean;
  title?: string | null;
  titleInfo?: string;
  superHeader?: string | null;
  preIcon?: string | null;
  underTitle?: string | null;
  actions?: ActionButtonProps[];
  actionsAsButtons?: boolean | null;
  isPageHeader?: boolean;
  alignCenter?: boolean;
  teleportId?: string;
};
const props = withDefaults(defineProps<Props>(), {
  loading: false,
  editMode: null,
  withBackButton: false,
  isPageHeader: false,
  title: null,
  titleInfo: '',
  superHeader: null,
  preIcon: null,
  underTitle: null,
  actions: () => [],
  actionsAsButtons: true,
  alignCenter: false,
  teleportId: undefined,
});

const emit = defineEmits<{
  (event: 'back'): void;
  (event: 'edit'): void;
}>();

const smallScreen = useSmallScreen().isSmallScreen;

const goBack = (event = null) => {
  emit('back', event);
  updateQueryString(null, false);
};
</script>

<template>
  <Teleport
    :disabled="!teleportId"
    :to="teleportId">
    <div
      v-if="!smallScreen || !isPageHeader"
      class="relative flex min-h-[3rem] items-center gap-3 md:min-h-[4rem] flex-wrap bg-[--color-background-content-header-primary] border-b"
      :class="withBackButton || preIcon ? 'pr-edge' : 'px-edge'">
      <div
        v-if="withBackButton || preIcon"
        class="h-[4rem] items-center p-3 w-[50px]"
        @click="goBack($event)">
        <div
          class="w-full h-full flex items-center rounded justify-center"
          :class="{
            'cursor-pointer hover:bg-row-hover ': withBackButton,
          }">
          <IconWithLoading
            v-if="withBackButton || preIcon"
            classes="text-2xl"
            :icon="withBackButton ? 'fa-arrow-left ' : preIcon" />
        </div>
      </div>
      <div class="flex md:h-[4rem] flex-1 items-center gap-2 md:overflow-hidden pr-2">
        <div class="h-16 flex-0 flex flex-col justify-center overflow-hidden">
          <div
            v-if="superHeader"
            class="sub-title uppercase text-highlight font-headers">
            {{ superHeader }}
          </div>
          <div
            class="flex gap-2"
            :class="alignCenter ? 'items-center' : 'items-baseline'">
            <h2
              class="truncate font-headers"
              :title="titleInfo ? titleInfo : title">
              <slot name="title"> {{ title }} </slot>
              <InfoIcon
                v-if="titleInfo"
                class="ml-5"
                :title="titleInfo" />
            </h2>
            <IconWithLoading
              v-if="loading"
              loading />
            <slot name="afterTitle" />
          </div>
        </div>
        <slot name="menuButton"></slot>
      </div>
      <div class="md:h-[4rem]">
        <ActionButtonGroup
          v-if="actionsAsButtons"
          :actions="
            [
              editMode && editMode !== null
                ? {
                    icon: 'fa-pencil',
                    title: 'Edit',
                    action: () => {
                      $emit('edit');
                    },
                  }
                : null,
            ]
              .concat(actions)
              .filter((i) => i !== null)
          " />
      </div>
    </div>
    <div
      v-if="(underTitle && underTitle !== ' ') || !slotEmpty($slots, 'underTitle')"
      :class="slotEmpty($slots, 'underTitle') ? 'py-2 pl-edge ' : '' + (smallScreen ? ' !px-3' : '')"
      class="text-soft [&>div]:px-edge bg-[--color-background-content-header-primary] shadow">
      <DisplayRichText
        v-if="underTitle"
        class="!p-0"
        :links-in-new-tab="false"
        :content="underTitle" />
      <slot name="underTitle" />
    </div>
  </Teleport>
</template>
