<script setup lang="ts">
import { ref } from 'vue';
import ChevronToggle from '@/components/Icons/ChevronToggle.vue';
import { type ActionButtonProps } from '@/types/button';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';

type Props = {
  header?: string | null;
  preIcon?: string | null;
  superText?: string | null;
  hoverEffects?: boolean | null;
  small?: boolean | null;
  togglable?: boolean | null;
  defaultOpen?: boolean | null;
  loading?: boolean | null;
  edgeToEdge?: boolean | null;
  actions?: ActionButtonProps[];
  actionsAsButtons?: boolean | null;
  newHover?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  header: null,
  superText: null,
  preIcon: null,
  hoverEffects: true,
  small: false,
  togglable: false,
  loading: false,
  edgeToEdge: false,
  defaultOpen: true,
  actions: () => [],
  actionsAsButtons: true,
  newHover: false,
});

const emit = defineEmits<{
  'open': [arg: boolean];
}>();

const isOpen = ref(props.defaultOpen);

const toggleOpen = () => {
  isOpen.value = !isOpen.value;
  emit('open', isOpen.value);
};
</script>
<template>
  <div class="box-container-container group/box">
    <div
      :class="{
        ' hover:border-strong hover:shadow-md ': hoverEffects,
        ' hover:bg-hover ': hoverEffects && newHover,
        ' px-edge  ': !small && !edgeToEdge,
        ' px-3 ': small && !edgeToEdge,
        ' py-edge  ': !small,
        ' py-3 ': small,
      }"
      class="bg-box test border transition-all rounded box-container shadow">
      <div
        v-if="header || actions.length > 0"
        class="relative flex justify-between items-center"
        :class="{
          ' px-edge  ': !small && edgeToEdge,
          ' px-3 ': small && edgeToEdge,
          'cursor-pointer': togglable,
        }"
        @click="togglable ? toggleOpen() : null">
        <div
          :class="{ 'w-full': actions.length === 0 }"
          class="flex gap-5 items-center">
          <ChevronToggle
            v-if="togglable"
            class="!text-xs"
            :model-value="isOpen" />
          <slot name="header">
            <div class="flex flex-col">
              <span
                v-if="superText"
                class="text-soft text-sm"
                >{{ superText }}</span
              >
              <div class="font-headers font-mediumbold flex items-center gap-5 text-lg box-container-header">
                <slot name="preIcon">
                  <i
                    v-if="preIcon"
                    class="fa fa-fw"
                    :class="preIcon"></i>
                </slot>
                {{ header }}
                <slot name="afterTitle" />
              </div>
              <slot name="underHeader" />
            </div>
          </slot>
        </div>

        <template v-if="actions.length > 0 && (isOpen || !togglable)">
          <ActionButtonGroup
            v-if="actionsAsButtons"
            :actions="actions">
          </ActionButtonGroup>
        </template>
        <slot name="actionButtons" />
      </div>
      <transition :name="togglable ? 'slide-down' : ''">
        <div
          v-if="isOpen || !togglable"
          :class="{
            ' pt-edge ': !small,
            ' pt-3 ': small,
          }">
          <slot />
        </div>
      </transition>
    </div>
  </div>
</template>
