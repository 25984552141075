<script setup lang="ts">
import moment from 'moment';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';
import { dateTimeFormat } from '@/variables/date-format';
import { ref, watch } from 'vue';
import ButtonGroup from '@/components/Inputs/Components/ButtonGroup.vue';
import DatePickerDropdown from '@/components/Inputs/Date/DatePickerDropdown.vue';

type Props = {
  granularity?: string | null;
  baseStart?: string | null;
  displayFormat?: string;
  headerFormat?: string | null;
  emitChangesAsActions?: boolean | null;
  withGoToDateCalendar?: boolean | null;
  simpleButtons?: boolean | null;
  working?: boolean | null;
};

const props = withDefaults(defineProps<Props>(), {
  granularity: 'day',
  baseStart: null,
  displayFormat: 'DDDD MMMM YYYY',
  headerFormat: null,
  emitChangesAsActions: false,
  withGoToDateCalendar: true,
  simpleButtons: false,
  working: false,
});

const emit = defineEmits<{
  (e: 'dateChange', date: { start: string; end: string }): void;
  (e: 'back'): void;
  (e: 'next'): void;
}>();

const start = ref(moment(props.baseStart).startOf(props.granularity).format(dateTimeFormat));
const end = ref(moment(props.baseStart).startOf(props.granularity).format(dateTimeFormat));
const startOnYear = ref(false);

watch(
  () => props.baseStart,
  (baseStart) => {
    start.value = moment(baseStart).startOf(props.granularity).format(dateTimeFormat);
    end.value = moment(baseStart).startOf(props.granularity).format(dateTimeFormat);
  }
);

const showDateModified = (changer = 0, format = props.displayFormat) => {
  return moment(start.value).add(changer, props.granularity).format(format);
};

const goTo = (changer = 0) => {
  if (props.emitChangesAsActions) {
    switch (changer) {
      case -1: {
        emit('back');
        return;
      }
      case 1: {
        emit('next');
        return;
      }
      default: {
        return;
      }
    }
  }
  start.value = moment(start.value).add(changer, props.granularity).startOf(props.granularity).format(dateTimeFormat);
  end.value = moment(end.value).add(changer, props.granularity).endOf(props.granularity).format(dateTimeFormat);

  emit('dateChange', { start: start.value, end: end.value });
};

const goToDate = (date: string) => {
  start.value = moment(date).startOf(props.granularity).format(dateTimeFormat);
  end.value = moment(date).endOf(props.granularity).format(dateTimeFormat);
  emit('dateChange', { start: start.value, end: end.value });
};
</script>

<template>
  <div class="flex h-[40px] items-center bg text gap-5">
    <ButtonGroup class="h-[28px]">
      <button
        class="btn btn-tiny btn-info"
        data-intro="previous-date"
        @click="goTo(-1)">
        <i class="fa fa-fw fa-chevron-left" />
      </button>
      <DatePickerDropdown
        :start="start"
        required
        :month-picker="granularity !== 'day'"
        :min-date="null"
        buttons-class="btn-tiny btn-info rounded-none"
        @new-date="goToDate">
        <template #trigger>
          <button class="btn btn-tiny btn-info rounded-none h-[28px]">
            <i class="fa fa-calendar fa-fw" />
          </button>
        </template>
      </DatePickerDropdown>
      <button
        class="btn btn-tiny btn-info"
        @click="goTo(1)">
        <i class="fa fa-fw fa-chevron-right" />
      </button>
    </ButtonGroup>

    <VDatepicker
      :model-value="start"
      required
      :month-picker="granularity !== 'day'"
      :flow="granularity === 'day' ? ['calendar'] : []"
      :min-date="null"
      class="[&>div]:bg"
      @update:model-value="goToDate">
      <template #trigger>
        <div class="mx-auto flex cursor-pointer items-center justify-center text-center">
          <h1 class="truncate font-headers">
            {{ showDateModified(0, headerFormat ? headerFormat : displayFormat) }}
          </h1>
        </div>
      </template>
    </VDatepicker>
  </div>
</template>
