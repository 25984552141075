<script lang="ts" setup>
import Toggle from '@vueform/toggle';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import { slugify } from '@/util/string-utils';

type Props = {
  modelValue: boolean | null;
  label?: string;
  canEdit?: boolean;
  labelPosition?: 'right' | 'top';
  required?: boolean;
  title?: string | null;
  leftLabel?: string | null;
};

withDefaults(defineProps<Props>(), {
  canEdit: true,
  labelPosition: 'right',
  required: false,
  title: null,
  leftLabel: null,
  label: null,
});

const emit = defineEmits<{
  (event: 'update:modelValue', arg: boolean): void;
  (event: 'change', arg: boolean): void;
}>();

const onChange = (e) => {
  emit('update:modelValue', e);
};
</script>

<template>
  <div
    class="flex items-center gap-1"
    :class="{
      'flex-col-reverse items-center': labelPosition === 'top',
      ' cursor-pointer': canEdit,
      ' cursor-not-allowed ': !canEdit,
    }">
    <InputLabel
      v-if="labelPosition !== 'top' && leftLabel"
      :label="leftLabel"
      :required="required" />
    <Toggle
      :dusk="`toggle-${slugify(leftLabel)}-${slugify(label)}`"
      class="focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-4 focus-visible:outline-highlight rounded-full"
      :model-value="modelValue"
      :disabled="!canEdit"
      :required="required"
      @change="onChange" />
    <InputLabel
      v-if="label || $slots.label"
      :label="label"
      :title="title"
      :mandatory-text="required ? 'required' : null">
      <slot name="label" />
    </InputLabel>
  </div>
</template>
